import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../models';
import { RestService } from 'src/app/@core/services/rest.service';
import { SettingService } from '@app/@core/services';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUser: Observable<User>;

  constructor(private restService: RestService, private settingService: SettingService) {}

  public get currentUserValue(): User {
    return this.settingService.user;
  }

  login(username: string, password: string) {
    return this.restService.post(`/login`, { username, password });
  }

  logout() {
    this.settingService.setUser(null);
  }
}
