import { CriteriaBundleModel } from '../criteria-bundle';
import { QualityCriteriasActions } from './actions';
import { QualityCriteriaModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum QualityCriteriaModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum QualityCriteriaModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  cleanCriteriaBundlesInQualityCriteria,
  updateCriteriaBundlesInQualityCriteria,
  openQualityCriteriaView,
  getQualityCriterias,
  getQualityCriteriasSuccess,
  getQualityCriteriasFailure,
  getQualityCriteria,
  getQualityCriteriaSuccess,
  getQualityCriteriaFailure,
  createQualityCriteria,
  createQualityCriteriaSuccess,
  createQualityCriteriaFailure,
  updateQualityCriteria,
  updateQualityCriteriaSuccess,
  updateQualityCriteriaFailure,
  deleteQualityCriteria,
  deleteQualityCriteriaSuccess,
  deleteQualityCriteriaFailure,
} = QualityCriteriasActions;
export interface QualityCriteriaState {
  list: Array<QualityCriteriaModel>;
  item: QualityCriteriaModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    fullName?: string;
  };
  loading: boolean;
  modal: {
    type: QualityCriteriaModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: QualityCriteriaModalStatus;
  };
  inCriteriaBundles: Array<CriteriaBundleModel>;
}

export const initialState: QualityCriteriaState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    fullName: null,
    name: null,
  },
  loading: false,
  modal: {
    type: QualityCriteriaModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: QualityCriteriaModalStatus.Init,
  },
  inCriteriaBundles: [],
};

export const reducer = createReducer(
  initialState,
  on(getQualityCriterias, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getQualityCriteriasSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getQualityCriteriasFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updateQualityCriteria, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: QualityCriteriaModalStatus.Called,
      type: QualityCriteriaModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateQualityCriteriaSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: QualityCriteriaModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateQualityCriteriaFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: QualityCriteriaModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createQualityCriteria, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: QualityCriteriaModalStatus.Init,
      type: QualityCriteriaModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createQualityCriteriaSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: QualityCriteriaModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createQualityCriteriaFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: QualityCriteriaModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteQualityCriteria, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteQualityCriteriaSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteQualityCriteriaFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openQualityCriteriaView, (state, { payload }) => {
    const modal = {
      ...state.modal,
      status: QualityCriteriaModalStatus.Init,
      errors: [],
    };

    if (payload && Array.isArray(payload.inCriteriaBundles)) {
      const lines = payload.inCriteriaBundles;
      const inCriteriaBundles = [...lines];
      return {
        ...state,
        modal,
        inCriteriaBundles,
      };
    }

    return {
      ...state,
      modal,
      inCriteriaBundles: [],
    };
  }),
  on(updateCriteriaBundlesInQualityCriteria, (state, { payload }) => {
    const { inCriteriaBundles } = payload;
    return {
      ...state,
      inCriteriaBundles: [...inCriteriaBundles],
    };
  }),
  on(cleanCriteriaBundlesInQualityCriteria, (state) => {
    return {
      ...state,
      inCriteriaBundles: [],
    };
  })
);
