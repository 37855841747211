import { createAction, props } from '@ngrx/store';

import { TransportRequestModel } from './model';
import {
  TransportRequestResultsModel,
  TransportRequestResultModel,
} from './results.model';

const getTransportRequests = createAction(
  '[TRANSPORTREQUEST] Get TransportRequests',
  props<{ payload: any }>()
);

const getTransportRequestsSuccess = createAction(
  '[TRANSPORTREQUEST] Get TransportRequests Success',
  props<{ payload: TransportRequestResultsModel }>()
);

export const getTransportRequestsFailure = createAction(
  '[TRANSPORTREQUEST] Get TransportRequests Faild'
);

const getTransportRequest = createAction(
  '[TRANSPORTREQUEST] Get TransportRequest',
  props<{ payload: { transportRequestId: number } }>()
);

const getTransportRequestSuccess = createAction(
  '[TRANSPORTREQUEST] Get TransportRequest Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const getTransportRequestFailure = createAction(
  '[TRANSPORTREQUEST] Get TransportRequest Faild'
);

const getTransportRequestWithTripWithOnIntervalLorry = createAction(
  '[TRIP] Get TransportRequestWithTripWithOnIntervalLorry',
  props<{ payload: { transportRequestId: number } }>()
);

const getTransportRequestWithTripWithOnIntervalLorrySuccess = createAction(
  '[TRIP] Get TransportRequestWithTripWithOnIntervalLorry Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const getTransportRequestWithTripWithOnIntervalLorryFailure = createAction(
  '[TRIP] Get TransportRequestWithTripWithOnIntervalLorry Faild'
);

const createTransportRequest = createAction(
  '[TRANSPORTREQUEST] Create TransportRequest',
  props<{
    payload: { projectId: number; transportRequest: TransportRequestModel };
  }>()
);

export const createTransportRequestSuccess = createAction(
  '[TRANSPORTREQUEST] Create TransportRequest Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const createTransportRequestFailure = createAction(
  '[TRANSPORTREQUEST] Create TransportRequest Faild',
  props<{ payload: TransportRequestResultModel }>()
);

const updateTransportRequest = createAction(
  '[TRANSPORTREQUEST] Update TransportRequest',
  props<{
    payload: {
      transportRequestId: number;
      transportRequest: TransportRequestModel;
    };
  }>()
);

const updateTransportRequestSuccess = createAction(
  '[TRANSPORTREQUEST] Update TransportRequest Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const updateTransportRequestFailure = createAction(
  '[TRANSPORTREQUEST] Update TransportRequest Faild',
  props<{ payload: TransportRequestResultModel }>()
);

const deleteTransportRequest = createAction(
  '[TRANSPORTREQUEST] Delete TransportRequest',
  props<{ payload: number }>()
);

const deleteTransportRequestSuccess = createAction(
  '[TRANSPORTREQUEST] Delete TransportRequest Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const deleteTransportRequestFailure = createAction(
  '[TRANSPORTREQUEST] Delete TransportRequest Faild',
  props<{ payload: TransportRequestResultModel }>()
);

export const openTransportRequestView = createAction(
  '[TRANSPORTREQUEST] Open TransportRequest View'
);

export const TransportRequestsActions = {
  getTransportRequestWithTripWithOnIntervalLorry,
  getTransportRequestWithTripWithOnIntervalLorrySuccess,
  getTransportRequestWithTripWithOnIntervalLorryFailure,
  openTransportRequestView,
  getTransportRequests,
  getTransportRequestsSuccess,
  getTransportRequestsFailure,
  getTransportRequest,
  getTransportRequestSuccess,
  getTransportRequestFailure,
  createTransportRequest,
  createTransportRequestSuccess,
  createTransportRequestFailure,
  updateTransportRequest,
  updateTransportRequestSuccess,
  updateTransportRequestFailure,
  deleteTransportRequest,
  deleteTransportRequestSuccess,
  deleteTransportRequestFailure,
};
