import { createAction, props } from '@ngrx/store';

import { SettingModel } from './model';
import { SettingResultsModel, SettingResultModel } from './results.model';

const getSettingProject = createAction('[SETTING] Get Settings Project');

const getSettingProjectSuccess = createAction(
  '[SETTING] Get Settings Project Success',
  props<{ payload: SettingResultsModel }>()
);

export const getSettingProjectFailure = createAction(
  '[SETTING] Get Settings Project Faild'
);

const getSettings = createAction(
  '[SETTING] Get Settings',
  props<{ payload: any }>()
);

const getSettingsSuccess = createAction(
  '[SETTING] Get Settings Success',
  props<{ payload: SettingResultsModel }>()
);

export const getSettingsFailure = createAction('[SETTING] Get Settings Faild');

const getSetting = createAction(
  '[SETTING] Get Setting',
  props<{ payload: any }>()
);

const getSettingSuccess = createAction(
  '[SETTING] Get Setting Success',
  props<{ payload: SettingResultModel }>()
);

export const getSettingFailure = createAction('[SETTING] Get Setting Faild');

const createSetting = createAction(
  '[SETTING] Create Setting',
  props<{ payload: SettingModel }>()
);

const createSettingSuccess = createAction(
  '[SETTING] Create Setting Success',
  props<{ payload: SettingResultModel }>()
);

export const createSettingFailure = createAction(
  '[SETTING] Create Setting Faild',
  props<{ payload: SettingResultModel }>()
);

const updateSetting = createAction(
  '[SETTING] Update Setting',
  props<{ payload: Array<SettingModel> }>()
);

const updateSettingSuccess = createAction(
  '[SETTING] Update Setting Success',
  props<{ payload: SettingResultModel }>()
);

export const updateSettingFailure = createAction(
  '[SETTING] Update Setting Faild',
  props<{ payload: SettingResultModel }>()
);

const deleteSetting = createAction(
  '[SETTING] Delete Setting',
  props<{ payload: number }>()
);

const deleteSettingSuccess = createAction(
  '[SETTING] Delete Setting Success',
  props<{ payload: SettingResultModel }>()
);

export const deleteSettingFailure = createAction(
  '[SETTING] Delete Setting Faild',
  props<{ payload: SettingResultModel }>()
);

export const openSettingView = createAction('[SETTING] Open Setting View');

const printReportAttendance = createAction(
  '[SETTING] Print ReportAttendance',
  props<{ payload: { fromDate: string; toDate: string } }>()
);

const printReportAttendanceSuccess = createAction(
  '[SETTING] Print ReportAttendance Success',
  props<{ payload: Blob }>()
);

export const printReportAttendanceFailure = createAction(
  '[SETTING] Print ReportAttendance Faild'
);

export const SettingsActions = {
  printReportAttendance,
  printReportAttendanceSuccess,
  printReportAttendanceFailure,
  getSettingProject,
  getSettingProjectSuccess,
  getSettingProjectFailure,
  openSettingView,
  getSettings,
  getSettingsSuccess,
  getSettingsFailure,
  getSetting,
  getSettingSuccess,
  getSettingFailure,
  createSetting,
  createSettingSuccess,
  createSettingFailure,
  updateSetting,
  updateSettingSuccess,
  updateSettingFailure,
  deleteSetting,
  deleteSettingSuccess,
  deleteSettingFailure,
};
