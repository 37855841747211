import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
export interface PointTrip {
  id?: number;
  name?: string;
  address?: string;
  phone?: number;
  lat?: number;
  lng?: number;
  plateNumber?: number;
}
@Component({
  selector: 'app-round-trip',
  templateUrl: './round-trip.component.html',
  styleUrls: ['./round-trip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundTripComponent {
  @Input()
  departure?: PointTrip;

  @Input()
  arrival?: PointTrip;

  @Input()
  driver?: PointTrip;

  @Input()
  status?: boolean;

  iconDepartureUrl = '/assets/icons/warehouse.svg';
  iconArrivalUrl = '/assets/icons/project.svg';
}
