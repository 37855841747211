import { Directive, OnInit, ElementRef } from '@angular/core';
import { SettingService } from '../services';

@Directive({
  selector: '[appImageAccess]',
})
export class ImageAccessDirective implements OnInit {
  accessToken: string;

  constructor(
    private elementRef: ElementRef,
    private settingService: SettingService
  ) {
    this.accessToken = this.settingService.user
      ? this.settingService.user.accessToken
      : '';
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.src = `${
      this.elementRef.nativeElement.src
    }&accessToken=${this.accessToken.replace('Bearer ', '')}`;
  }
}
