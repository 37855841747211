import { createAction, props } from '@ngrx/store';

import { PurchaseRequestModel, PurchaseRequestLineModel } from './model';
import {
  PurchaseRequestResultsModel,
  PurchaseRequestResultModel,
} from './results.model';
import { ProductModel } from '../product/model';
import { TransportRequestResultModel } from '../transport-request';

const getPurchaseRequests = createAction(
  '[PURCHASEEREQUEST] Get PurchaseRequests',
  props<{ payload: any }>()
);

const getPurchaseRequestsSuccess = createAction(
  '[PURCHASEEREQUEST] Get PurchaseRequests Success',
  props<{ payload: PurchaseRequestResultsModel }>()
);

export const getPurchaseRequestsFailure = createAction(
  '[PURCHASEEREQUEST] Get PurchaseRequests Faild'
);

const getPurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Get PurchaseRequest',
  props<{ payload: { purchaseRequestId: number } }>()
);

const getPurchaseRequestSuccess = createAction(
  '[PURCHASEEREQUEST] Get PurchaseRequest Success',
  props<{ payload: PurchaseRequestResultModel }>()
);

export const getPurchaseRequestFailure = createAction(
  '[PURCHASEEREQUEST] Get PurchaseRequest Faild'
);

const createPurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Create PurchaseRequest',
  props<{
    payload: { projectId: number; purchaseRequest: PurchaseRequestModel };
  }>()
);

export const createPurchaseRequestSuccess = createAction(
  '[PURCHASEEREQUEST] Create PurchaseRequest Success',
  props<{ payload: PurchaseRequestResultModel }>()
);

export const createPurchaseRequestFailure = createAction(
  '[PURCHASEEREQUEST] Create PurchaseRequest Faild',
  props<{ payload: PurchaseRequestResultModel }>()
);

const updatePurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Update PurchaseRequest',
  props<{
    payload: {
      purchaseRequestId: number;
      purchaseRequest: PurchaseRequestModel;
    };
  }>()
);

const updatePurchaseRequestSuccess = createAction(
  '[PURCHASEEREQUEST] Update PurchaseRequest Success',
  props<{ payload: PurchaseRequestResultModel }>()
);

export const updatePurchaseRequestFailure = createAction(
  '[PURCHASEEREQUEST] Update PurchaseRequest Faild',
  props<{ payload: PurchaseRequestResultModel }>()
);

const deletePurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Delete PurchaseRequest',
  props<{ payload: number }>()
);

const deletePurchaseRequestSuccess = createAction(
  '[PURCHASEEREQUEST] Delete PurchaseRequest Success',
  props<{ payload: PurchaseRequestResultModel }>()
);

export const deletePurchaseRequestFailure = createAction(
  '[PURCHASEEREQUEST] Delete PurchaseRequest Faild',
  props<{ payload: PurchaseRequestResultModel }>()
);

export const openPurchaseRequestView = createAction(
  '[PURCHASEEREQUEST] Open PurchaseRequest View',
  props<{
    payload: {
      inProducts: Array<PurchaseRequestLineModel>;
    };
  }>()
);

export const cleanProductInPurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Clean Products in  PurchaseRequest'
);

export const addProductInPurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Add Product in  PurchaseRequest',
  props<{ payload: { product: ProductModel; quantity: number } }>()
);

export const editProductInPurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Edit Product in  PurchaseRequest',
  props<{
    payload: { product: PurchaseRequestLineModel; quantity: number };
  }>()
);

export const removeProductInPurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Remove Product in  PurchaseRequest',
  props<{ payload: { product: PurchaseRequestLineModel } }>()
);
const createPurchaseRequestTransportRequest = createAction(
  '[PURCHASEEREQUEST] Create TransportRequest',
  props<{
    payload: { purchaseRequestId: number };
  }>()
);

export const createPurchaseRequestTransportRequestSuccess = createAction(
  '[PURCHASEEREQUEST] Create TransportRequest Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const createPurchaseRequestTransportRequestFailure = createAction(
  '[PURCHASEEREQUEST] Create TransportRequest Faild',
  props<{ payload: TransportRequestResultModel }>()
);

const receivePurchaseRequest = createAction(
  '[PURCHASEEREQUEST] Receive PurchaseRequest',
  props<{ payload: any }>()
);

const receivePurchaseRequestSuccess = createAction(
  '[PURCHASEEREQUEST] Receive PurchaseRequest Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const receivePurchaseRequestFailure = createAction(
  '[PURCHASEEREQUEST] Receive PurchaseRequest Faild'
);

export const PurchaseRequestsActions = {
  receivePurchaseRequest,
  receivePurchaseRequestSuccess,
  receivePurchaseRequestFailure,
  createPurchaseRequestTransportRequest,
  createPurchaseRequestTransportRequestSuccess,
  createPurchaseRequestTransportRequestFailure,
  cleanProductInPurchaseRequest,
  addProductInPurchaseRequest,
  editProductInPurchaseRequest,
  removeProductInPurchaseRequest,
  openPurchaseRequestView,
  getPurchaseRequests,
  getPurchaseRequestsSuccess,
  getPurchaseRequestsFailure,
  getPurchaseRequest,
  getPurchaseRequestSuccess,
  getPurchaseRequestFailure,
  createPurchaseRequest,
  createPurchaseRequestSuccess,
  createPurchaseRequestFailure,
  updatePurchaseRequest,
  updatePurchaseRequestSuccess,
  updatePurchaseRequestFailure,
  deletePurchaseRequest,
  deletePurchaseRequestSuccess,
  deletePurchaseRequestFailure,
};
