import { createAction, props } from '@ngrx/store';

import { QualityCriteriaModel } from './model';
import {
  QualityCriteriaResultsModel,
  QualityCriteriaResultModel,
} from './results.model';
import { CriteriaBundleModel } from '../criteria-bundle';

const getQualityCriterias = createAction(
  '[QUALITYCRITERIA] Get QualityCriterias',
  props<{ payload: any }>()
);

const getQualityCriteriasSuccess = createAction(
  '[QUALITYCRITERIA] Get QualityCriterias Success',
  props<{ payload: QualityCriteriaResultsModel }>()
);

export const getQualityCriteriasFailure = createAction(
  '[QUALITYCRITERIA] Get QualityCriterias Faild'
);

const getQualityCriteria = createAction(
  '[QUALITYCRITERIA] Get QualityCriteria',
  props<{ payload: { qualityCriteriaId: number } }>()
);

const getQualityCriteriaSuccess = createAction(
  '[QUALITYCRITERIA] Get QualityCriteria Success',
  props<{ payload: QualityCriteriaResultModel }>()
);

export const getQualityCriteriaFailure = createAction(
  '[QUALITYCRITERIA] Get QualityCriteria Faild'
);

const createQualityCriteria = createAction(
  '[QUALITYCRITERIA] Create QualityCriteria',
  props<{ payload: QualityCriteriaModel }>()
);

const createQualityCriteriaSuccess = createAction(
  '[QUALITYCRITERIA] Create QualityCriteria Success',
  props<{ payload: QualityCriteriaResultModel }>()
);

export const createQualityCriteriaFailure = createAction(
  '[QUALITYCRITERIA] Create QualityCriteria Faild',
  props<{ payload: QualityCriteriaResultModel }>()
);

const updateQualityCriteria = createAction(
  '[QUALITYCRITERIA] Update QualityCriteria',
  props<{
    payload: {
      qualityCriteriaId: number;
      qualityCriteria: QualityCriteriaModel;
    };
  }>()
);

const updateQualityCriteriaSuccess = createAction(
  '[QUALITYCRITERIA] Update QualityCriteria Success',
  props<{ payload: QualityCriteriaResultModel }>()
);

export const updateQualityCriteriaFailure = createAction(
  '[QUALITYCRITERIA] Update QualityCriteria Faild',
  props<{ payload: QualityCriteriaResultModel }>()
);

const deleteQualityCriteria = createAction(
  '[QUALITYCRITERIA] Delete QualityCriteria',
  props<{ payload: number }>()
);

const deleteQualityCriteriaSuccess = createAction(
  '[QUALITYCRITERIA] Delete QualityCriteria Success',
  props<{ payload: QualityCriteriaResultModel }>()
);

export const deleteQualityCriteriaFailure = createAction(
  '[QUALITYCRITERIA] Delete QualityCriteria Faild',
  props<{ payload: QualityCriteriaResultModel }>()
);

export const openQualityCriteriaView = createAction(
  '[QUALITYCRITERIA] Open QualityCriteria View',
  props<{
    payload: {
      inCriteriaBundles: Array<CriteriaBundleModel>;
    };
  }>()
);

export const cleanCriteriaBundlesInQualityCriteria = createAction(
  '[QUALITYCRITERIA] Clean CriteriaBundles in  QualityCriteria'
);

export const updateCriteriaBundlesInQualityCriteria = createAction(
  '[QUALITYCRITERIA] Add CriteriaBundle in  QualityCriteria',
  props<{ payload: { inCriteriaBundles: CriteriaBundleModel[] } }>()
);

export const QualityCriteriasActions = {
  updateCriteriaBundlesInQualityCriteria,
  cleanCriteriaBundlesInQualityCriteria,
  openQualityCriteriaView,
  getQualityCriterias,
  getQualityCriteriasSuccess,
  getQualityCriteriasFailure,
  getQualityCriteria,
  getQualityCriteriaSuccess,
  getQualityCriteriaFailure,
  createQualityCriteria,
  createQualityCriteriaSuccess,
  createQualityCriteriaFailure,
  updateQualityCriteria,
  updateQualityCriteriaSuccess,
  updateQualityCriteriaFailure,
  deleteQualityCriteria,
  deleteQualityCriteriaSuccess,
  deleteQualityCriteriaFailure,
};
