import { TransportRequestsActions } from './actions';
import { TransportRequestModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum TransportRequestModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum TransportRequestModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getTransportRequests,
  getTransportRequestsFailure,
  getTransportRequestsSuccess,
  getTransportRequest,
  getTransportRequestFailure,
  getTransportRequestSuccess,
  createTransportRequest,
  createTransportRequestFailure,
  createTransportRequestSuccess,
  updateTransportRequest,
  updateTransportRequestFailure,
  updateTransportRequestSuccess,
  deleteTransportRequest,
  deleteTransportRequestFailure,
  deleteTransportRequestSuccess,
  openTransportRequestView,
  getTransportRequestWithTripWithOnIntervalLorry,
  getTransportRequestWithTripWithOnIntervalLorrySuccess,
  getTransportRequestWithTripWithOnIntervalLorryFailure,
} = TransportRequestsActions;
export interface TransportRequestState {
  list: Array<TransportRequestModel>;
  item: TransportRequestModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId?: number;
  };
  loading: boolean;
  modal: {
    type: TransportRequestModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: TransportRequestModalStatus;
  };
}

export const initialState: TransportRequestState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  loading: false,
  modal: {
    type: TransportRequestModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: TransportRequestModalStatus.Init,
  },
};

export const reducer = createReducer(
  initialState,
  on(getTransportRequests, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getTransportRequestsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getTransportRequestsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getTransportRequest, (state) => {
    return { ...state, loading: true, item: null };
  }),
  on(getTransportRequestSuccess, (state, { payload }) => {
    return {
      ...state,
      item: { ...payload.data },
      loading: false,
    };
  }),
  on(getTransportRequestFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getTransportRequestWithTripWithOnIntervalLorry, (state) => {
    return { ...state, loading: true, item: null };
  }),
  on(
    getTransportRequestWithTripWithOnIntervalLorrySuccess,
    (state, { payload }) => {
      return {
        ...state,
        item: { ...payload.data },
        loading: false,
      };
    }
  ),
  on(getTransportRequestWithTripWithOnIntervalLorryFailure, (state) => {
    return {
      ...state,
      loading: false,
      item: null,
    };
  }),
  on(updateTransportRequest, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: TransportRequestModalStatus.Called,
      type: TransportRequestModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateTransportRequestSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TransportRequestModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateTransportRequestFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TransportRequestModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createTransportRequest, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: TransportRequestModalStatus.Init,
      type: TransportRequestModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createTransportRequestSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TransportRequestModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createTransportRequestFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TransportRequestModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteTransportRequest, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteTransportRequestSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteTransportRequestFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openTransportRequestView, (state) => {
    const modal = {
      ...state.modal,
      status: TransportRequestModalStatus.Init,
      errors: [],
    };

    return {
      ...state,
      modal,
    };
  })
);
