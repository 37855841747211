<div class="clearfix">
  <nz-upload
    class="hellooo"
    nzListType="picture-card"
    [(nzFileList)]="fileList"
    [nzShowButton]="fileList.length < quantity"
    [nzShowUploadList]="showUploadList"
    [nzPreview]="handlePreview"
    [nzDownload]="handleDownload"
    [nzCustomRequest]="customReq"
    [nzRemove]="handleRemove"
    [nzBeforeUpload]="beforeUpload"
  >
    <i nz-icon nzType="plus"></i>
    <div class="ant-upload-text">{{ 'Upload' | translate }}</div>
  </nz-upload>
  <div class="has-error">{{ error }}</div>
  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null" (nzOnCancel)="previewVisible = false">
    <ng-template #modalContent>
      <img appImageAccess [src]="previewImage" [ngStyle]="{ width: '100%' }" />
    </ng-template>
  </nz-modal>
</div>
