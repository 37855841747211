import { createAction, props } from '@ngrx/store';

import { ProductRequirementModel, ProductRequirementLineModel } from './model';
import { ProductRequirementResultsModel, ProductRequirementResultModel } from './results.model';
import { ProductModel } from '../product/model';
import { ManufactureRequestResultsModel } from '../manufacture-request';
import { GoodsIssueRequestResultsModel } from '../goods-issue-request';
import { PurchaseRequestResultsModel } from '../purchase-request';

const getProductRequirements = createAction('[PRODUCTREQUIREMENT] Get ProductRequirements', props<{ payload: any }>());

const getProductRequirementsSuccess = createAction(
  '[PRODUCTREQUIREMENT] Get ProductRequirements Success',
  props<{ payload: ProductRequirementResultsModel }>()
);

export const getProductRequirementsFailure = createAction('[PRODUCTREQUIREMENT] Get ProductRequirements Faild');

const getProductRequirement = createAction(
  '[PRODUCTREQUIREMENT] Get ProductRequirement',
  props<{ payload: { productRequirementId: number } }>()
);

const getProductRequirementSuccess = createAction(
  '[PRODUCTREQUIREMENT] Get ProductRequirement Success',
  props<{ payload: ProductRequirementResultModel }>()
);

export const getProductRequirementFailure = createAction('[PRODUCTREQUIREMENT] Get ProductRequirement Faild');

const createProductRequirement = createAction(
  '[PRODUCTREQUIREMENT] Create ProductRequirement',
  props<{
    payload: { projectId: number; productRequirement: ProductRequirementModel };
  }>()
);

const createProductRequirementSuccess = createAction(
  '[PRODUCTREQUIREMENT] Create ProductRequirement Success',
  props<{ payload: ProductRequirementResultModel }>()
);

export const createProductRequirementFailure = createAction(
  '[PRODUCTREQUIREMENT] Create ProductRequirement Faild',
  props<{ payload: ProductRequirementResultModel }>()
);

const updateProductRequirement = createAction(
  '[PRODUCTREQUIREMENT] Update ProductRequirement',
  props<{
    payload: {
      productRequirementId: number;
      productRequirement: ProductRequirementModel;
    };
  }>()
);

const updateProductRequirementSuccess = createAction(
  '[PRODUCTREQUIREMENT] Update ProductRequirement Success',
  props<{ payload: ProductRequirementResultModel }>()
);

export const updateProductRequirementFailure = createAction(
  '[PRODUCTREQUIREMENT] Update ProductRequirement Faild',
  props<{ payload: ProductRequirementResultModel }>()
);

const deleteProductRequirement = createAction('[PRODUCTREQUIREMENT] Delete ProductRequirement', props<{ payload: number }>());

const deleteProductRequirementSuccess = createAction(
  '[PRODUCTREQUIREMENT] Delete ProductRequirement Success',
  props<{ payload: ProductRequirementResultModel }>()
);

export const deleteProductRequirementFailure = createAction(
  '[PRODUCTREQUIREMENT] Delete ProductRequirement Faild',
  props<{ payload: ProductRequirementResultModel }>()
);

export const openProductRequirementView = createAction(
  '[PRODUCTREQUIREMENT] Open ProductRequirement View',
  props<{
    payload: {
      inProducts: Array<ProductRequirementLineModel>;
    };
  }>()
);

export const openProductRequirementInputView = createAction(
  '[PRODUCTREQUIREMENT] Open ProductRequirement Input View',
  props<{
    payload: {
      inProducts: Array<ProductRequirementLineModel>;
    };
  }>()
);

export const cleanProductInProductRequirement = createAction('[PRODUCTREQUIREMENT] Clean Products in ProductRequirement');

export const addProductInProductRequirement = createAction(
  '[PRODUCTREQUIREMENT] Add Product in ProductRequirement',
  props<{
    payload: { product: ProductModel; quantity: number };
  }>()
);

export const editProductInProductRequirement = createAction(
  '[PRODUCTREQUIREMENT] Edit Product in ProductRequirement',
  props<{
    payload: { product: ProductRequirementLineModel; quantity: number };
  }>()
);

export const removeProductInProductRequirement = createAction(
  '[PRODUCTREQUIREMENT] Remove Product in ProductRequirement',
  props<{ payload: { product: ProductRequirementLineModel } }>()
);

export const addProductInProductRequirementTransferview = createAction(
  '[PRODUCTREQUIREMENT] Add Product in ProductRequirement Transferview',
  props<{ payload: { products: Array<ProductRequirementLineModel> } }>()
);

export const removeProductInProductRequirementTransferview = createAction(
  '[PRODUCTREQUIREMENT] Remove Product in ProductRequirement Transferview',
  props<{ payload: { products: Array<ProductRequirementLineModel> } }>()
);

const getProductRequirementManufactureRequests = createAction('[PRODUCTREQUIREMENT] Get ManufactureRequests', props<{ payload: any }>());

const getProductRequirementManufactureRequestsSuccess = createAction(
  '[PRODUCTREQUIREMENT] Get ManufactureRequests Success',
  props<{ payload: ManufactureRequestResultsModel }>()
);

export const getProductRequirementManufactureRequestsFailure = createAction('[PRODUCTREQUIREMENT] Get ManufactureRequests Faild');

const getProductRequirementGoodsIssueRequests = createAction('[PRODUCTREQUIREMENT] Get GoodsIssueRequests', props<{ payload: any }>());

const getProductRequirementGoodsIssueRequestsSuccess = createAction(
  '[PRODUCTREQUIREMENT] Get GoodsIssueRequests Success',
  props<{ payload: GoodsIssueRequestResultsModel }>()
);

export const getProductRequirementGoodsIssueRequestsFailure = createAction('[PRODUCTREQUIREMENT] Get GoodsIssueRequests Faild');

const getProductRequirementPurchaseRequests = createAction('[PRODUCTREQUIREMENT] Get PurchaseRequests', props<{ payload: any }>());

const getProductRequirementPurchaseRequestsSuccess = createAction(
  '[PRODUCTREQUIREMENT] Get PurchaseRequests Success',
  props<{ payload: PurchaseRequestResultsModel }>()
);

export const getProductRequirementPurchaseRequestsFailure = createAction(
  '[PRODUCTREQUIREMENT] Get ProductRequirement PurchaseRequests Faild'
);

const getProductRequirementChildren = createAction(
  '[PRODUCTREQUIREMENT] Get ProductRequirementChildren',
  props<{ payload: { productRequirementId: number } }>()
);

const getProductRequirementChildrenSuccess = createAction(
  '[PRODUCTREQUIREMENT] Get ProductRequirementChildren Success',
  props<{ payload: ProductRequirementResultModel }>()
);

export const getProductRequirementChildrenFailure = createAction('[PRODUCTREQUIREMENT] Get ProductRequirementChildren Faild');

const downloadProductRequirementFile = createAction(
  '[PRODUCTREQUIREMENT] Download ProductRequirementFile',
  props<{ payload: { productReqId: number } }>()
);
const downloadProductRequirementFileSuccess = createAction(
  '[PRODUCTREQUIREMENT] Download ProductRequirementFile Success',
  props<{ payload: Blob }>()
);
const downloadProductRequirementFileFailure = createAction('[PRODUCTREQUIREMENT] Download ProductRequirementFile Faild');

export const ProductRequirementsActions = {
  downloadProductRequirementFile,
  downloadProductRequirementFileSuccess,
  downloadProductRequirementFileFailure,
  getProductRequirementChildren,
  getProductRequirementChildrenSuccess,
  getProductRequirementChildrenFailure,
  addProductInProductRequirementTransferview,
  removeProductInProductRequirementTransferview,
  getProductRequirementManufactureRequests,
  getProductRequirementManufactureRequestsSuccess,
  getProductRequirementManufactureRequestsFailure,
  getProductRequirementGoodsIssueRequests,
  getProductRequirementGoodsIssueRequestsSuccess,
  getProductRequirementGoodsIssueRequestsFailure,
  getProductRequirementPurchaseRequests,
  getProductRequirementPurchaseRequestsSuccess,
  getProductRequirementPurchaseRequestsFailure,
  openProductRequirementInputView,
  cleanProductInProductRequirement,
  addProductInProductRequirement,
  editProductInProductRequirement,
  removeProductInProductRequirement,
  openProductRequirementView,

  getProductRequirements,
  getProductRequirementsSuccess,
  getProductRequirementsFailure,
  getProductRequirement,
  getProductRequirementSuccess,
  getProductRequirementFailure,
  createProductRequirement,
  createProductRequirementSuccess,
  createProductRequirementFailure,
  updateProductRequirement,
  updateProductRequirementSuccess,
  updateProductRequirementFailure,
  deleteProductRequirement,
  deleteProductRequirementSuccess,
  deleteProductRequirementFailure,
};
