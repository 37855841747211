import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { Observable, of } from 'rxjs';
import { SettingService } from './setting/setting.service';

@Injectable()
export class PermissionsService {
  private readonly WORKFLOW_EVENTS = Object.freeze(environment.workflow);
  private userRoles: Set<string>;
  constructor(private settingService: SettingService) {}

  public checkAuthorization(path: any): Observable<boolean> {
    if (!this.userRoles) {
      this.userRoles = new Set(this.settingService.roles || []);
    }
    return of(this.doCheckAuthorization(path));
  }

  public checkAuthorizationSync(path: any): boolean {
    if (!this.userRoles) {
      this.userRoles = new Set(this.settingService.roles || []);
      this.doCheckAuthorization(path);
    }
    return this.doCheckAuthorization(path);
  }

  public checkAuthorizationWithMenusSync(path: any, menuItems): boolean {
    if (Array.isArray(menuItems)) {
      return menuItems.some((item) => {
        return item.indexOf(path.replace('/:id', '')) === 0;
      });
    }
    return false;
  }

  private doCheckAuthorization(path: any): boolean {
    const keys = this.parsePath(path);
    if (keys.length) {
      // tslint:disable-next-line: no-string-literal
      const entry = this.findEntry(this.WORKFLOW_EVENTS['default'], keys);

      // tslint:disable-next-line: no-string-literal
      if (entry && entry['permittedRoles'] && this.userRoles.size) {
        return entry.permittedRoles.some((permittedRole) => this.userRoles.has(permittedRole));
      }
    }
    return false;
  }

  private parsePath(path: any): string[] {
    if (typeof path === 'string') {
      return path.split('.');
    }
    if (Array.isArray(path)) {
      return path;
    }
    return [];
  }

  private findEntry(currentObject: any, keys: string[], index = 0) {
    const key = keys[index];
    if (currentObject[key] && index < keys.length - 1) {
      return this.findEntry(currentObject[key], keys, index + 1);
    } else if (currentObject[key] && index === keys.length - 1) {
      return currentObject[key];
    } else {
      return false;
    }
  }
}
