import { CriteriaBundleModel } from '../criteria-bundle';
import { SafetyCriteriasActions } from './actions';
import { SafetyCriteriaModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum SafetyCriteriaModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum SafetyCriteriaModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  cleanCriteriaBundlesInSafetyCriteria,
  updateCriteriaBundlesInSafetyCriteria,
  openSafetyCriteriaView,
  getSafetyCriterias,
  getSafetyCriteriasSuccess,
  getSafetyCriteriasFailure,
  getSafetyCriteria,
  getSafetyCriteriaSuccess,
  getSafetyCriteriaFailure,
  createSafetyCriteria,
  createSafetyCriteriaSuccess,
  createSafetyCriteriaFailure,
  updateSafetyCriteria,
  updateSafetyCriteriaSuccess,
  updateSafetyCriteriaFailure,
  deleteSafetyCriteria,
  deleteSafetyCriteriaSuccess,
  deleteSafetyCriteriaFailure,
} = SafetyCriteriasActions;
export interface SafetyCriteriaState {
  list: Array<SafetyCriteriaModel>;
  item: SafetyCriteriaModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    fullName?: string;
  };
  loading: boolean;
  modal: {
    type: SafetyCriteriaModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: SafetyCriteriaModalStatus;
  };
  inCriteriaBundles: Array<CriteriaBundleModel>;
}

export const initialState: SafetyCriteriaState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    fullName: null,
    name: null,
  },
  loading: false,
  modal: {
    type: SafetyCriteriaModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: SafetyCriteriaModalStatus.Init,
  },
  inCriteriaBundles: [],
};

export const reducer = createReducer(
  initialState,
  on(getSafetyCriterias, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getSafetyCriteriasSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getSafetyCriteriasFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updateSafetyCriteria, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: SafetyCriteriaModalStatus.Called,
      type: SafetyCriteriaModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateSafetyCriteriaSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: SafetyCriteriaModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateSafetyCriteriaFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: SafetyCriteriaModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createSafetyCriteria, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: SafetyCriteriaModalStatus.Init,
      type: SafetyCriteriaModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createSafetyCriteriaSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: SafetyCriteriaModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createSafetyCriteriaFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: SafetyCriteriaModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteSafetyCriteria, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteSafetyCriteriaSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteSafetyCriteriaFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openSafetyCriteriaView, (state, { payload }) => {
    const modal = {
      ...state.modal,
      status: SafetyCriteriaModalStatus.Init,
      errors: [],
    };

    if (payload && Array.isArray(payload.inCriteriaBundles)) {
      const lines = payload.inCriteriaBundles;
      const inCriteriaBundles = [...lines];
      return {
        ...state,
        modal,
        inCriteriaBundles,
      };
    }

    return {
      ...state,
      modal,
      inCriteriaBundles: [],
    };
  }),
  on(updateCriteriaBundlesInSafetyCriteria, (state, { payload }) => {
    const { inCriteriaBundles } = payload;
    return {
      ...state,
      inCriteriaBundles: [...inCriteriaBundles],
    };
  }),
  on(cleanCriteriaBundlesInSafetyCriteria, (state) => {
    return {
      ...state,
      inCriteriaBundles: [],
    };
  })
);
