import { createAction, props } from '@ngrx/store';

import { SafetyCriteriaModel } from './model';
import {
  SafetyCriteriaResultsModel,
  SafetyCriteriaResultModel,
} from './results.model';
import { CriteriaBundleModel } from '../criteria-bundle';

const getSafetyCriterias = createAction(
  '[SAFETYCRITERIA] Get SafetyCriterias',
  props<{ payload: any }>()
);

const getSafetyCriteriasSuccess = createAction(
  '[SAFETYCRITERIA] Get SafetyCriterias Success',
  props<{ payload: SafetyCriteriaResultsModel }>()
);

export const getSafetyCriteriasFailure = createAction(
  '[SAFETYCRITERIA] Get SafetyCriterias Faild'
);

const getSafetyCriteria = createAction(
  '[SAFETYCRITERIA] Get SafetyCriteria',
  props<{ payload: { safetyCriteriaId: number } }>()
);

const getSafetyCriteriaSuccess = createAction(
  '[SAFETYCRITERIA] Get SafetyCriteria Success',
  props<{ payload: SafetyCriteriaResultModel }>()
);

export const getSafetyCriteriaFailure = createAction(
  '[SAFETYCRITERIA] Get SafetyCriteria Faild'
);

const createSafetyCriteria = createAction(
  '[SAFETYCRITERIA] Create SafetyCriteria',
  props<{ payload: SafetyCriteriaModel }>()
);

const createSafetyCriteriaSuccess = createAction(
  '[SAFETYCRITERIA] Create SafetyCriteria Success',
  props<{ payload: SafetyCriteriaResultModel }>()
);

export const createSafetyCriteriaFailure = createAction(
  '[SAFETYCRITERIA] Create SafetyCriteria Faild',
  props<{ payload: SafetyCriteriaResultModel }>()
);

const updateSafetyCriteria = createAction(
  '[SAFETYCRITERIA] Update SafetyCriteria',
  props<{
    payload: {
      safetyCriteriaId: number;
      safetyCriteria: SafetyCriteriaModel;
    };
  }>()
);

const updateSafetyCriteriaSuccess = createAction(
  '[SAFETYCRITERIA] Update SafetyCriteria Success',
  props<{ payload: SafetyCriteriaResultModel }>()
);

export const updateSafetyCriteriaFailure = createAction(
  '[SAFETYCRITERIA] Update SafetyCriteria Faild',
  props<{ payload: SafetyCriteriaResultModel }>()
);

const deleteSafetyCriteria = createAction(
  '[SAFETYCRITERIA] Delete SafetyCriteria',
  props<{ payload: number }>()
);

const deleteSafetyCriteriaSuccess = createAction(
  '[SAFETYCRITERIA] Delete SafetyCriteria Success',
  props<{ payload: SafetyCriteriaResultModel }>()
);

export const deleteSafetyCriteriaFailure = createAction(
  '[SAFETYCRITERIA] Delete SafetyCriteria Faild',
  props<{ payload: SafetyCriteriaResultModel }>()
);

export const openSafetyCriteriaView = createAction(
  '[SAFETYCRITERIA] Open SafetyCriteria View',
  props<{
    payload: {
      inCriteriaBundles: Array<CriteriaBundleModel>;
    };
  }>()
);

export const cleanCriteriaBundlesInSafetyCriteria = createAction(
  '[SAFETYCRITERIA] Clean CriteriaBundles in  SafetyCriteria'
);

export const updateCriteriaBundlesInSafetyCriteria = createAction(
  '[SAFETYCRITERIA] Add CriteriaBundle in  SafetyCriteria',
  props<{ payload: { inCriteriaBundles: CriteriaBundleModel[] } }>()
);

export const SafetyCriteriasActions = {
  updateCriteriaBundlesInSafetyCriteria,
  cleanCriteriaBundlesInSafetyCriteria,
  openSafetyCriteriaView,
  getSafetyCriterias,
  getSafetyCriteriasSuccess,
  getSafetyCriteriasFailure,
  getSafetyCriteria,
  getSafetyCriteriaSuccess,
  getSafetyCriteriaFailure,
  createSafetyCriteria,
  createSafetyCriteriaSuccess,
  createSafetyCriteriaFailure,
  updateSafetyCriteria,
  updateSafetyCriteriaSuccess,
  updateSafetyCriteriaFailure,
  deleteSafetyCriteria,
  deleteSafetyCriteriaSuccess,
  deleteSafetyCriteriaFailure,
};
