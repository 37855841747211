import { GoodsIssueDocumentsActions } from './actions';
import { GoodsIssueDocumentModel, GoodsIssueDocumentLineModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum GoodsIssueDocumentModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum GoodsIssueDocumentModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getGoodsIssueDocuments,
  getGoodsIssueDocumentsFailure,
  getGoodsIssueDocumentsSuccess,
  getGoodsIssueDocument,
  getGoodsIssueDocumentFailure,
  getGoodsIssueDocumentSuccess,
  createGoodsIssueDocument,
  createGoodsIssueDocumentFailure,
  createGoodsIssueDocumentSuccess,
  updateGoodsIssueDocument,
  updateGoodsIssueDocumentFailure,
  updateGoodsIssueDocumentSuccess,
  deleteGoodsIssueDocument,
  deleteGoodsIssueDocumentFailure,
  deleteGoodsIssueDocumentSuccess,
  openGoodsIssueDocumentView,
  cleanProductInGoodsIssueDocument,
  addProductInGoodsIssueDocument,
  editProductInGoodsIssueDocument,
  removeProductInGoodsIssueDocument,
} = GoodsIssueDocumentsActions;
export interface GoodsIssueDocumentState {
  list: Array<GoodsIssueDocumentModel>;
  item: GoodsIssueDocumentModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId?: number;
  };
  loading: boolean;
  modal: {
    type: GoodsIssueDocumentModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: GoodsIssueDocumentModalStatus;
  };
  inProducts: Array<GoodsIssueDocumentLineModel>;
}

export const initialState: GoodsIssueDocumentState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  loading: false,
  modal: {
    type: GoodsIssueDocumentModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: GoodsIssueDocumentModalStatus.Init,
  },
  inProducts: [],
};

export const reducer = createReducer(
  initialState,
  on(getGoodsIssueDocuments, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getGoodsIssueDocumentsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getGoodsIssueDocumentsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updateGoodsIssueDocument, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: GoodsIssueDocumentModalStatus.Called,
      type: GoodsIssueDocumentModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateGoodsIssueDocumentSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsIssueDocumentModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateGoodsIssueDocumentFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsIssueDocumentModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsIssueDocument, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: GoodsIssueDocumentModalStatus.Init,
      type: GoodsIssueDocumentModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsIssueDocumentSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsIssueDocumentModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsIssueDocumentFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsIssueDocumentModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteGoodsIssueDocument, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteGoodsIssueDocumentSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteGoodsIssueDocumentFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openGoodsIssueDocumentView, (state, { payload }) => {
    const modal = {
      ...state.modal,
      status: GoodsIssueDocumentModalStatus.Init,
      errors: [],
    };

    if (payload && Array.isArray(payload.inProducts)) {
      const lines = payload.inProducts;
      const inProducts = lines.map((c) => {
        return {
          ...c,
          uid: `server-${c.id}`,
          name: c.productName,
          note: c.note,
          unit: c.productUnit,
        };
      });
      return {
        ...state,
        modal,
        inProducts,
      };
    }

    return {
      ...state,
      modal,
      inProducts: [],
    };
  }),
  on(addProductInGoodsIssueDocument, (state, { payload }) => {
    const { product, quantity } = payload;
    const length = state.inProducts.length + 1;
    const productExist = state.inProducts.find(
      (p) => p.productId === product.id
    );
    if (productExist) {
      const inProducts = state.inProducts.map((p) => {
        let qty = quantity;
        if (p.productId === product.id) {
          qty += p.quantity;
        }
        return { ...p, quantity: qty, isEdited: true };
      });

      return {
        ...state,
        inProducts,
      };
    }
    const inProduct = {
      uid: `local-${length}`,
      name: product.name,
      productId: product.id,
      quantity,
      isNew: true,
    } as GoodsIssueDocumentLineModel;

    return {
      ...state,
      inProducts: [...state.inProducts, inProduct],
    };
  }),
  on(editProductInGoodsIssueDocument, (state, { payload }) => {
    const { product, quantity } = payload;
    const newProducts = state.inProducts.map((c) => {
      if (c.productId === product.productId && !c.isNew) {
        return {
          ...c,
          ...product,
          productId: product.productId,
          quantity,
          isEdited: true,
        };
      }
      if (c.productId === product.productId && c.isNew) {
        return { ...c, ...product, productId: product.productId };
      }
      return c;
    });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(removeProductInGoodsIssueDocument, (state, { payload }) => {
    if (!payload) {
      return {
        ...state,
      };
    }
    const { product } = payload;
    const newProducts = state.inProducts
      .map((c) => {
        if (c.productId === product.productId) {
          return { ...c, isRemoved: true };
        }
        return c;
      })
      .filter((c) => {
        if (c.productId === product.productId && c.isNew) {
          return false;
        }
        return true;
      });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(cleanProductInGoodsIssueDocument, (state) => {
    return {
      ...state,
      inProducts: [],
    };
  })
);
