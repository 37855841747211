import { NgModule } from '@angular/core';
import '@angular/common/locales/vi';
import { registerLocaleData } from '@angular/common';
import vi from '@angular/common/locales/vi';
import { AlainThemeModule } from '@delon/theme';
import { NestableModule } from 'src/modules/menu-builder/nestable.module';
import { NoticeIconModule } from '@delon/abc/notice-icon';
import { STModule } from '@delon/abc/st';
import { SEModule } from '@delon/abc/se';
import { PageHeaderModule } from '@delon/abc/page-header';
import { GlobalFooterModule } from '@delon/abc/global-footer';
import { DelonACLModule } from '@delon/acl';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';

import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzImageModule } from 'ng-zorro-antd/image';
import { vi_VN, NZ_I18N } from 'ng-zorro-antd/i18n';

import { NzPipesModule } from 'ng-zorro-antd/pipes';

import { environment } from '@env/environment';
registerLocaleData(vi);
const delonModule = [NoticeIconModule];
const antModule = [
  NzImageModule,
  NzCardModule,
  NzSelectModule,
  NzUploadModule,
  NzDropDownModule,
  NzTableModule,
  NzDescriptionsModule,
  NzToolTipModule,
  NzRateModule,
  NzFormModule,
  NzDividerModule,
  NzSpinModule,
  NzButtonModule,
  NzBreadCrumbModule,
  NzAvatarModule,
  NzTagModule,
  NzListModule,
  NzPageHeaderModule,
  NzBadgeModule,
  NzProgressModule,
  NzSkeletonModule,
  NzDrawerModule,
  NzStatisticModule,
  NzInputModule,
  NzDatePickerModule,
  NzPaginationModule,
  NzEmptyModule,
  NzPopconfirmModule,
  NzCheckboxModule,
  NzTimelineModule,
  NzTransferModule,
  NzAlertModule,
  NzMessageModule,
  NzSliderModule,
  NzSwitchModule,
  NzModalModule,
  NzIconModule,
  NzPopoverModule,
  NzLayoutModule,
  NzRadioModule,
  NzTabsModule,
  NzNotificationModule,
  NzPipesModule,
];
@NgModule({
  imports: [
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.ggKeygKey,
      libraries: ['places'],
    }),
    ToastContainerModule,
    AlainThemeModule,
    STModule,
    GlobalFooterModule,
    PageHeaderModule,
    SEModule,
    DelonACLModule,
    NestableModule,
    ...antModule,
    ...delonModule,
  ],
  exports: [
    STModule,
    GlobalFooterModule,
    PageHeaderModule,
    SEModule,
    AlainThemeModule,
    DelonACLModule,
    ...antModule,
    ...delonModule,
    AgmCoreModule,
    NestableModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: vi_VN }],
})
export class AppUIModule {}
