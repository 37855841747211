import { GoodsReceivedNotesActions } from './actions';
import { GoodsReceivedNoteModel, GoodsReceivedNoteLineModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum GoodsReceivedNoteModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum GoodsReceivedNoteModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getGoodsReceivedNotes,
  getGoodsReceivedNotesFailure,
  getGoodsReceivedNotesSuccess,
  getGoodsReceivedNote,
  getGoodsReceivedNoteFailure,
  getGoodsReceivedNoteSuccess,
  createGoodsReceivedNote,
  createGoodsReceivedNoteFailure,
  createGoodsReceivedNoteSuccess,
  updateGoodsReceivedNote,
  updateGoodsReceivedNoteFailure,
  updateGoodsReceivedNoteSuccess,
  deleteGoodsReceivedNote,
  deleteGoodsReceivedNoteFailure,
  deleteGoodsReceivedNoteSuccess,
  openGoodsReceivedNoteView,
  cleanProductInGoodsReceivedNote,
  addProductInGoodsReceivedNote,
  editProductInGoodsReceivedNote,
  removeProductInGoodsReceivedNote,
} = GoodsReceivedNotesActions;
export interface GoodsReceivedNoteState {
  list: Array<GoodsReceivedNoteModel>;
  item: GoodsReceivedNoteModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId?: number;
  };
  loading: boolean;
  modal: {
    type: GoodsReceivedNoteModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: GoodsReceivedNoteModalStatus;
  };
  inProducts: Array<GoodsReceivedNoteLineModel>;
}

export const initialState: GoodsReceivedNoteState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  loading: false,
  modal: {
    type: GoodsReceivedNoteModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: GoodsReceivedNoteModalStatus.Init,
  },
  inProducts: [],
};

export const reducer = createReducer(
  initialState,
  on(getGoodsReceivedNotes, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getGoodsReceivedNotesSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getGoodsReceivedNotesFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updateGoodsReceivedNote, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: GoodsReceivedNoteModalStatus.Called,
      type: GoodsReceivedNoteModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateGoodsReceivedNoteSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsReceivedNoteModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateGoodsReceivedNoteFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsReceivedNoteModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsReceivedNote, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: GoodsReceivedNoteModalStatus.Init,
      type: GoodsReceivedNoteModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsReceivedNoteSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsReceivedNoteModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsReceivedNoteFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsReceivedNoteModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteGoodsReceivedNote, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteGoodsReceivedNoteSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteGoodsReceivedNoteFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openGoodsReceivedNoteView, (state, { payload }) => {
    const modal = {
      ...state.modal,
      status: GoodsReceivedNoteModalStatus.Init,
      errors: [],
    };

    if (payload && Array.isArray(payload.inProducts)) {
      const lines = payload.inProducts;
      const inProducts = lines.map((c) => {
        return {
          ...c,
          uid: `server-${c.id}`,
          name: c.productName,
          note: c.note,
          unit: c.productUnit,
        };
      });
      return {
        ...state,
        modal,
        inProducts,
      };
    }

    return {
      ...state,
      modal,
      inProducts: [],
    };
  }),
  on(addProductInGoodsReceivedNote, (state, { payload }) => {
    const { product, quantity } = payload;
    const length = state.inProducts.length + 1;
    const productExist = state.inProducts.find(
      (p) => p.productId === product.id
    );
    if (productExist) {
      const inProducts = state.inProducts.map((p) => {
        let qty = quantity;
        if (p.productId === product.id) {
          qty += p.quantity;
        }
        return { ...p, quantity: qty, isEdited: true };
      });

      return {
        ...state,
        inProducts,
      };
    }
    const inProduct = {
      uid: `local-${length}`,
      name: product.name,
      productId: product.id,
      quantity,
      isNew: true,
    } as GoodsReceivedNoteLineModel;

    return {
      ...state,
      inProducts: [...state.inProducts, inProduct],
    };
  }),
  on(editProductInGoodsReceivedNote, (state, { payload }) => {
    const { product, quantity } = payload;
    const newProducts = state.inProducts.map((c) => {
      if (c.productId === product.productId && !c.isNew) {
        return {
          ...c,
          ...product,
          productId: product.productId,
          quantity,
          isEdited: true,
        };
      }
      if (c.productId === product.productId && c.isNew) {
        return { ...c, ...product, productId: product.productId };
      }
      return c;
    });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(removeProductInGoodsReceivedNote, (state, { payload }) => {
    if (!payload) {
      return {
        ...state,
      };
    }
    const { product } = payload;
    const newProducts = state.inProducts
      .map((c) => {
        if (c.productId === product.productId) {
          return { ...c, isRemoved: true };
        }
        return c;
      })
      .filter((c) => {
        if (c.productId === product.productId && c.isNew) {
          return false;
        }
        return true;
      });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(cleanProductInGoodsReceivedNote, (state) => {
    return {
      ...state,
      inProducts: [],
    };
  })
);
