import { createAction, props } from '@ngrx/store';

import { DesignFileModel } from './model';
import { DesignFileResultsModel, DesignFileResultModel } from './results.model';

const getDesignFiles = createAction('[DESIGNFILE] Get DesignFiles', props<{ payload: any }>());

const getDesignFilesSuccess = createAction('[DESIGNFILE] Get DesignFiles Success', props<{ payload: DesignFileResultsModel }>());

export const getDesignFilesFailure = createAction('[DESIGNFILE] Get DesignFiles Faild');

const getDesignFile = createAction('[DESIGNFILE] Get DesignFile', props<{ payload: any }>());

const getDesignFileSuccess = createAction('[DESIGNFILE] Get DesignFile Success', props<{ payload: DesignFileResultModel }>());

export const getDesignFileFailure = createAction('[DESIGNFILE] Get DesignFile Faild');

const createDesignFile = createAction('[DESIGNFILE] Create DesignFile', props<{ payload: DesignFileModel }>());

const createDesignFileSuccess = createAction('[DESIGNFILE] Create DesignFile Success', props<{ payload: DesignFileResultModel }>());

export const createDesignFileFailure = createAction('[DESIGNFILE] Create DesignFile Faild', props<{ payload: DesignFileResultModel }>());

const updateDesignFile = createAction('[DESIGNFILE] Update DesignFile', props<{ payload: DesignFileModel }>());

const updateDesignFileSuccess = createAction('[DESIGNFILE] Update DesignFile Success', props<{ payload: DesignFileResultModel }>());

export const updateDesignFileFailure = createAction('[DESIGNFILE] Update DesignFile Faild', props<{ payload: DesignFileResultModel }>());

const deleteDesignFile = createAction('[DESIGNFILE] Delete DesignFile', props<{ payload: number }>());

const deleteDesignFileSuccess = createAction('[DESIGNFILE] Delete DesignFile Success', props<{ payload: { fileId: number } }>());

export const deleteDesignFileFailure = createAction('[DESIGNFILE] Delete DesignFile Faild');

export const openDesignFileView = createAction('[DESIGNFILE] Open DesignFile View');

const downloadSessionFiles = createAction('[DESIGNFILE] Download SessionFiles', props<{ payload: { projectId: number } }>());
const downloadSessionFilesSuccess = createAction('[DESIGNFILE] Download SessionFiles Success', props<{ payload: Blob }>());
const downloadSessionFilesFailure = createAction('[DESIGNFILE] Download SessionFiles Faild');

export const DesignFilesActions = {
  downloadSessionFiles,
  downloadSessionFilesSuccess,
  downloadSessionFilesFailure,
  openDesignFileView,
  getDesignFiles,
  getDesignFilesSuccess,
  getDesignFilesFailure,
  getDesignFile,
  getDesignFileSuccess,
  getDesignFileFailure,
  createDesignFile,
  createDesignFileSuccess,
  createDesignFileFailure,
  updateDesignFile,
  updateDesignFileSuccess,
  updateDesignFileFailure,
  deleteDesignFile,
  deleteDesignFileSuccess,
  deleteDesignFileFailure,
};
