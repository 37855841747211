import { createAction, props } from '@ngrx/store';

import { WarehouseModel, WarehouseProductLineModel } from './model';
import { WarehouseResultsModel, WarehouseResultModel } from './results.model';
import {
  GoodsReceivedNoteResultsModel,
  GoodsReceivedNoteResultModel,
  GoodsReceivedNoteLineModel,
} from '../goods-received-note';
import {
  GoodsIssueDocumentResultsModel,
  GoodsIssueDocumentResultModel,
  GoodsIssueDocumentLineModel,
} from '../goods-issue-document';
import { ProductModel, ProductResultsModel } from '../product';
import { GoodsIssueRequestResultsModel } from '../manufacture-request';

const getWarehouses = createAction(
  '[WAREHOUSE] Get Warehouses',
  props<{ payload: any }>()
);

const getWarehousesSuccess = createAction(
  '[WAREHOUSE] Get Warehouses Success',
  props<{ payload: WarehouseResultsModel }>()
);

export const getWarehousesFailure = createAction(
  '[WAREHOUSE] Get Warehouses Faild'
);

const getWarehouse = createAction(
  '[WAREHOUSE] Get Warehouse',
  props<{ payload: number }>()
);

const getWarehouseSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Success',
  props<{ payload: WarehouseResultModel }>()
);

export const getWarehouseFailure = createAction(
  '[WAREHOUSE] Get Warehouse Faild'
);

const createWarehouse = createAction(
  '[WAREHOUSE] Create Warehouse',
  props<{ payload: WarehouseModel }>()
);

const createWarehouseSuccess = createAction(
  '[WAREHOUSE] Create Warehouse Success',
  props<{ payload: WarehouseResultModel }>()
);

export const createWarehouseFailure = createAction(
  '[WAREHOUSE] Create Warehouse Faild',
  props<{ payload: WarehouseResultModel }>()
);

const updateWarehouse = createAction(
  '[WAREHOUSE] Update Warehouse',
  props<{ payload: WarehouseModel }>()
);

const updateWarehouseSuccess = createAction(
  '[WAREHOUSE] Update Warehouse Success',
  props<{ payload: WarehouseResultModel }>()
);

export const updateWarehouseFailure = createAction(
  '[WAREHOUSE] Update Warehouse Faild',
  props<{ payload: WarehouseResultModel }>()
);

const deleteWarehouse = createAction(
  '[WAREHOUSE] Delete Warehouse',
  props<{ payload: number }>()
);

const deleteWarehouseSuccess = createAction(
  '[WAREHOUSE] Delete Warehouse Success',
  props<{ payload: WarehouseResultModel }>()
);

export const deleteWarehouseFailure = createAction(
  '[WAREHOUSE] Delete Warehouse Faild',
  props<{ payload: WarehouseResultModel }>()
);

export const openWarehouseView = createAction(
  '[WAREHOUSE] Open Warehouse View'
);

const getFactories = createAction(
  '[WAREHOUSE] Get Warehouse Factories',
  props<{ payload: any }>()
);

const getFactoriesSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Factories Success',
  props<{ payload: WarehouseResultsModel }>()
);

export const getFactoriesFailure = createAction(
  '[WAREHOUSE] Get Warehouse Factories Faild'
);

const getStocks = createAction(
  '[WAREHOUSE] Get Warehouse Stocks',
  props<{ payload: any }>()
);

const getStocksSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Stocks Success',
  props<{ payload: WarehouseResultsModel }>()
);

export const getStocksFailure = createAction(
  '[WAREHOUSE] Get Warehouse Stocks Faild'
);

const createWarehouseStock = createAction(
  '[WAREHOUSE] Create Warehouse Stock',
  props<{ payload: WarehouseModel }>()
);

const createWarehouseStockSuccess = createAction(
  '[WAREHOUSE] Create Warehouse Stock Success',
  props<{ payload: WarehouseResultModel }>()
);

export const createWarehouseStockFailure = createAction(
  '[WAREHOUSE] Create Warehouse Stock Faild',
  props<{ payload: WarehouseResultModel }>()
);

const updateWarehouseStock = createAction(
  '[WAREHOUSE] Update Warehouse Stock',
  props<{ payload: WarehouseModel }>()
);

const updateWarehouseStockSuccess = createAction(
  '[WAREHOUSE] Update Warehouse Stock Success',
  props<{ payload: WarehouseResultModel }>()
);

export const updateWarehouseStockFailure = createAction(
  '[WAREHOUSE] Update Warehouse Stock Faild',
  props<{ payload: WarehouseResultModel }>()
);

const deleteWarehouseStock = createAction(
  '[WAREHOUSE] Delete Warehouse Stock',
  props<{ payload: number }>()
);

const deleteWarehouseStockSuccess = createAction(
  '[WAREHOUSE] Delete Warehouse Stock Success',
  props<{ payload: WarehouseResultModel }>()
);

export const deleteWarehouseStockFailure = createAction(
  '[WAREHOUSE] Delete Warehouse Stock Faild',
  props<{ payload: WarehouseResultModel }>()
);

const getStock = createAction(
  '[WAREHOUSE] Get Warehouse Stock',
  props<{ payload: any }>()
);

const getStockSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Stock Success',
  props<{ payload: WarehouseResultModel }>()
);

export const getStockFailure = createAction(
  '[WAREHOUSE] Get Warehouse Stock Faild'
);

const getStockGoodsReceivedNotesSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsReceivedNotes Success',
  props<{ payload: GoodsReceivedNoteResultsModel }>()
);

export const getStockGoodsReceivedNotesFailure = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsReceivedNotes Faild'
);

export const openStockGoodsReceivedNoteView = createAction(
  '[WAREHOUSE] Open Warehouse Stock GoodsReceivedNote View',
  props<{
    payload: {
      inProducts: Array<GoodsReceivedNoteLineModel>;
    };
  }>()
);

const getStockGoodsReceivedNote = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsReceivedNote',
  props<{ payload: any }>()
);

const getStockGoodsReceivedNoteSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const getStockGoodsReceivedNoteFailure = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsReceivedNote Faild'
);

const createStockGoodsReceivedNote = createAction(
  '[WAREHOUSE] Create Warehouse Stock GoodsReceivedNote',
  props<{ payload: any }>()
);

const createStockGoodsReceivedNoteSuccess = createAction(
  '[WAREHOUSE] Create Warehouse Stock GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const createStockGoodsReceivedNoteFailure = createAction(
  '[WAREHOUSE] Create Warehouse Stock GoodsReceivedNote Faild',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

const updateStockGoodsReceivedNote = createAction(
  '[WAREHOUSE] Update Warehouse Stock GoodsReceivedNote',
  props<{ payload: any }>()
);

const updateStockGoodsReceivedNoteSuccess = createAction(
  '[WAREHOUSE] Update Warehouse Stock GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const updateStockGoodsReceivedNoteFailure = createAction(
  '[WAREHOUSE] Update Warehouse Stock GoodsReceivedNote Faild',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

const confirmStockGoodsReceivedNote = createAction(
  '[WAREHOUSE] Confirm Warehouse GoodsReceivedNote',
  props<{ payload: any }>()
);

const confirmStockGoodsReceivedNoteSuccess = createAction(
  '[WAREHOUSE] Confirm Warehouse GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const confirmStockGoodsReceivedNoteFailure = createAction(
  '[WAREHOUSE] Confirm Warehouse GoodsReceivedNote Success Faild'
);

const printStockGoodsReceivedNote = createAction(
  '[WAREHOUSE] Print Warehouse GoodsReceivedNote',
  props<{ payload: any }>()
);

const printStockGoodsReceivedNoteSuccess = createAction(
  '[WAREHOUSE] Print Warehouse GoodsReceivedNote Success',
  props<{ payload: Blob }>()
);

export const printStockGoodsReceivedNoteFailure = createAction(
  '[WAREHOUSE] Print Warehouse GoodsReceivedNote Success Faild'
);

const getStockGoodsIssueRequests = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsIssueRequests',
  props<{ payload: any }>()
);

const getStockGoodsIssueRequestsSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsIssueRequests Success',
  props<{ payload: GoodsIssueRequestResultsModel }>()
);

export const getStockGoodsIssueRequestsFailure = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsIssueRequests Faild'
);

const getStockGoodsIssueDocuments = createAction(
  '[WAREHOUSE] Get Warehouse GoodsIssueDocuments',
  props<{ payload: any }>()
);

const getStockGoodsIssueDocumentsSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsIssueDocuments Success',
  props<{ payload: GoodsIssueDocumentResultsModel }>()
);

export const getStockGoodsIssueDocumentsFailure = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsIssueDocuments Faild'
);

const confirmStockGoodsIssueDocument = createAction(
  '[WAREHOUSE] Confirm Warehouse GoodsIssueDocument',
  props<{ payload: any }>()
);

const confirmStockGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Confirm Warehouse GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const confirmStockGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Confirm Warehouse GoodsIssueDocument Success Faild'
);

const printStockGoodsIssueDocument = createAction(
  '[WAREHOUSE] Print Warehouse GoodsIssueDocument',
  props<{ payload: any }>()
);

const printStockGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Print Warehouse GoodsIssueDocument Success',
  props<{ payload: Blob }>()
);

export const printStockGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Print Warehouse GoodsIssueDocument Success Faild'
);

export const openStockGoodsIssueDocumentView = createAction(
  '[WAREHOUSE] Open Warehouse Stock GoodsIssueDocument View',
  props<{
    payload: {
      inProducts: Array<GoodsIssueDocumentLineModel>;
    };
  }>()
);

const getStockGoodsIssueDocument = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsIssueDocument',
  props<{ payload: any }>()
);

const getStockGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const getStockGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsIssueDocument Faild'
);

const createStockGoodsIssueDocument = createAction(
  '[WAREHOUSE] Create Warehouse Stock GoodsIssueDocument',
  props<{ payload: any }>()
);

const createStockGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Create Warehouse Stock GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const createStockGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Create Warehouse Stock GoodsIssueDocument Faild',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

const updateStockGoodsIssueDocument = createAction(
  '[WAREHOUSE] Update Warehouse Stock GoodsIssueDocument',
  props<{ payload: any }>()
);

const updateStockGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Update Warehouse Stock GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const updateStockGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Update Warehouse Stock GoodsIssueDocument Faild',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

const createWarehouseFactory = createAction(
  '[WAREHOUSE] Create Warehouse Factory',
  props<{ payload: WarehouseModel }>()
);

const createWarehouseFactorySuccess = createAction(
  '[WAREHOUSE] Create Warehouse Factory Success',
  props<{ payload: WarehouseResultModel }>()
);

export const createWarehouseFactoryFailure = createAction(
  '[WAREHOUSE] Create Warehouse Factory Faild',
  props<{ payload: WarehouseResultModel }>()
);

const updateWarehouseFactory = createAction(
  '[WAREHOUSE] Update Warehouse Factory',
  props<{ payload: WarehouseModel }>()
);

const updateWarehouseFactorySuccess = createAction(
  '[WAREHOUSE] Update Warehouse Factory Success',
  props<{ payload: WarehouseResultModel }>()
);

export const updateWarehouseFactoryFailure = createAction(
  '[WAREHOUSE] Update Warehouse Factory Faild',
  props<{ payload: WarehouseResultModel }>()
);

const deleteWarehouseFactory = createAction(
  '[WAREHOUSE] Delete Warehouse Factory',
  props<{ payload: number }>()
);

const deleteWarehouseFactorySuccess = createAction(
  '[WAREHOUSE] Delete Warehouse Factory Success',
  props<{ payload: WarehouseResultModel }>()
);

export const deleteWarehouseFactoryFailure = createAction(
  '[WAREHOUSE] Delete Warehouse Factory Faild',
  props<{ payload: WarehouseResultModel }>()
);

export const openFactoryGoodsReceivedNoteView = createAction(
  '[WAREHOUSE] Open Warehouse Factory GoodsReceivedNote View',
  props<{
    payload: {
      inProducts: Array<GoodsReceivedNoteLineModel>;
    };
  }>()
);

const getFactory = createAction(
  '[WAREHOUSE] Get Warehouse Factory',
  props<{ payload: any }>()
);

const getFactorySuccess = createAction(
  '[WAREHOUSE] Get Warehouse Factory Success',
  props<{ payload: WarehouseResultModel }>()
);

export const getFactoryFailure = createAction(
  '[WAREHOUSE] Get Warehouse Factory Faild'
);

const getStockGoodsReceivedNotes = createAction(
  '[WAREHOUSE] Get Warehouse Stock GoodsReceivedNotes',
  props<{ payload: any }>()
);

const getFactoryManufactureRequests = createAction(
  '[WAREHOUSE] Get Warehouse Factory ManufactureRequests',
  props<{ payload: any }>()
);

const getFactoryManufactureRequestsSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Factory ManufactureRequests Success',
  props<{ payload: GoodsIssueRequestResultsModel }>()
);

export const getFactoryManufactureRequestsFailure = createAction(
  '[WAREHOUSE] Get Warehouse Factory ManufactureRequests Faild'
);

export const openFactoryGoodsIssueDocumentView = createAction(
  '[WAREHOUSE] Open Warehouse Factory GoodsIssueDocument View',
  props<{
    payload: {
      inProducts: Array<GoodsIssueDocumentLineModel>;
    };
  }>()
);

const getFactoryGoodsReceivedNotes = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsReceivedNotes',
  props<{ payload: any }>()
);

const getFactoryGoodsReceivedNotesSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsReceivedNotes Success',
  props<{ payload: GoodsReceivedNoteResultsModel }>()
);

export const getFactoryGoodsReceivedNotesFailure = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsReceivedNotes Faild'
);

const getFactoryGoodsReceivedNote = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsReceivedNote',
  props<{ payload: any }>()
);

const getFactoryGoodsReceivedNoteSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const getFactoryGoodsReceivedNoteFailure = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsReceivedNote Faild'
);

const confirmFactoryGoodsIssueDocument = createAction(
  '[WAREHOUSE] Confirm Factory GoodsIssueDocument',
  props<{ payload: any }>()
);

const confirmFactoryGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Confirm Factory GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const confirmFactoryGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Confirm Factory GoodsIssueDocument Success Faild'
);

const printFactoryGoodsIssueDocument = createAction(
  '[WAREHOUSE] Print Factory GoodsIssueDocument',
  props<{ payload: any }>()
);

const printFactoryGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Print Factory GoodsIssueDocument Success',
  props<{ payload: Blob }>()
);

export const printFactoryGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Print Factory GoodsIssueDocument Success Faild'
);

const getFactoryGoodsIssueDocuments = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsIssueDocuments',
  props<{ payload: any }>()
);

const getFactoryGoodsIssueDocumentsSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsIssueDocuments Success',
  props<{ payload: GoodsIssueDocumentResultsModel }>()
);

export const getFactoryGoodsIssueDocumentsFailure = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsIssueDocuments Faild'
);

const getFactoryGoodsIssueDocument = createAction(
  '[WAREHOUSE] Get Warehouse FactoryGoodsIssueDocument',
  props<{ payload: any }>()
);

const getFactoryGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const getFactoryGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Get Warehouse Factory GoodsIssueDocument Faild'
);

const createFactoryGoodsIssueDocument = createAction(
  '[WAREHOUSE] Create Factory Warehouse GoodsIssueDocument',
  props<{ payload: any }>()
);

const createFactoryGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Create Warehouse Factory GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const createFactoryGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Create Warehouse Factory GoodsIssueDocument Faild',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

const updateFactoryGoodsIssueDocument = createAction(
  '[WAREHOUSE] Update Warehouse Factory GoodsIssueDocument',
  props<{ payload: any }>()
);

const updateFactoryGoodsIssueDocumentSuccess = createAction(
  '[WAREHOUSE] Update Warehouse Factory GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const updateFactoryGoodsIssueDocumentFailure = createAction(
  '[WAREHOUSE] Update Warehouse Factory GoodsIssueDocument Faild',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const cleanWarehouseProductsInDocument = createAction(
  '[WAREHOUSE] Clean Products in WarehouseProductsInDocument'
);

export const addProductInWarehouseProductsInDocument = createAction(
  '[WAREHOUSE] Add Product in WarehouseProductsInDocument',
  props<{ payload: { product: ProductModel; quantity: number } }>()
);

export const editProductInWarehouseProductsInDocument = createAction(
  '[WAREHOUSE] Edit Product in WarehouseProductsInDocument',
  props<{
    payload: { product: WarehouseProductLineModel; quantity: number };
  }>()
);

export const removeProductInWarehouseProductsInDocument = createAction(
  '[WAREHOUSE] Remove Product in WarehouseProductsInDocument',
  props<{ payload: { product: WarehouseProductLineModel } }>()
);

const getWarehouseProducts = createAction(
  '[WAREHOUSE] Get Warehouse Products',
  props<{ payload: { warehouseId: number; productName?: string } }>()
);

const getWarehouseProductsSuccess = createAction(
  '[WAREHOUSE] Get Warehouse Products Success',
  props<{ payload: ProductResultsModel }>()
);

export const getWarehouseProductsFailure = createAction(
  '[WAREHOUSE] Get Warehouse Products Faild'
);

export const WarehousesActions = {
  getWarehouseProducts,
  getWarehouseProductsSuccess,
  getWarehouseProductsFailure,
  confirmFactoryGoodsIssueDocument,
  confirmFactoryGoodsIssueDocumentSuccess,
  confirmFactoryGoodsIssueDocumentFailure,
  printFactoryGoodsIssueDocument,
  printFactoryGoodsIssueDocumentSuccess,
  printFactoryGoodsIssueDocumentFailure,
  confirmStockGoodsReceivedNote,
  confirmStockGoodsReceivedNoteSuccess,
  confirmStockGoodsReceivedNoteFailure,
  printStockGoodsReceivedNote,
  printStockGoodsReceivedNoteSuccess,
  printStockGoodsReceivedNoteFailure,
  confirmStockGoodsIssueDocument,
  confirmStockGoodsIssueDocumentSuccess,
  confirmStockGoodsIssueDocumentFailure,
  printStockGoodsIssueDocument,
  printStockGoodsIssueDocumentSuccess,
  printStockGoodsIssueDocumentFailure,
  updateWarehouseFactory,
  updateWarehouseFactorySuccess,
  updateWarehouseFactoryFailure,
  deleteWarehouseFactory,
  deleteWarehouseFactorySuccess,
  deleteWarehouseFactoryFailure,
  updateWarehouseStock,
  updateWarehouseStockSuccess,
  updateWarehouseStockFailure,
  deleteWarehouseStock,
  deleteWarehouseStockSuccess,
  deleteWarehouseStockFailure,

  createWarehouseStock,
  createWarehouseStockSuccess,
  createWarehouseStockFailure,
  createWarehouseFactory,
  createWarehouseFactorySuccess,
  createWarehouseFactoryFailure,
  createFactoryGoodsIssueDocument,
  createFactoryGoodsIssueDocumentSuccess,
  createFactoryGoodsIssueDocumentFailure,
  updateFactoryGoodsIssueDocument,
  updateFactoryGoodsIssueDocumentSuccess,
  updateFactoryGoodsIssueDocumentFailure,
  getFactoryManufactureRequests,
  getFactoryManufactureRequestsSuccess,
  getFactoryManufactureRequestsFailure,
  cleanWarehouseProductsInDocument,
  addProductInWarehouseProductsInDocument,
  editProductInWarehouseProductsInDocument,
  removeProductInWarehouseProductsInDocument,
  getStockGoodsIssueRequests,
  getStockGoodsIssueRequestsSuccess,
  getStockGoodsIssueRequestsFailure,
  updateStockGoodsIssueDocument,
  updateStockGoodsIssueDocumentSuccess,
  updateStockGoodsIssueDocumentFailure,
  createStockGoodsIssueDocument,
  createStockGoodsIssueDocumentSuccess,
  createStockGoodsIssueDocumentFailure,
  updateStockGoodsReceivedNote,
  updateStockGoodsReceivedNoteSuccess,
  updateStockGoodsReceivedNoteFailure,
  createStockGoodsReceivedNote,
  createStockGoodsReceivedNoteSuccess,
  createStockGoodsReceivedNoteFailure,
  openStockGoodsIssueDocumentView,
  openFactoryGoodsIssueDocumentView,
  openStockGoodsReceivedNoteView,
  openFactoryGoodsReceivedNoteView,
  getFactoryGoodsIssueDocument,
  getFactoryGoodsIssueDocumentSuccess,
  getFactoryGoodsIssueDocumentFailure,
  getFactoryGoodsReceivedNote,
  getFactoryGoodsReceivedNoteSuccess,
  getFactoryGoodsReceivedNoteFailure,
  getStockGoodsReceivedNote,
  getStockGoodsReceivedNoteSuccess,
  getStockGoodsReceivedNoteFailure,
  getStockGoodsIssueDocument,
  getStockGoodsIssueDocumentSuccess,
  getStockGoodsIssueDocumentFailure,
  getFactoryGoodsIssueDocuments,
  getFactoryGoodsIssueDocumentsSuccess,
  getFactoryGoodsIssueDocumentsFailure,
  getFactoryGoodsReceivedNotes,
  getFactoryGoodsReceivedNotesSuccess,
  getFactoryGoodsReceivedNotesFailure,
  getStockGoodsIssueDocuments,
  getStockGoodsIssueDocumentsSuccess,
  getStockGoodsIssueDocumentsFailure,
  getStockGoodsReceivedNotes,
  getStockGoodsReceivedNotesSuccess,
  getStockGoodsReceivedNotesFailure,
  getFactory,
  getFactorySuccess,
  getFactoryFailure,
  getStock,
  getStockSuccess,
  getStockFailure,
  getStocks,
  getStocksSuccess,
  getStocksFailure,
  getFactories,
  getFactoriesSuccess,
  getFactoriesFailure,
  openWarehouseView,
  getWarehouses,
  getWarehousesSuccess,
  getWarehousesFailure,
  getWarehouse,
  getWarehouseSuccess,
  getWarehouseFailure,
  createWarehouse,
  createWarehouseSuccess,
  createWarehouseFailure,
  updateWarehouse,
  updateWarehouseSuccess,
  updateWarehouseFailure,
  deleteWarehouse,
  deleteWarehouseSuccess,
  deleteWarehouseFailure,
};
