import { TripsActions } from './actions';
import { TripModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum TripModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum TripModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getTripWithLorry,
  getTripWithLorrySuccess,
  getTripWithLorryFailure,
  getTrips,
  getTripsFailure,
  getTripsSuccess,
  getTrip,
  getTripFailure,
  getTripSuccess,
  createTrip,
  createTripFailure,
  createTripSuccess,
  updateTrip,
  updateTripFailure,
  updateTripSuccess,
  deleteTrip,
  deleteTripFailure,
  deleteTripSuccess,
  openTripView,
  addTransportRequest,
  addTransportRequestSuccess,
  addTransportRequestFailure,
} = TripsActions;
export interface TripState {
  list: Array<TripModel>;
  item: TripModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    fullName?: string;
  };
  loading: boolean;
  modal: {
    type: TripModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: TripModalStatus;
  };
}

export const initialState: TripState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    fullName: null,
    name: null,
  },
  loading: false,
  modal: {
    type: TripModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: TripModalStatus.Init,
  },
};

export const reducer = createReducer(
  initialState,
  on(getTrips, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getTripsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getTripsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getTrip, (state) => {
    return { ...state, loading: true, item: null };
  }),
  on(getTripSuccess, (state, { payload }) => {
    return {
      ...state,
      item: { ...payload.data },
      loading: false,
    };
  }),
  on(getTripFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getTripWithLorry, (state) => {
    return { ...state, loading: true, item: null };
  }),
  on(getTripWithLorrySuccess, (state, { payload }) => {
    return {
      ...state,
      item: { ...payload.data },
      loading: false,
    };
  }),
  on(getTripWithLorryFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updateTrip, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: TripModalStatus.Called,
      type: TripModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateTripSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TripModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateTripFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TripModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createTrip, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: TripModalStatus.Init,
      type: TripModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createTripSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TripModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createTripFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TripModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(addTransportRequest, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: TripModalStatus.Init,
      type: TripModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(addTransportRequestSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TripModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(addTransportRequestFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: TripModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteTrip, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteTripSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteTripFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openTripView, (state) => {
    const modal = {
      ...state.modal,
      status: TripModalStatus.Init,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  })
);
