import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { PermissionsService } from '../services';
import { SettingService } from '@app/@core/services/setting/setting.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {
  constructor(
    private permissionsService: PermissionsService,
    private settingService: SettingService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const routerPath = this.getRouterPath(route);
    const flatMenus = this.settingService.flatMenu;
    const enter = this.permissionsService.checkAuthorizationWithMenusSync(
      routerPath,
      flatMenus
    );

    return enter;
  }

  getRouterPath(route: ActivatedRouteSnapshot) {
    return route.pathFromRoot
      .filter((p) => p.routeConfig && p.routeConfig.path !== '')
      .map((p) => '/' + p.routeConfig.path)
      .join('');
  }
}
