import { WorkOutlinesActions } from './actions';
import { WorkOutlineModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum WorkOutlineModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum WorkOutlineModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getWorkOutlines,
  getWorkOutlinesFailure,
  getWorkOutlinesSuccess,
  getWorkOutline,
  getWorkOutlineFailure,
  getWorkOutlineSuccess,
  createWorkOutline,
  createWorkOutlineFailure,
  createWorkOutlineSuccess,
  updateWorkOutline,
  updateWorkOutlineFailure,
  updateWorkOutlineSuccess,
  deleteWorkOutline,
  deleteWorkOutlineFailure,
  deleteWorkOutlineSuccess,
  openWorkOutlineView,
} = WorkOutlinesActions;
export interface WorkOutlineState {
  list: Array<WorkOutlineModel>;
  item: WorkOutlineModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    fullName?: string;
  };
  loading: boolean;
  modal: {
    type: WorkOutlineModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: WorkOutlineModalStatus;
  };
}

export const initialState: WorkOutlineState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    fullName: null,
    name: null,
  },
  loading: false,
  modal: {
    type: WorkOutlineModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: WorkOutlineModalStatus.Init,
  },
};

export const reducer = createReducer(
  initialState,
  on(getWorkOutlines, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getWorkOutlinesSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getWorkOutlinesFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updateWorkOutline, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: WorkOutlineModalStatus.Called,
      type: WorkOutlineModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWorkOutlineSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WorkOutlineModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWorkOutlineFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WorkOutlineModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWorkOutline, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: WorkOutlineModalStatus.Init,
      type: WorkOutlineModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWorkOutlineSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WorkOutlineModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWorkOutlineFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WorkOutlineModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteWorkOutline, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteWorkOutlineSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteWorkOutlineFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openWorkOutlineView, (state) => {
    const modal = {
      ...state.modal,
      status: WorkOutlineModalStatus.Init,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  })
);
