import { SettingsActions } from './actions';
import { SettingModel } from './model';
import { createReducer, on } from '@ngrx/store';

const {
  getSettings,
  getSettingsFailure,
  getSettingsSuccess,
  getSettingProject,
  getSettingProjectSuccess,
  getSettingProjectFailure,
  printReportAttendance,
  printReportAttendanceSuccess,
  printReportAttendanceFailure,
} = SettingsActions;
export interface SettingState {
  list: Array<SettingModel>;
  item: SettingModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    fullName?: string;
  };
  loading: boolean;

  printLoading: boolean;
  printData: Blob;
}

export const initialState: SettingState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    fullName: null,
    name: null,
  },
  loading: false,

  printLoading: false,
  printData: null,
};

export const reducer = createReducer(
  initialState,
  on(getSettings, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getSettingsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getSettingsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getSettingProject, (state) => {
    return { ...state, loading: true };
  }),
  on(getSettingProjectSuccess, (state, { payload }) => {
    return {
      ...state,
      loading: false,
      list: [...payload.data],
    };
  }),
  on(getSettingProjectFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(printReportAttendance, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: true,
    };
  }),
  on(printReportAttendanceSuccess, (state, { payload }) => {
    return {
      ...state,
      printData: payload,
      printLoading: false,
    };
  }),
  on(printReportAttendanceFailure, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: false,
    };
  })
);
