import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import * as authActions from './auth.actions';
import { UsersActions } from '@app/pages/@store/user';
import { AuthenticationService } from '../services';
import { Observable, of } from 'rxjs';
import { SettingService } from '@app/@core/services/setting/setting.service';

const { AuthActionTypes } = authActions;

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions<authActions.AuthActions>,
    private authenticationService: AuthenticationService,
    private settingService: SettingService
  ) {}

  @Effect()
  login$: Observable<any> = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN),
    switchMap(({ payload }) => {
      return this.authenticationService.login(payload.username, payload.password).pipe(
        map((user) => new authActions.LoginSuccess(user)),
        catchError((error) => {
          console.log(error);
          return of(new authActions.LoginFailure({ error }));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  loginSuccess$: Observable<any> = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    switchMap((res) => {
      this.settingService.setUser(res.payload);
      return of(UsersActions.getProfilePermissions());
    }),
    tap((res) => {
      // this.router.navigate(['/page/dashboard']);
      const url = window.location.origin;
      window.location.href = url;
    })
  );
}
