import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SettingService } from './setting/setting.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '@delon/theme';
import { environment } from '@env/environment';
@Injectable({ providedIn: 'root' })
export class StartupService {
  constructor(
    private httpClient: HttpClient,
    private menuService: MenuService,
    private settingService: SettingService,
    private translateService: TranslateService
  ) {}

  init(): void {}

  load(): Promise<any> {
    return new Promise((resolve) => {
      zip(
        this.httpClient.get(`/assets/data/i18n/vi-data.json`),
        this.httpClient.get(`/assets/data/app-data.json`),
        this.httpClient.get(`${environment.apiUrl}/api/myRoles`)
      )
        .pipe(
          catchError(() => {
            return [];
          })
        )
        .subscribe(
          ([langData, appData, rolesData]) => {
            this.setupLanguage(langData);
            this.setupAppInfo(appData?.app);

            const rolesCode = this.getMyRoles(rolesData?.data);
            this.setupRoles(rolesCode);

            const hasAdminRoles = this.hasAdminRole(rolesCode);

            const menuDefault = appData?.menu;
            const firstRoleData = this.getFirstRoleData(rolesData?.data);

            const myMenu = this.getMenuData(menuDefault, firstRoleData, hasAdminRoles);
            this.setupMenu(myMenu);
            this.setupPermisionRouter(myMenu);
          },
          (e) => {
            console.log(e);
          },
          () => {
            resolve(null);
          }
        );
    });
  }

  setupLanguage(langData) {
    this.translateService.setTranslation('vi', langData);
    this.translateService.setDefaultLang('vi');
  }

  setupAppInfo(appInfo) {
    this.settingService.setApp(appInfo);
  }

  setupMenu(myMenu) {
    const menuItems = myMenu.map((item) => this.buildMenuItem(item));
    this.menuService.add([
      {
        group: true,
        children: menuItems,
      },
    ]);
  }

  setupPermisionRouter(myMenu) {
    const flatMenu = this.getFlatMenu(myMenu);
    this.settingService.setFlatMenu(flatMenu);
  }

  buildMenuItem(item) {
    let childrenItem = [];
    if (Array.isArray(item.children)) {
      childrenItem = item.children.map((c) => this.buildMenuItem(c));
    }
    return {
      text: item.title,
      i18n: item.title,
      icon: item.iconCode,
      link: item.link,
      children: childrenItem,
    };
  }

  getFlatMenu(items) {
    return items
      .reduce((current, next) => {
        const children = this.getFlatMenuChildren(next);
        current = current.concat(children);
        return current;
      }, [])
      .map((menuItem) => {
        return menuItem.link;
      });
  }

  getFlatMenuChildren(menu) {
    if (Array.isArray(menu.children) && menu.children.length) {
      return menu.children.reduce((current, next) => {
        const children = this.getFlatMenuChildren(next);
        current = current.concat(children);
        return current;
      }, []);
    }

    return [menu];
  }

  hasAdminRole(rolesCode) {
    if (!Array.isArray(rolesCode)) {
      return false;
    }
    return rolesCode.some((role) => {
      return role === 'ADMIN';
    });
  }

  setupRoles(roles) {
    this.settingService.setRoles(roles);
  }

  getMyRoles(rolesData) {
    if (!Array.isArray(rolesData)) {
      return [];
    }
    return rolesData.map((role) => {
      return role.code || 'NORMAL';
    });
  }

  getFirstRoleData(rolesData) {
    if (!Array.isArray(rolesData)) {
      return null;
    }

    return rolesData[0];
  }

  getMenuData(menuDefault, myRoleData, hasAdminRole) {
    try {
      const myMenu = JSON.parse(myRoleData?.webMenu);
      return myMenu?.children || [];
    } catch (error) {
      console.log('Tài khoản này chưa cấu hình menu, vui lòng liên hệ với Admin hoặc IT để hỗ trợ', error.message);
      return hasAdminRole ? menuDefault : [];
    }
  }
}
