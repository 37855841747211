import { ProjectsActions, removeProjectDesignFile } from './actions';
import { ProjectModel, ProjectLogModel, ProjectRegistrationModel } from './model';
import { createReducer, on } from '@ngrx/store';
import { ProductRequirementModel } from '../product-requirement/model';
import { ProductModel } from '../product';
import { WorkerModel, WorkerAttendanceModel } from '../worker';
import { UploadSessionModel } from '../design-file';
import { SafetyCriteriaModel } from '../safety-criteria';
import { QualityCriteriaModel } from '../quality-criteria';
import { WorkOutlineModel } from '../work-outline';
import { MarkModel } from '../criteria-bundle';

export enum ProjectModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum ProjectModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

export enum ProjectProductRequirementsModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum ProjectProductRequirementsModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

export enum ProjectStatusModalType {
  Pause = 0,
  Resume = 1,
  Finish = 2,
}

const {
  getProjects,
  getProjectsFailure,
  getProjectsSuccess,
  getReportProjects,
  getReportProjectsSuccess,
  getReportProjectsFailure,
  getProjectsForMap,
  getProjectsForMapSuccess,
  getProjectsForMapFailure,
  getProject,
  getProjectFailure,
  getProjectSuccess,
  createProject,
  createProjectFailure,
  createProjectSuccess,
  updateProject,
  updateProjectFailure,
  updateProjectSuccess,
  deleteProject,
  deleteProjectFailure,
  deleteProjectSuccess,
  openProjectView,
  getProjectProductRequirements,
  getProjectProductRequirementsSuccess,
  getProjectProductRequirementsFailure,
  getProjectWorkers,
  getProjectWorkersSuccess,
  getProjectWorkersFailure,
  cleanProjectMembers,
  removeProjectMember,
  getProjectUploadSessions,
  getProjectUploadSessionsSuccess,
  getProjectUploadSessionsFailure,
  addProjectDesignFile,
  getProjectWorkOutlines,
  getProjectWorkOutlinesSuccess,
  getProjectWorkOutlinesFailure,
  getProjectSafetyCriterias,
  getProjectSafetyCriteriasSuccess,
  getProjectSafetyCriteriasFailure,
  getProjectQualityCriterias,
  getProjectQualityCriteriasSuccess,
  getProjectQualityCriteriasFailure,
  getProjectAttendances,
  getProjectAttendancesSuccess,
  getProjectAttendancesFailure,
  getProjectLogs,
  getProjectLogsSuccess,
  getProjectLogsFailure,

  getProjectRegistrations,
  getProjectRegistrationsSuccess,
  getProjectRegistrationsFailure,

  getProjectPhotosComplete,
  getProjectPhotosCompleteSuccess,
  getProjectPhotosCompleteFailure,
  getProjectPhotosCheckin,
  getProjectPhotosCheckinSuccess,
  getProjectPhotosCheckinFailure,

  getMarkSessions,
  getMarkSessionsSuccess,
  getMarkSessionsFailure,

  markCriteriaBundle,
  markCriteriaBundleFailure,
  markCriteriaBundleSuccess,

  markProjectDone,
  markProjectDoneSuccess,
  markProjectDoneFailure,
  markProjectAccountingDone,
  markProjectAccountingDoneSuccess,
  markProjectAccountingDoneFailure,
  pauseProject,
  pauseProjectFailure,
  pauseProjectSuccess,
  resumeProject,
  resumeProjectSuccess,
  resumeProjectFailure,
  openResumeProjectView,
} = ProjectsActions;
export interface ProjectState {
  list: Array<ProjectModel>;
  projectsForMap: Array<ProjectModel>;
  item: ProjectModel;
  itemLoading: boolean;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
  };
  loading: boolean;
  modal: {
    type: ProjectModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: ProjectModalStatus;
  };
  productRequirements: Array<ProductRequirementModel>;
  productRequirementsLoading: boolean;
  productRequirementsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  productRequirementsParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };
  productRequirementsModal: {
    type: ProjectProductRequirementsModalType.View;
    data: null;
    isLoading: false;
    errors: [];
    status: ProjectProductRequirementsModalStatus.Init;
  };

  productRequirementProducts: Array<ProductModel>;
  productRequirementProductsLoading: boolean;

  workers: Array<WorkerModel>;
  workersLoading: boolean;

  uploadSessions: Array<UploadSessionModel>;
  uploadSessionsLoading: boolean;
  uploadSessionsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };

  workOutlines: Array<WorkOutlineModel>;
  workOutlinesLoading: boolean;
  safetyCriterias: SafetyCriteriaModel[];
  safetyCriteriasLoading: boolean;
  safetyCriteriasPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };

  qualityCriterias: QualityCriteriaModel[];
  qualityCriteriasLoading: boolean;
  qualityCriteriasPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  attendances: WorkerAttendanceModel[];
  attendancesLoading: boolean;

  logs: ProjectLogModel[];
  logsLoading: boolean;

  registrations: ProjectRegistrationModel[];
  registrationsLoading: boolean;
  registrationsParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    projectId?: number;
  };
  registrationsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };

  photosCheckin: Array<any>;
  photosComplete: Array<any>;

  markSessions: Array<MarkModel>;
  markSessionsLoading: boolean;

  resumeModal: {
    type: ProjectModalType;
    isLoading: boolean;
    errors: Array<any>;
    status: ProjectModalStatus;
  };
}

export const initialState: ProjectState = {
  list: [],
  projectsForMap: [],
  item: null,
  itemLoading: false,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
  },
  loading: false,
  modal: {
    type: ProjectModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: ProjectModalStatus.Init,
  },
  productRequirements: [],
  productRequirementsLoading: false,
  productRequirementsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  productRequirementsModal: {
    type: ProjectProductRequirementsModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: ProjectProductRequirementsModalStatus.Init,
  },
  productRequirementsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  productRequirementProducts: [],
  productRequirementProductsLoading: false,
  workers: [],
  workersLoading: false,

  uploadSessions: [],
  uploadSessionsLoading: false,
  uploadSessionsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  workOutlines: [],
  workOutlinesLoading: false,

  safetyCriterias: [],
  safetyCriteriasLoading: false,
  safetyCriteriasPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },

  qualityCriterias: [],
  qualityCriteriasLoading: false,
  qualityCriteriasPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  attendances: [],
  attendancesLoading: false,

  logs: [],
  logsLoading: false,
  registrations: [],
  registrationsLoading: false,
  registrationsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    projectId: null,
  },

  registrationsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },

  photosCheckin: [],
  photosComplete: [],

  markSessions: [],
  markSessionsLoading: false,

  resumeModal: {
    type: ProjectModalType.View,
    isLoading: false,
    errors: [],
    status: ProjectModalStatus.Init,
  },
};

export const reducer = createReducer(
  initialState,
  on(getProjects, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getProjectsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getProjectsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getReportProjects, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getReportProjectsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getReportProjectsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getProjectsForMap, (state, { payload }) => {
    return { ...state, loading: true };
  }),
  on(getProjectsForMapSuccess, (state, { payload }) => {
    return {
      ...state,
      projectsForMap: [...payload.data],
      loading: false,
    };
  }),
  on(getProjectsForMapFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getProject, (state) => {
    return { ...state, itemLoading: true };
  }),
  on(getProjectSuccess, (state, { payload }) => {
    return {
      ...state,
      item: { ...payload.data },
      itemLoading: false,
    };
  }),
  on(getProjectFailure, (state) => {
    return {
      ...state,
      itemLoading: false,
    };
  }),
  on(updateProject, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: ProjectModalStatus.Called,
      type: ProjectModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateProjectSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProjectModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateProjectFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProjectModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(markProjectDone, (state) => {
    return {
      ...state,
      itemLoading: true,
    };
  }),
  on(markProjectDoneSuccess, (state) => {
    return {
      ...state,
      itemLoading: false,
    };
  }),
  on(markProjectDoneFailure, (state, { payload }) => {
    return {
      ...state,
      itemLoading: false,
    };
  }),
  on(markProjectAccountingDone, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: ProjectModalStatus.Init,
      type: ProjectModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(markProjectAccountingDoneSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProjectModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(markProjectAccountingDoneFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProjectModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createProject, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: ProjectModalStatus.Init,
      type: ProjectModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createProjectSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProjectModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createProjectFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProjectModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteProject, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteProjectSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteProjectFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openProjectView, (state) => {
    const modal = {
      ...state.modal,
      status: ProjectModalStatus.Init,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(getProjectProductRequirements, (state, { payload }) => {
    return {
      ...state,
      productRequirementsLoading: true,
      productRequirementsParams: { ...payload },
    };
  }),
  on(getProjectProductRequirementsSuccess, (state, { payload }) => {
    return {
      ...state,
      productRequirements: [...payload.data],
      productRequirementsPagination: { ...payload.pagination },
      productRequirementsLoading: false,
    };
  }),
  on(getProjectProductRequirementsFailure, (state) => {
    return {
      ...state,
      productRequirementsLoading: false,
    };
  }),
  on(getProjectWorkers, (state) => {
    return { ...state, workersLoading: true };
  }),
  on(getProjectWorkersSuccess, (state, { payload }) => {
    return {
      ...state,
      workers: [...payload.data],
      workersLoading: false,
    };
  }),
  on(getProjectWorkersFailure, (state) => {
    return {
      ...state,
      workersLoading: false,
    };
  }),
  on(cleanProjectMembers, (state) => {
    return {
      ...state,
      workers: [],
    };
  }),
  on(removeProjectMember, (state, { memberId }) => {
    const newMembers = state.workers.filter((member) => member.id !== memberId);
    return {
      ...state,
      workers: newMembers,
    };
  }),
  on(getProjectUploadSessions, (state, { payload }) => {
    return {
      ...state,
      uploadSessionsLoading: true,
      params: { ...payload },
    };
  }),
  on(getProjectUploadSessionsSuccess, (state, { payload }) => {
    if (!payload || !payload.data) {
      return { ...state };
    }

    return {
      ...state,
      uploadSessions: [...payload.data],
      uploadSessionsPagination: { ...payload.pagination },
      uploadSessionsLoading: false,
    };
  }),
  on(getProjectUploadSessionsFailure, (state) => {
    return {
      ...state,
      uploadSessionsLoading: false,
    };
  }),
  on(addProjectDesignFile, (state, { payload }) => {
    if (payload.data) {
      const uploadSessions = state.uploadSessions.map((u) => {
        if (u.id === payload.data.uploadSessionId) {
          const designFiles = u.designFiles.concat([payload.data]);
          return { ...u, designFiles };
        }
        return u;
      });

      return {
        ...state,
        uploadSessions,
      };
    }
    return {
      ...state,
    };
  }),
  on(removeProjectDesignFile, (state, { payload }) => {
    if (payload.fileId) {
      const uploadSessions = state.uploadSessions.map((u) => {
        const designFiles = u.designFiles.filter((file) => file.id !== payload.fileId);
        return { ...u, designFiles };
      });

      return {
        ...state,
        uploadSessions,
      };
    }
    return {
      ...state,
    };
  }),

  on(getProjectWorkOutlines, (state) => {
    return {
      ...state,
      workOutlines: [],
      workOutlinesLoading: true,
    };
  }),
  on(getProjectWorkOutlinesSuccess, (state, { payload }) => {
    return {
      ...state,
      workOutlines: [...payload.data],
      workOutlinesLoading: false,
    };
  }),
  on(getProjectWorkOutlinesFailure, (state) => {
    return {
      ...state,
      workOutlines: [],
      workOutlinesLoading: false,
    };
  }),
  // on(addProjectMember, (state, { worker }) => {
  //   const newMembers = [worker].concat(state.workers);
  //   return {
  //     ...state,
  //     workers: newMembers,
  //   };
  // }),
  on(getProjectSafetyCriterias, (state, { payload }) => {
    return {
      ...state,
      safetyCriteriasLoading: true,
      params: { ...payload },
    };
  }),
  on(getProjectSafetyCriteriasSuccess, (state, { payload }) => {
    return {
      ...state,
      safetyCriterias: [...payload.data],
      safetyCriteriasPagination: { ...payload.pagination },
      safetyCriteriasLoading: false,
    };
  }),
  on(getProjectSafetyCriteriasFailure, (state) => {
    return {
      ...state,
      safetyCriteriasLoading: false,
    };
  }),
  on(getProjectQualityCriterias, (state, { payload }) => {
    return {
      ...state,
      qualityCriteriasLoading: true,
      params: { ...payload },
    };
  }),
  on(getProjectQualityCriteriasSuccess, (state, { payload }) => {
    return {
      ...state,
      qualityCriterias: [...payload.data],
      qualityCriteriasPagination: { ...payload.pagination },
      qualityCriteriasLoading: false,
    };
  }),
  on(getProjectQualityCriteriasFailure, (state) => {
    return {
      ...state,
      qualityCriteriasLoading: false,
    };
  }),

  on(getProjectAttendances, (state) => {
    return {
      ...state,
      attendancesLoading: true,
    };
  }),
  on(getProjectAttendancesSuccess, (state, { payload }) => {
    return {
      ...state,
      attendances: [...payload.data],
      attendancesLoading: false,
    };
  }),
  on(getProjectAttendancesFailure, (state) => {
    return {
      ...state,
      attendancesLoading: false,
    };
  }),

  on(getProjectLogs, (state) => {
    return {
      ...state,
      logsLoading: true,
    };
  }),
  on(getProjectLogsSuccess, (state, { payload }) => {
    return {
      ...state,
      logs: [...payload.data],
      logsLoading: false,
    };
  }),
  on(getProjectLogsFailure, (state) => {
    return {
      ...state,
      logsLoading: false,
    };
  }),
  on(getProjectRegistrations, (state, { payload }) => {
    return {
      ...state,
      registrationsLoading: true,
      registrationsParams: { ...payload },
    };
  }),
  on(getProjectRegistrationsSuccess, (state, { payload }) => {
    return {
      ...state,
      registrations: [...payload.data],
      registrationsPagination: { ...payload.pagination },
      registrationsLoading: false,
    };
  }),
  on(getProjectRegistrationsFailure, (state) => {
    return {
      ...state,
      registrationsLoading: false,
    };
  }),

  on(getProjectPhotosCheckin, (state) => {
    return {
      ...state,
      photosCheckin: [],
    };
  }),
  on(getProjectPhotosCheckinSuccess, (state, { payload }) => {
    return {
      ...state,
      photosCheckin: [...payload.data],
    };
  }),
  on(getProjectPhotosCheckinFailure, (state) => {
    return {
      ...state,
    };
  }),
  on(getProjectPhotosComplete, (state) => {
    return {
      ...state,
      photosComplete: [],
    };
  }),
  on(getProjectPhotosCompleteSuccess, (state, { payload }) => {
    return {
      ...state,
      photosComplete: [...payload.data],
    };
  }),
  on(getProjectPhotosCompleteFailure, (state) => {
    return {
      ...state,
    };
  }),
  on(markCriteriaBundle, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: ProjectModalStatus.Init,
      type: ProjectModalType.Create,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(markCriteriaBundleSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProjectModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(markCriteriaBundleFailure, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProjectModalStatus.Failure,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(getMarkSessions, (state) => {
    return {
      ...state,
      markSessionsLoading: true,
    };
  }),
  on(getMarkSessionsSuccess, (state, { payload }) => {
    return {
      ...state,
      markSessions: [...payload.data],
      markSessionsLoading: false,
    };
  }),
  on(getMarkSessionsFailure, (state) => {
    return {
      ...state,
      markSessionsLoading: false,
    };
  }),
  on(resumeProject, (state, { payload }) => {
    const resumeModal = {
      ...state.resumeModal,
      isLoading: true,
      status: ProjectModalStatus.Init,
      type: ProjectModalType.Edit,
    };
    return {
      ...state,
      resumeModal,
    };
  }),
  on(resumeProjectSuccess, (state) => {
    const modal = {
      ...state.resumeModal,
      isLoading: false,
      status: ProjectModalStatus.Success,
    };
    return {
      ...state,
      resumeModal: { ...modal },
    };
  }),
  on(resumeProjectFailure, (state) => {
    const modal = {
      ...state.resumeModal,
      isLoading: false,
      status: ProjectModalStatus.Failure,
    };
    return {
      ...state,
      resumeModal: { ...modal },
    };
  }),
  on(openResumeProjectView, (state) => {
    const modal = {
      ...state.resumeModal,
      status: ProjectModalStatus.Init,
      errors: [],
    };
    return {
      ...state,
      resumeModal: { ...modal },
    };
  })
);
