import { GoodsIssueRequestsActions } from './actions';
import { GoodsIssueRequestModel, GoodsIssueRequestLineModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum GoodsIssueRequestModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum GoodsIssueRequestModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getGoodsIssueRequests,
  getGoodsIssueRequestsFailure,
  getGoodsIssueRequestsSuccess,
  getGoodsIssueRequest,
  getGoodsIssueRequestFailure,
  getGoodsIssueRequestSuccess,
  createGoodsIssueRequest,
  createGoodsIssueRequestFailure,
  createGoodsIssueRequestSuccess,
  updateGoodsIssueRequest,
  updateGoodsIssueRequestFailure,
  updateGoodsIssueRequestSuccess,
  deleteGoodsIssueRequest,
  deleteGoodsIssueRequestFailure,
  deleteGoodsIssueRequestSuccess,
  openGoodsIssueRequestView,
  cleanProductInGoodsIssueRequest,
  addProductInGoodsIssueRequest,
  editProductInGoodsIssueRequest,
  removeProductInGoodsIssueRequest,
} = GoodsIssueRequestsActions;
export interface GoodsIssueRequestState {
  list: Array<GoodsIssueRequestModel>;
  item: GoodsIssueRequestModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId?: number;
  };
  loading: boolean;
  modal: {
    type: GoodsIssueRequestModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: GoodsIssueRequestModalStatus;
  };
  inProducts: Array<GoodsIssueRequestLineModel>;
}

export const initialState: GoodsIssueRequestState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  loading: false,
  modal: {
    type: GoodsIssueRequestModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: GoodsIssueRequestModalStatus.Init,
  },
  inProducts: [],
};

export const reducer = createReducer(
  initialState,
  on(getGoodsIssueRequests, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getGoodsIssueRequestsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getGoodsIssueRequestsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getGoodsIssueRequest, (state, { payload }) => {
    return { ...state, loading: true };
  }),
  on(getGoodsIssueRequestSuccess, (state, { payload }) => {
    return {
      ...state,
      item: { ...payload.data },
      loading: false,
    };
  }),
  on(getGoodsIssueRequestFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updateGoodsIssueRequest, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: GoodsIssueRequestModalStatus.Called,
      type: GoodsIssueRequestModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateGoodsIssueRequestSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsIssueRequestModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateGoodsIssueRequestFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsIssueRequestModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsIssueRequest, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: GoodsIssueRequestModalStatus.Init,
      type: GoodsIssueRequestModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsIssueRequestSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsIssueRequestModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createGoodsIssueRequestFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: GoodsIssueRequestModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteGoodsIssueRequest, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteGoodsIssueRequestSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteGoodsIssueRequestFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openGoodsIssueRequestView, (state, { payload }) => {
    const modal = {
      ...state.modal,
      status: GoodsIssueRequestModalStatus.Init,
      errors: [],
    };

    if (payload && Array.isArray(payload.inProducts)) {
      const lines = payload.inProducts;
      const inProducts = lines.map((c) => {
        return {
          ...c,
          uid: `server-${c.id}`,
          name: c.productName,
          note: c.note,
          unit: c.productUnit,
        };
      });
      return {
        ...state,
        modal,
        inProducts,
      };
    }

    return {
      ...state,
      modal,
      inProducts: [],
    };
  }),
  on(addProductInGoodsIssueRequest, (state, { payload }) => {
    const { product, quantity } = payload;
    const length = state.inProducts.length + 1;
    const productExist = state.inProducts.find(
      (p) => p.productId === product.id
    );
    if (productExist) {
      const inProducts = state.inProducts.map((p) => {
        let qty = quantity;
        if (p.productId === product.id) {
          qty += p.quantity;
        }
        return { ...p, quantity: qty, isEdited: true };
      });

      return {
        ...state,
        inProducts,
      };
    }
    const inProduct = {
      uid: `local-${length}`,
      name: product.name,
      productId: product.id,
      quantity,
      isNew: true,
    } as GoodsIssueRequestLineModel;

    return {
      ...state,
      inProducts: [...state.inProducts, inProduct],
    };
  }),
  on(editProductInGoodsIssueRequest, (state, { payload }) => {
    const { product, quantity } = payload;
    const newProducts = state.inProducts.map((c) => {
      if (c.productId === product.productId && !c.isNew) {
        return {
          ...c,
          ...product,
          productId: product.productId,
          quantity,
          isEdited: true,
        };
      }
      if (c.productId === product.productId && c.isNew) {
        return { ...c, ...product, productId: product.productId };
      }
      return c;
    });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(removeProductInGoodsIssueRequest, (state, { payload }) => {
    if (!payload) {
      return {
        ...state,
      };
    }
    const { product } = payload;
    const newProducts = state.inProducts
      .map((c) => {
        if (c.productId === product.productId) {
          return { ...c, isRemoved: true };
        }
        return c;
      })
      .filter((c) => {
        if (c.productId === product.productId && c.isNew) {
          return false;
        }
        return true;
      });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(cleanProductInGoodsIssueRequest, (state) => {
    return {
      ...state,
      inProducts: [],
    };
  })
);
