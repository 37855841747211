import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppUIModule } from '../app-ui.module';
import { RouterModule } from '@angular/router';
import { LayoutProWidgetUserComponent } from './default/@components/user/user.component';
import { LayoutProWidgetSwitchGroupComponent } from './default/@components/switch-group/switch-group.component';
import { LayoutProLogoComponent } from './default/@components/logo/logo.component';
import { LayoutProFooterComponent } from './default/@components/footer/footer.component';
import { LayoutProHeaderComponent } from './default/@components/header/header.component';
import { LayoutProMenuComponent } from './default/@components/menu/menu.component';
import { ProPageHeaderWrapperComponent } from './default/@components/page-header-wrapper/page-header-wrapper.component';
import { ProPageGridComponent } from './default/@components/page-grid/page-grid.component';
import { LayoutProHeaderWidgetComponent } from './default/@components/widget/widget.component';
import { LayoutProWidgetNotifyComponent } from './default/@components/notify/notify.component';

import { SharedModule } from '../@shared/shared.module';
import { LayoutDefaultComponent } from './default/default.component';
import { LayoutAuthComponent } from './auth/auth.component';

@NgModule({
  declarations: [
    LayoutProWidgetNotifyComponent,
    LayoutAuthComponent,
    LayoutDefaultComponent,
    LayoutProWidgetSwitchGroupComponent,
    LayoutProHeaderWidgetComponent,
    LayoutProLogoComponent,
    LayoutProFooterComponent,
    LayoutProHeaderComponent,
    LayoutProMenuComponent,
    ProPageHeaderWrapperComponent,
    ProPageGridComponent,
    LayoutProWidgetUserComponent,
  ],
  exports: [ProPageHeaderWrapperComponent, ProPageGridComponent],
  imports: [AppUIModule, RouterModule, CommonModule, SharedModule],
})
export class LayoutModule {}
