import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const error = err.error || err.name || err.statusText;
        console.log(error);
        if ([401].indexOf(err.status) !== -1) {
          this.authenticationService.logout();
          // tslint:disable-next-line: deprecation
          this.router.navigate(['/auth/login']);
        }
        return throwError(error);
      })
    );
  }
}
