import { createAction, props } from '@ngrx/store';

import { GoodsIssueRequestModel, GoodsIssueRequestLineModel } from './model';
import {
  GoodsIssueRequestResultsModel,
  GoodsIssueRequestResultModel,
} from './results.model';
import { ProductModel } from '../product/model';
import { TransportRequestResultModel } from '../transport-request';
import { GoodsIssueDocumentResultModel } from '../goods-issue-document';

const getGoodsIssueRequests = createAction(
  '[GOODISSUEREQUEST] Get GoodsIssueRequests',
  props<{ payload: any }>()
);

const getGoodsIssueRequestsSuccess = createAction(
  '[GOODISSUEREQUEST] Get GoodsIssueRequests Success',
  props<{ payload: GoodsIssueRequestResultsModel }>()
);

export const getGoodsIssueRequestsFailure = createAction(
  '[GOODISSUEREQUEST] Get GoodsIssueRequests Faild'
);

const getGoodsIssueRequest = createAction(
  '[GOODISSUEREQUEST] Get GoodsIssueRequest',
  props<{ payload: { goodsIssueRequestId: number } }>()
);

const getGoodsIssueRequestSuccess = createAction(
  '[GOODISSUEREQUEST] Get GoodsIssueRequest Success',
  props<{ payload: GoodsIssueRequestResultModel }>()
);

export const getGoodsIssueRequestFailure = createAction(
  '[GOODISSUEREQUEST] Get GoodsIssueRequest Faild'
);

const createGoodsIssueRequest = createAction(
  '[GOODISSUEREQUEST] Create GoodsIssueRequest',
  props<{
    payload: { projectId: number; goodsIssueRequest: GoodsIssueRequestModel };
  }>()
);

export const createGoodsIssueRequestSuccess = createAction(
  '[GOODISSUEREQUEST] Create GoodsIssueRequest Success',
  props<{ payload: GoodsIssueRequestResultModel }>()
);

export const createGoodsIssueRequestFailure = createAction(
  '[GOODISSUEREQUEST] Create GoodsIssueRequest Faild',
  props<{ payload: GoodsIssueRequestResultModel }>()
);

const updateGoodsIssueRequest = createAction(
  '[GOODISSUEREQUEST] Update GoodsIssueRequest',
  props<{
    payload: {
      goodsIssueRequestId: number;
      goodsIssueRequest: GoodsIssueRequestModel;
    };
  }>()
);

const updateGoodsIssueRequestSuccess = createAction(
  '[GOODISSUEREQUEST] Update GoodsIssueRequest Success',
  props<{ payload: GoodsIssueRequestResultModel }>()
);

export const updateGoodsIssueRequestFailure = createAction(
  '[GOODISSUEREQUEST] Update GoodsIssueRequest Faild',
  props<{ payload: GoodsIssueRequestResultModel }>()
);

const deleteGoodsIssueRequest = createAction(
  '[GOODISSUEREQUEST] Delete GoodsIssueRequest',
  props<{ payload: number }>()
);

const deleteGoodsIssueRequestSuccess = createAction(
  '[GOODISSUEREQUEST] Delete GoodsIssueRequest Success',
  props<{ payload: GoodsIssueRequestResultModel }>()
);

export const deleteGoodsIssueRequestFailure = createAction(
  '[GOODISSUEREQUEST] Delete GoodsIssueRequest Faild',
  props<{ payload: GoodsIssueRequestResultModel }>()
);

export const openGoodsIssueRequestView = createAction(
  '[GOODISSUEREQUEST] Open GoodsIssueRequest View',
  props<{
    payload: {
      inProducts: Array<GoodsIssueRequestLineModel>;
    };
  }>()
);

export const cleanProductInGoodsIssueRequest = createAction(
  '[GOODISSUEREQUEST] Clean Products in  GoodsIssueRequest'
);

export const addProductInGoodsIssueRequest = createAction(
  '[GOODISSUEREQUEST] Add Product in  GoodsIssueRequest',
  props<{ payload: { product: ProductModel; quantity: number } }>()
);

export const editProductInGoodsIssueRequest = createAction(
  '[GOODISSUEREQUEST] Edit Product in  GoodsIssueRequest',
  props<{
    payload: { product: GoodsIssueRequestLineModel; quantity: number };
  }>()
);

export const removeProductInGoodsIssueRequest = createAction(
  '[GOODISSUEREQUEST] Remove Product in  GoodsIssueRequest',
  props<{ payload: { product: GoodsIssueRequestLineModel } }>()
);

const createGoodsIssueRequestsTransportRequest = createAction(
  '[GOODISSUEREQUEST] Create TransportRequest',
  props<{
    payload: { goodsIssueRequestId: number };
  }>()
);

export const createGoodsIssueRequestsTransportRequestSuccess = createAction(
  '[GOODISSUEREQUEST] Create TransportRequest Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const createGoodsIssueRequestsTransportRequestFailure = createAction(
  '[GOODISSUEREQUEST] Create TransportRequest Faild',
  props<{ payload: TransportRequestResultModel }>()
);

export const createGoodsIssueRequestGoodsIssueDocument = createAction(
  '[GOODISSUEREQUEST] Create GoodsIssue',
  props<{
    payload: { goodsIssueRequestId: number };
  }>()
);

export const createGoodsIssueRequestGoodsIssueDocumentSuccess = createAction(
  '[GOODISSUEREQUEST] Create GoodsIssue Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const createGoodsIssueRequestGoodsIssueDocumentFailure = createAction(
  '[GOODISSUEREQUEST] Create GoodsIssue Faild',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const GoodsIssueRequestsActions = {
  createGoodsIssueRequestGoodsIssueDocument,
  createGoodsIssueRequestGoodsIssueDocumentSuccess,
  createGoodsIssueRequestGoodsIssueDocumentFailure,
  createGoodsIssueRequestsTransportRequest,
  createGoodsIssueRequestsTransportRequestSuccess,
  createGoodsIssueRequestsTransportRequestFailure,
  cleanProductInGoodsIssueRequest,
  addProductInGoodsIssueRequest,
  editProductInGoodsIssueRequest,
  removeProductInGoodsIssueRequest,
  openGoodsIssueRequestView,
  getGoodsIssueRequests,
  getGoodsIssueRequestsSuccess,
  getGoodsIssueRequestsFailure,
  getGoodsIssueRequest,
  getGoodsIssueRequestSuccess,
  getGoodsIssueRequestFailure,
  createGoodsIssueRequest,
  createGoodsIssueRequestSuccess,
  createGoodsIssueRequestFailure,
  updateGoodsIssueRequest,
  updateGoodsIssueRequestSuccess,
  updateGoodsIssueRequestFailure,
  deleteGoodsIssueRequest,
  deleteGoodsIssueRequestSuccess,
  deleteGoodsIssueRequestFailure,
};
