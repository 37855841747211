<nz-card>
  <div class="round-trip">
    <div class="departure point">
      <div class="icon">
        <img [src]="iconDepartureUrl" />
      </div>
      <div class="infor">
        <div class="name">{{ departure ? departure.name : '-' }}</div>
        <div class="address">{{ departure ? departure.address : '-' }}</div>
      </div>
    </div>
    <div class="running">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <div class="driver point">
        <div class="icon">
          <img src="/assets/icons/icon-lorry.svg" alt="lorry" />
        </div>
        <div class="info">
          <div>{{ driver ? driver.name : '-' }}</div>
          <div>{{ driver ? driver.phone : '-' }}</div>
          <div>{{ driver ? driver.plateNumber : '-' }}</div>
        </div>
      </div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="arrival point">
      <div class="icon">
        <img [src]="iconArrivalUrl" />
      </div>
      <div class="infor">
        <div class="name">{{ arrival ? arrival.name : '-' }}</div>
        <div class="address">{{ arrival ? arrival.address : '-' }}</div>
      </div>
    </div>
  </div>
</nz-card>
