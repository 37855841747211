import { WarehousesActions } from './actions';
import { WarehouseModel, WarehouseProductLineModel } from './model';
import { createReducer, on } from '@ngrx/store';
import {
  GoodsIssueDocumentModel,
  GoodsIssueDocumentLineModel,
} from '../goods-issue-document';
import {
  GoodsReceivedNoteModel,
  GoodsReceivedNoteLineModel,
} from '../goods-received-note';
import { GoodsIssueRequestModel } from '../goods-issue-request';
import { ProductModel } from '../product';

export enum WarehouseModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum WarehouseModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getWarehouses,
  getWarehousesFailure,
  getWarehousesSuccess,
  getWarehouse,
  getWarehouseFailure,
  getWarehouseSuccess,
  createWarehouse,
  createWarehouseFailure,
  createWarehouseSuccess,
  updateWarehouse,
  updateWarehouseFailure,
  updateWarehouseSuccess,
  deleteWarehouse,
  deleteWarehouseFailure,
  deleteWarehouseSuccess,
  openWarehouseView,
  getStocks,
  getStocksSuccess,
  getStocksFailure,
  getFactories,
  getFactoriesSuccess,
  getFactoriesFailure,
  getFactory,
  getFactorySuccess,
  getFactoryFailure,
  getStock,
  getStockSuccess,
  getStockFailure,
  getStockGoodsIssueDocuments,
  getStockGoodsIssueDocumentsSuccess,
  getStockGoodsIssueDocumentsFailure,
  getStockGoodsReceivedNotes,
  getStockGoodsReceivedNotesSuccess,
  getStockGoodsReceivedNotesFailure,
  getFactoryGoodsIssueDocument,
  getFactoryGoodsIssueDocumentSuccess,
  getFactoryGoodsIssueDocumentFailure,
  getFactoryGoodsReceivedNote,
  getFactoryGoodsReceivedNoteSuccess,
  getFactoryGoodsReceivedNoteFailure,
  getFactoryManufactureRequests,
  getFactoryManufactureRequestsSuccess,
  getFactoryManufactureRequestsFailure,
  getStockGoodsReceivedNote,
  getStockGoodsReceivedNoteSuccess,
  getStockGoodsReceivedNoteFailure,
  getStockGoodsIssueRequests,
  getStockGoodsIssueRequestsSuccess,
  getStockGoodsIssueRequestsFailure,
  getStockGoodsIssueDocument,
  getStockGoodsIssueDocumentSuccess,
  getStockGoodsIssueDocumentFailure,
  openStockGoodsIssueDocumentView,
  openStockGoodsReceivedNoteView,
  updateStockGoodsIssueDocument,
  updateStockGoodsIssueDocumentSuccess,
  updateStockGoodsIssueDocumentFailure,
  createStockGoodsIssueDocument,
  createStockGoodsIssueDocumentSuccess,
  createStockGoodsIssueDocumentFailure,
  updateStockGoodsReceivedNote,
  updateStockGoodsReceivedNoteSuccess,
  updateStockGoodsReceivedNoteFailure,
  createStockGoodsReceivedNote,
  createStockGoodsReceivedNoteSuccess,
  createStockGoodsReceivedNoteFailure,
  cleanWarehouseProductsInDocument,
  addProductInWarehouseProductsInDocument,
  editProductInWarehouseProductsInDocument,
  removeProductInWarehouseProductsInDocument,
  createWarehouseStock,
  createWarehouseStockSuccess,
  createWarehouseStockFailure,
  updateWarehouseStock,
  updateWarehouseStockSuccess,
  updateWarehouseStockFailure,
  createWarehouseFactory,
  createWarehouseFactorySuccess,
  createWarehouseFactoryFailure,
  updateWarehouseFactory,
  updateWarehouseFactorySuccess,
  updateWarehouseFactoryFailure,
  getFactoryGoodsIssueDocuments,
  getFactoryGoodsIssueDocumentsSuccess,
  getFactoryGoodsIssueDocumentsFailure,
  updateFactoryGoodsIssueDocument,
  updateFactoryGoodsIssueDocumentSuccess,
  updateFactoryGoodsIssueDocumentFailure,
  createFactoryGoodsIssueDocument,
  createFactoryGoodsIssueDocumentSuccess,
  createFactoryGoodsIssueDocumentFailure,

  printStockGoodsIssueDocument,
  printStockGoodsIssueDocumentSuccess,
  printStockGoodsIssueDocumentFailure,
  printStockGoodsReceivedNote,
  printStockGoodsReceivedNoteSuccess,
  printStockGoodsReceivedNoteFailure,
  printFactoryGoodsIssueDocument,
  printFactoryGoodsIssueDocumentSuccess,
  printFactoryGoodsIssueDocumentFailure,

  getWarehouseProducts,
  getWarehouseProductsSuccess,
  getWarehouseProductsFailure,
} = WarehousesActions;
export interface WarehouseState {
  list: Array<WarehouseModel>;
  item: WarehouseModel;
  itemLoading: boolean;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
  };
  loading: boolean;
  modal: {
    id: number;
    type: WarehouseModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: WarehouseModalStatus;
  };

  stocks: Array<WarehouseModel>;
  stockItem: WarehouseModel;
  stockItemLoading: boolean;
  stocksLoading: boolean;
  stocksPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  stocksParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
  };

  stockGoodsIssueRequests: Array<GoodsIssueRequestModel>;
  stockGoodsIssueRequestsLoading: boolean;
  stockGoodsIssueRequestsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  stockGoodsIssueRequestsParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };
  stockGoodsIssueRequestModal: {
    type: WarehouseModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: WarehouseModalStatus;
  };

  stockGoodsIssueDocuments: Array<GoodsIssueDocumentModel>;
  stockGoodsIssueDocumentsLoading: boolean;
  stockGoodsIssueDocumentsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  stockGoodsIssueDocumentsParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };
  stockGoodsIssueDocumentModal: {
    type: WarehouseModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: WarehouseModalStatus;
  };

  stockGoodsIssueDocument: GoodsIssueDocumentModel;
  stockGoodsIssueDocumentLoading: boolean;
  stockGoodsIssueDocumentProducts: Array<GoodsIssueDocumentLineModel>;

  stockGoodsReceivedNotes: Array<GoodsReceivedNoteModel>;
  stockGoodsReceivedNotesLoading: boolean;
  stockGoodsReceivedNotesPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  stockGoodsReceivedNotesParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };
  stockGoodsReceivedNoteModal: {
    type: WarehouseModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: WarehouseModalStatus;
  };

  stockGoodsReceivedNote: GoodsReceivedNoteModel;
  stockGoodsReceivedNoteLoading: boolean;
  stockGoodsReceivedNoteProducts: Array<GoodsReceivedNoteLineModel>;

  factories: Array<WarehouseModel>;
  factoryItem: WarehouseModel;
  factoryItemLoading: boolean;
  factoriesLoading: boolean;
  factoriesPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  factoriesParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
  };

  factoryManufactureRequests: Array<GoodsIssueRequestModel>;
  factoryManufactureRequestsLoading: boolean;
  factoryManufactureRequestsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  factoryManufactureRequestsParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };
  factoryGoodsIssueRequestModal: {
    type: WarehouseModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: WarehouseModalStatus;
  };

  factoryGoodsIssueDocuments: Array<GoodsIssueDocumentModel>;
  factoryGoodsIssueDocumentsLoading: boolean;
  factoryGoodsIssueDocumentsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  factoryGoodsIssueDocumentsParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };
  factoryGoodsIssueDocumentModal: {
    type: WarehouseModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: WarehouseModalStatus;
  };

  factoryGoodsIssueDocument: GoodsIssueDocumentModel;
  factoryGoodsIssueDocumentLoading: boolean;
  factoryGoodsIssueDocumentProducts: Array<GoodsIssueDocumentLineModel>;

  factoryGoodsReceivedNotes: Array<GoodsReceivedNoteModel>;
  factoryGoodsReceivedNotesLoading: boolean;
  factoryGoodsReceivedNotesPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  factoryGoodsReceivedNotesParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };
  factoryGoodsReceivedNoteModal: {
    type: WarehouseModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: WarehouseModalStatus;
  };

  factoryGoodsReceivedNote: GoodsReceivedNoteModel;
  factoryGoodsReceivedNoteLoading: boolean;
  factoryGoodsReceivedNoteProducts: Array<GoodsReceivedNoteLineModel>;
  printLoading: boolean;
  printData: Blob;
  productsInDocument: Array<WarehouseProductLineModel>;

  products: Array<ProductModel>;
  productsLoading: boolean;
  productsParams: {
    page?: number;
    size?: number;
    sort?: string;
    productName?: string;
    warehouseId: number;
  };
  productsPagination: {
    page?: number;
    size?: number;
    totalRecords?: number;
  };
}

export const initialState: WarehouseState = {
  list: [],
  item: null,
  itemLoading: false,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
  },
  loading: false,
  modal: {
    id: null,
    type: WarehouseModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: WarehouseModalStatus.Init,
  },
  stocks: [],
  stockItem: null,
  stockItemLoading: false,
  stocksLoading: false,
  stocksPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  stocksParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
  },

  factories: [],
  factoryItem: null,
  factoryItemLoading: false,
  factoriesLoading: false,
  factoriesPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  factoriesParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
  },
  stockGoodsIssueRequests: [],
  stockGoodsIssueRequestsLoading: false,
  stockGoodsIssueRequestsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  stockGoodsIssueRequestModal: {
    type: WarehouseModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: WarehouseModalStatus.Init,
  },
  stockGoodsIssueRequestsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  stockGoodsIssueDocuments: [],
  stockGoodsIssueDocumentsLoading: false,
  stockGoodsIssueDocumentsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  stockGoodsIssueDocumentModal: {
    type: WarehouseModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: WarehouseModalStatus.Init,
  },
  stockGoodsIssueDocumentsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  stockGoodsIssueDocument: null,
  stockGoodsIssueDocumentLoading: false,
  stockGoodsIssueDocumentProducts: [],

  stockGoodsReceivedNotes: [],
  stockGoodsReceivedNotesLoading: false,
  stockGoodsReceivedNotesPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  stockGoodsReceivedNoteModal: {
    type: WarehouseModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: WarehouseModalStatus.Init,
  },
  stockGoodsReceivedNotesParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  stockGoodsReceivedNote: null,
  stockGoodsReceivedNoteLoading: false,
  stockGoodsReceivedNoteProducts: [],

  factoryManufactureRequests: [],
  factoryManufactureRequestsLoading: false,
  factoryManufactureRequestsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  factoryGoodsIssueRequestModal: {
    type: WarehouseModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: WarehouseModalStatus.Init,
  },
  factoryManufactureRequestsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  factoryGoodsIssueDocuments: [],
  factoryGoodsIssueDocumentsLoading: false,
  factoryGoodsIssueDocumentsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  factoryGoodsIssueDocumentModal: {
    type: WarehouseModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: WarehouseModalStatus.Init,
  },
  factoryGoodsIssueDocumentsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  factoryGoodsIssueDocument: null,
  factoryGoodsIssueDocumentLoading: false,
  factoryGoodsIssueDocumentProducts: [],

  factoryGoodsReceivedNotes: [],
  factoryGoodsReceivedNotesLoading: false,
  factoryGoodsReceivedNotesPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  factoryGoodsReceivedNoteModal: {
    type: WarehouseModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: WarehouseModalStatus.Init,
  },
  factoryGoodsReceivedNotesParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  factoryGoodsReceivedNote: null,
  factoryGoodsReceivedNoteLoading: false,
  factoryGoodsReceivedNoteProducts: [],
  printLoading: false,
  printData: null,
  productsInDocument: [],

  products: [],
  productsLoading: false,
  productsParams: {
    page: 0,
    size: 0,
    sort: null,
    productName: null,
    warehouseId: null,
  },
  productsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
};

export const reducer = createReducer(
  initialState,
  on(getWarehouses, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getWarehousesSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getWarehousesFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getWarehouse, (state) => {
    return { ...state, itemLoading: true };
  }),
  on(getWarehouseSuccess, (state, { payload }) => {
    return {
      ...state,
      item: { ...payload.data },
      itemLoading: false,
    };
  }),
  on(getWarehouseFailure, (state) => {
    return {
      ...state,
      itemLoading: false,
    };
  }),
  on(getWarehouseProducts, (state, { payload }) => {
    return { ...state, productsLoading: true, productsParams: { ...payload } };
  }),
  on(getWarehouseProductsSuccess, (state, { payload }) => {
    return {
      ...state,
      products: [...payload.data],
      productsPagination: { ...payload.pagination },
      productsLoading: false,
    };
  }),
  on(getWarehouseProductsFailure, (state) => {
    return {
      ...state,
      productsLoading: false,
    };
  }),
  on(updateWarehouse, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: WarehouseModalStatus.Called,
      type: WarehouseModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouse, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: WarehouseModalStatus.Init,
      type: WarehouseModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouseSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouseFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouseStock, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: WarehouseModalStatus.Init,
      type: WarehouseModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouseStockSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouseStockFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseStock, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: WarehouseModalStatus.Init,
      type: WarehouseModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseStockSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseStockFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouseFactory, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: WarehouseModalStatus.Init,
      type: WarehouseModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouseFactorySuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createWarehouseFactoryFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseFactory, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: WarehouseModalStatus.Init,
      type: WarehouseModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseFactorySuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseFactoryFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),

  on(updateWarehouse, (state, { payload }) => {
    const modal = {
      ...state.modal,
      id: payload.id,
      isLoading: true,
      status: WarehouseModalStatus.Init,
      type: WarehouseModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateWarehouseFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteWarehouse, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteWarehouseSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteWarehouseFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openWarehouseView, (state) => {
    const modal = {
      ...state.modal,
      status: WarehouseModalStatus.Init,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(getFactories, (state, { payload }) => {
    return {
      ...state,
      factoriesLoading: true,
      factoriesParams: { ...payload },
    };
  }),
  on(getFactoriesSuccess, (state, { payload }) => {
    return {
      ...state,
      factories: [...payload.data],
      factoriesPagination: { ...payload.pagination },
      factoriesLoading: false,
    };
  }),
  on(getFactoriesFailure, (state) => {
    return {
      ...state,
      factoriesLoading: false,
    };
  }),
  on(getFactory, (state, { payload }) => {
    return {
      ...state,
      factoryItemLoading: true,
    };
  }),
  on(getFactorySuccess, (state, { payload }) => {
    return {
      ...state,
      factoryItem: { ...payload.data },
      factoryItemLoading: false,
    };
  }),
  on(getFactoryFailure, (state) => {
    return {
      ...state,
      factoryItemLoading: false,
    };
  }),
  on(getStocks, (state, { payload }) => {
    return {
      ...state,
      stocksLoading: true,
      stocksParams: { ...payload },
    };
  }),
  on(getStocksSuccess, (state, { payload }) => {
    return {
      ...state,
      stocks: [...payload.data],
      stocksPagination: { ...payload.pagination },
      stocksLoading: false,
    };
  }),
  on(getStocksFailure, (state) => {
    return {
      ...state,
      stocksLoading: false,
    };
  }),
  on(getStock, (state, { payload }) => {
    return {
      ...state,
      stockItemLoading: true,
    };
  }),
  on(getStockSuccess, (state, { payload }) => {
    return {
      ...state,
      stockItem: { ...payload.data },
      stockItemLoading: false,
    };
  }),
  on(getStockFailure, (state) => {
    return {
      ...state,
      stockItemLoading: false,
    };
  }),
  on(getStockGoodsIssueRequests, (state, { payload }) => {
    return {
      ...state,
      stockGoodsIssueRequestsLoading: true,
      stockGoodsIssueRequestsParams: { ...payload },
    };
  }),
  on(getStockGoodsIssueRequestsSuccess, (state, { payload }) => {
    return {
      ...state,
      stockGoodsIssueRequests: [...payload.data],
      stockGoodsIssueRequestsPagination: { ...payload.pagination },
      stockGoodsIssueRequestsLoading: false,
    };
  }),
  on(getStockGoodsIssueRequestsFailure, (state) => {
    return {
      ...state,
      stockGoodsIssueRequestsLoading: false,
    };
  }),

  on(getFactoryManufactureRequests, (state, { payload }) => {
    return {
      ...state,
      factoryManufactureRequestsLoading: true,
      factoryManufactureRequestsParams: { ...payload },
    };
  }),
  on(getFactoryManufactureRequestsSuccess, (state, { payload }) => {
    return {
      ...state,
      factoryManufactureRequests: [...payload.data],
      factoryManufactureRequestsPagination: { ...payload.pagination },
      factoryManufactureRequestsLoading: false,
    };
  }),
  on(getFactoryManufactureRequestsFailure, (state) => {
    return {
      ...state,
      factoryManufactureRequestsLoading: false,
    };
  }),

  on(getStockGoodsIssueDocuments, (state, { payload }) => {
    return {
      ...state,
      stockGoodsIssueDocumentsLoading: true,
      stockGoodsIssueDocumentsParams: { ...payload },
    };
  }),
  on(getStockGoodsIssueDocumentsSuccess, (state, { payload }) => {
    return {
      ...state,
      stockGoodsIssueDocuments: [...payload.data],
      stockGoodsIssueDocumentsPagination: { ...payload.pagination },
      stockGoodsIssueDocumentsLoading: false,
    };
  }),
  on(getStockGoodsIssueDocumentsFailure, (state) => {
    return {
      ...state,
      stockGoodsIssueDocumentsLoading: false,
    };
  }),
  on(getStockGoodsReceivedNotes, (state, { payload }) => {
    return {
      ...state,
      stockGoodsReceivedNotesLoading: true,
      stockGoodsReceivedNotesParams: { ...payload },
    };
  }),
  on(getStockGoodsReceivedNotesSuccess, (state, { payload }) => {
    return {
      ...state,
      stockGoodsReceivedNotes: [...payload.data],
      stockGoodsReceivedNotesPagination: { ...payload.pagination },
      stockGoodsReceivedNotesLoading: false,
    };
  }),
  on(getStockGoodsReceivedNotesFailure, (state) => {
    return {
      ...state,
      stockGoodsReceivedNotesLoading: false,
    };
  }),
  on(openStockGoodsIssueDocumentView, (state) => {
    const modal = {
      ...state.stockGoodsIssueDocumentModal,
      status: WarehouseModalStatus.Init,
      errors: [],
    };
    return {
      ...state,
      stockGoodsIssueDocumentModal: modal,
      stockGoodsIssueDocumentProducts: [],
    };
  }),
  on(getStockGoodsIssueDocument, (state, { payload }) => {
    return {
      ...state,
      stockGoodsIssueDocumentLoading: true,
      stockGoodsIssueDocumentProducts: [],
    };
  }),
  on(getStockGoodsIssueDocumentSuccess, (state, { payload }) => {
    const productsIn = { ...payload.data }.lines;
    return {
      ...state,
      stockGoodsIssueDocument: { ...payload.data },
      stockGoodsIssueDocumentLoading: false,
      stockGoodsIssueDocumentProducts: [...productsIn],
      productsInDocument: [...productsIn],
    };
  }),
  on(getStockGoodsIssueDocumentFailure, (state) => {
    return {
      ...state,
      stockGoodsIssueDocumentLoading: false,
    };
  }),
  on(openStockGoodsReceivedNoteView, (state) => {
    const modal = {
      ...state.stockGoodsReceivedNoteModal,
      status: WarehouseModalStatus.Init,
      errors: [],
    };
    return {
      ...state,
      stockGoodsReceivedNoteModal: modal,
      productsInDocument: [],
    };
  }),
  on(getStockGoodsReceivedNote, (state) => {
    return {
      ...state,
      stockGoodsReceivedNoteLoading: true,
      productsInDocument: [],
    };
  }),
  on(getStockGoodsReceivedNoteSuccess, (state, { payload }) => {
    const productsIn = { ...payload.data }.lines;
    return {
      ...state,
      stockGoodsReceivedNote: { ...payload.data },
      stockGoodsReceivedNoteLoading: false,
      stockGoodsReceivedNoteProducts: [...productsIn],
      productsInDocument: [...productsIn],
    };
  }),
  on(getStockGoodsReceivedNoteFailure, (state) => {
    return {
      ...state,
      stockGoodsReceivedNoteLoading: false,
    };
  }),
  on(getFactoryGoodsIssueDocument, (state, { payload }) => {
    return {
      ...state,
      stockGoodsIssueDocumentLoading: true,
    };
  }),
  on(getFactoryGoodsIssueDocumentSuccess, (state, { payload }) => {
    const productsIn = { ...payload.data }.lines;
    return {
      ...state,
      factoryGoodsIssueDocument: { ...payload.data },
      factoryGoodsIssueDocumentLoading: false,
      productsInDocument: productsIn,
    };
  }),
  on(getFactoryGoodsIssueDocumentFailure, (state) => {
    return {
      ...state,
      factoryGoodsIssueDocumentLoading: false,
    };
  }),
  on(getFactoryGoodsReceivedNote, (state) => {
    return {
      ...state,
      stockGoodsReceivedNoteLoading: true,
    };
  }),
  on(getFactoryGoodsReceivedNoteSuccess, (state, { payload }) => {
    return {
      ...state,
      stockGoodsReceivedNote: { ...payload.data },
      stockGoodsReceivedNoteLoading: false,
    };
  }),
  on(getFactoryGoodsReceivedNoteFailure, (state) => {
    return {
      ...state,
      stockGoodsReceivedNoteLoading: false,
    };
  }),

  on(createStockGoodsReceivedNote, (state) => {
    const modal = {
      ...state.stockGoodsReceivedNoteModal,
      isLoading: true,
      status: WarehouseModalStatus.Called,
      type: WarehouseModalType.Create,
      errors: [],
    };
    return {
      ...state,
      stockGoodsReceivedNoteModal: modal,
    };
  }),
  on(createStockGoodsReceivedNoteSuccess, (state) => {
    const modal = {
      ...state.stockGoodsReceivedNoteModal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      stockGoodsReceivedNoteModal: modal,
    };
  }),
  on(createStockGoodsReceivedNoteFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.stockGoodsReceivedNoteModal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      stockGoodsReceivedNoteModal: modal,
    };
  }),

  on(updateStockGoodsReceivedNote, (state) => {
    const modal = {
      ...state.stockGoodsReceivedNoteModal,
      isLoading: true,
      status: WarehouseModalStatus.Called,
      type: WarehouseModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      stockGoodsReceivedNoteModal: modal,
    };
  }),
  on(updateStockGoodsReceivedNoteSuccess, (state) => {
    const modal = {
      ...state.stockGoodsReceivedNoteModal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      stockGoodsReceivedNoteModal: modal,
    };
  }),
  on(updateStockGoodsReceivedNoteFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.stockGoodsReceivedNoteModal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      stockGoodsReceivedNoteModal: modal,
    };
  }),

  on(createStockGoodsIssueDocument, (state) => {
    const modal = {
      ...state.stockGoodsIssueDocumentModal,
      isLoading: true,
      status: WarehouseModalStatus.Called,
      type: WarehouseModalType.Create,
      errors: [],
    };
    return {
      ...state,
      stockGoodsIssueDocumentModal: modal,
    };
  }),
  on(createStockGoodsIssueDocumentSuccess, (state) => {
    const modal = {
      ...state.stockGoodsIssueDocumentModal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      stockGoodsIssueDocumentModal: modal,
    };
  }),
  on(createStockGoodsIssueDocumentFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.stockGoodsIssueDocumentModal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      stockGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateStockGoodsIssueDocument, (state) => {
    const modal = {
      ...state.stockGoodsIssueDocumentModal,
      isLoading: true,
      status: WarehouseModalStatus.Called,
      type: WarehouseModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      stockGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateStockGoodsIssueDocumentSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      stockGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateStockGoodsIssueDocumentFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.stockGoodsIssueDocumentModal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      stockGoodsIssueDocumentModal: modal,
    };
  }),

  on(addProductInWarehouseProductsInDocument, (state, { payload }) => {
    const { product, quantity } = payload;
    const length = state.productsInDocument.length + 1;

    const productExist = state.productsInDocument.find(
      (p) => p.productId === product.id
    );
    if (productExist) {
      const inProducts = state.productsInDocument.map((p) => {
        let qty = p.quantity;
        if (p.productId === product.id) {
          qty = p.quantity + quantity;
        }
        return { ...p, quantity: qty, isEdited: true };
      });

      return {
        ...state,
        productsInDocument: inProducts,
      };
    }
    const productLine = {
      uid: `local-${length}`,
      productName: product.name,
      productId: product.id,
      productUnit: product.unit,
      quantity,
      isNew: true,
    } as WarehouseProductLineModel;
    return {
      ...state,
      productsInDocument: [...state.productsInDocument, productLine],
    };
  }),
  on(editProductInWarehouseProductsInDocument, (state, { payload }) => {
    const { product, quantity } = payload;
    const productsInDocument = state.productsInDocument.map((c) => {
      if (c.productId === product.productId && !c.isNew) {
        return {
          ...c,
          ...product,
          productId: product.productId,
          quantity,
          isEdited: true,
        };
      }
      if (c.productId === product.productId && c.isNew) {
        return { ...c, ...product, productId: product.productId };
      }
      return c;
    });
    return {
      ...state,
      productsInDocument,
    };
  }),
  on(removeProductInWarehouseProductsInDocument, (state, { payload }) => {
    if (!payload) {
      return {
        ...state,
      };
    }
    const { product } = payload;
    const productsInDocument = state.productsInDocument
      .map((c) => {
        if (c.productId === product.productId) {
          return { ...c, isRemoved: true };
        }
        return c;
      })
      .filter((c) => {
        if (c.productId === product.productId && c.isNew) {
          return false;
        }
        return true;
      });
    return {
      ...state,
      productsInDocument,
    };
  }),
  on(cleanWarehouseProductsInDocument, (state) => {
    return {
      ...state,
      productsInDocument: [],
    };
  }),

  on(getFactoryGoodsIssueDocuments, (state, { payload }) => {
    return {
      ...state,
      factoryGoodsIssueDocumentsLoading: true,
      factoryGoodsIssueDocumentsParams: { ...payload },
    };
  }),
  on(getFactoryGoodsIssueDocumentsSuccess, (state, { payload }) => {
    return {
      ...state,
      factoryGoodsIssueDocuments: [...payload.data],
      factoryGoodsIssueDocumentsPagination: { ...payload.pagination },
      factoryGoodsIssueDocumentsLoading: false,
    };
  }),
  on(getFactoryGoodsIssueDocumentsFailure, (state) => {
    return {
      ...state,
      factoryGoodsIssueDocumentsLoading: false,
    };
  }),
  on(createFactoryGoodsIssueDocument, (state) => {
    const modal = {
      ...state.factoryGoodsIssueDocumentModal,
      isLoading: true,
      status: WarehouseModalStatus.Called,
      type: WarehouseModalType.Create,
      errors: [],
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(createFactoryGoodsIssueDocumentSuccess, (state) => {
    const modal = {
      ...state.factoryGoodsIssueDocumentModal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(createFactoryGoodsIssueDocumentFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.factoryGoodsIssueDocumentModal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateFactoryGoodsIssueDocument, (state) => {
    const modal = {
      ...state.factoryGoodsIssueDocumentModal,
      isLoading: true,
      status: WarehouseModalStatus.Called,
      type: WarehouseModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateFactoryGoodsIssueDocumentSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateFactoryGoodsIssueDocumentFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.factoryGoodsIssueDocumentModal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateFactoryGoodsIssueDocument, (state) => {
    const modal = {
      ...state.factoryGoodsIssueDocumentModal,
      isLoading: true,
      status: WarehouseModalStatus.Called,
      type: WarehouseModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateFactoryGoodsIssueDocumentSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: WarehouseModalStatus.Success,
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(updateFactoryGoodsIssueDocumentFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.factoryGoodsIssueDocumentModal,
      isLoading: false,
      status: WarehouseModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      factoryGoodsIssueDocumentModal: modal,
    };
  }),
  on(printStockGoodsIssueDocument, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: true,
    };
  }),
  on(printStockGoodsIssueDocumentSuccess, (state, { payload }) => {
    return {
      ...state,
      printData: payload,
      printLoading: false,
    };
  }),
  on(printStockGoodsIssueDocumentFailure, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: false,
    };
  }),
  on(printStockGoodsReceivedNote, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: true,
    };
  }),
  on(printStockGoodsReceivedNoteSuccess, (state, { payload }) => {
    return {
      ...state,
      printData: payload,
      printLoading: false,
    };
  }),
  on(printStockGoodsReceivedNoteFailure, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: false,
    };
  }),
  on(printFactoryGoodsIssueDocument, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: true,
    };
  }),
  on(printFactoryGoodsIssueDocumentSuccess, (state, { payload }) => {
    return {
      ...state,
      printData: payload,
      printLoading: false,
    };
  }),
  on(printFactoryGoodsIssueDocumentFailure, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: false,
    };
  })
);
