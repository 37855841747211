import { ProductRequirementsActions } from './actions';
import { ProductRequirementModel, ProductRequirementLineModel } from './model';
import { createReducer, on } from '@ngrx/store';
import { GoodsIssueRequestModel } from '../goods-issue-request';
import { ManufactureRequestModel } from '../manufacture-request';
import { PurchaseRequestModel } from '../purchase-request';

export enum ProductRequirementModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum ProductRequirementModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getProductRequirements,
  getProductRequirementsFailure,
  getProductRequirementsSuccess,
  getProductRequirement,
  getProductRequirementFailure,
  getProductRequirementSuccess,
  createProductRequirement,
  createProductRequirementFailure,
  createProductRequirementSuccess,
  updateProductRequirement,
  updateProductRequirementFailure,
  updateProductRequirementSuccess,
  deleteProductRequirement,
  deleteProductRequirementFailure,
  deleteProductRequirementSuccess,
  openProductRequirementView,

  getProductRequirementManufactureRequests,
  getProductRequirementManufactureRequestsSuccess,
  getProductRequirementManufactureRequestsFailure,
  getProductRequirementGoodsIssueRequests,
  getProductRequirementGoodsIssueRequestsSuccess,
  getProductRequirementGoodsIssueRequestsFailure,

  getProductRequirementPurchaseRequests,
  getProductRequirementPurchaseRequestsSuccess,
  getProductRequirementPurchaseRequestsFailure,

  cleanProductInProductRequirement,
  addProductInProductRequirement,
  editProductInProductRequirement,
  removeProductInProductRequirement,
  addProductInProductRequirementTransferview,
  removeProductInProductRequirementTransferview,

  downloadProductRequirementFile,
  downloadProductRequirementFileSuccess,
  downloadProductRequirementFileFailure,
} = ProductRequirementsActions;
export interface ProductRequirementState {
  list: Array<ProductRequirementModel>;
  item: ProductRequirementModel;
  itemLoading: boolean;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId?: number;
  };
  loading: boolean;
  modal: {
    type: ProductRequirementModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: ProductRequirementModalStatus;
  };
  inProducts: Array<ProductRequirementLineModel>;

  manufactureRequests: Array<ManufactureRequestModel>;
  manufactureRequestsLoading: boolean;
  manufactureRequestsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  manufactureRequestModal: {
    type: ProductRequirementModalType;
    data: ManufactureRequestModel;
    isLoading: boolean;
    errors: Array<any>;
    status: ProductRequirementModalStatus;
  };
  manufactureRequestsParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };

  goodsIssueRequests: Array<GoodsIssueRequestModel>;
  goodsIssueRequestsLoading: boolean;
  goodsIssueRequestsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  goodsIssueRequestModal: {
    type: ProductRequirementModalType;
    data: GoodsIssueRequestModel;
    isLoading: boolean;
    errors: Array<any>;
    status: ProductRequirementModalStatus;
  };
  goodsIssueRequestsParams: {
    page: number;
    size: number;
    sort: string;
    search: string;
    name: string;
    projectId: number;
  };

  purchaseRequests: Array<PurchaseRequestModel>;
  purchaseRequestsLoading: boolean;
  purchaseRequestsPagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  purchaseRequestModal: {
    type: ProductRequirementModalType;
    data: PurchaseRequestModel;
    isLoading: boolean;
    errors: Array<any>;
    status: ProductRequirementModalStatus;
  };
  purchaseRequestsParams: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId: number;
  };

  printLoading: boolean;
  printData: Blob;
}

export const initialState: ProductRequirementState = {
  list: [],
  item: null,
  itemLoading: false,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  loading: false,
  modal: {
    type: ProductRequirementModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: ProductRequirementModalStatus.Init,
  },
  inProducts: [],

  manufactureRequests: [],
  manufactureRequestsLoading: false,
  manufactureRequestsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  manufactureRequestModal: {
    type: ProductRequirementModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: ProductRequirementModalStatus.Init,
  },
  manufactureRequestsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },

  goodsIssueRequests: [],
  goodsIssueRequestsLoading: false,
  goodsIssueRequestsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  goodsIssueRequestModal: {
    type: ProductRequirementModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: ProductRequirementModalStatus.Init,
  },
  goodsIssueRequestsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  purchaseRequests: [],
  purchaseRequestsLoading: false,
  purchaseRequestsPagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  purchaseRequestModal: {
    type: ProductRequirementModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: ProductRequirementModalStatus.Init,
  },
  purchaseRequestsParams: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },

  printLoading: false,
  printData: null,
};

export const reducer = createReducer(
  initialState,
  on(getProductRequirements, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getProductRequirementsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getProductRequirementsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getProductRequirement, (state) => {
    return { ...state, itemLoading: true };
  }),
  on(getProductRequirementSuccess, (state, { payload }) => {
    return { ...state, itemLoading: false, item: { ...payload.data } };
  }),
  on(getProductRequirementFailure, (state) => {
    return { ...state, itemLoading: false };
  }),
  on(getProductRequirementsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getProductRequirementsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updateProductRequirement, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: ProductRequirementModalStatus.Called,
      type: ProductRequirementModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateProductRequirementSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProductRequirementModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updateProductRequirementFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProductRequirementModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createProductRequirement, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: ProductRequirementModalStatus.Init,
      type: ProductRequirementModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createProductRequirementSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProductRequirementModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createProductRequirementFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: ProductRequirementModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deleteProductRequirement, (state) => {
    return { ...state, loading: true };
  }),
  on(deleteProductRequirementSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deleteProductRequirementFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openProductRequirementView, (state, { payload }) => {
    const modal = {
      ...state.modal,
      status: ProductRequirementModalStatus.Init,
      errors: [],
    };

    if (payload && Array.isArray(payload.inProducts)) {
      const lines = payload.inProducts;
      const inProducts = lines.map((c) => {
        return {
          ...c,
          uid: `server-${c.id}`,
          name: c.productName,
          note: c.note,
          unit: c.productUnit,
          code: c.requirementCode,
          productReqLineId: c.id,
          productRequirementLineId: c.id,
        };
      });
      return {
        ...state,
        modal,
        inProducts,
      };
    }

    return {
      ...state,
      modal,
      inProducts: [],
    };
  }),
  on(addProductInProductRequirement, (state, { payload }) => {
    const { product, quantity } = payload;
    const length = state.inProducts.length + 1;

    const productExist = state.inProducts.find((p) => p.productId === product.id);
    if (productExist) {
      const inProducts = state.inProducts.map((p) => {
        let qty = p.quantity;
        if (p.productId === product.id) {
          qty = p.quantity + quantity;
        }
        return { ...p, quantity: qty, isEdited: true };
      });

      return {
        ...state,
        inProducts,
      };
    }
    const inProduct = {
      uid: `local-${length}`,
      name: product.name,
      productId: product.id,
      productUnit: product.unit,
      productType: product.type,
      quantity,
      isNew: true,
    } as ProductRequirementLineModel;

    return {
      ...state,
      inProducts: [...state.inProducts, inProduct],
    };
  }),
  on(editProductInProductRequirement, (state, { payload }) => {
    const { product, quantity } = payload;

    const newProducts = state.inProducts.map((c) => {
      if (c.productId === product.productId && !c.isNew) {
        return {
          ...c,
          ...product,
          productId: product.productId,
          quantity,
          isEdited: true,
        };
      }
      if (c.productId === product.productId && c.isNew) {
        return { ...c, ...product, productId: product.productId };
      }
      return c;
    });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(removeProductInProductRequirement, (state, { payload }) => {
    if (!payload) {
      return {
        ...state,
      };
    }
    const { product } = payload;
    const newProducts = state.inProducts
      .map((c) => {
        if (c.productId === product.productId) {
          return { ...c, isRemoved: true };
        }
        return c;
      })
      .filter((c) => {
        if (c.productId === product.productId && c.isNew) {
          return false;
        }
        return true;
      });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(cleanProductInProductRequirement, (state) => {
    return {
      ...state,
      inProducts: [],
    };
  }),
  on(addProductInProductRequirementTransferview, (state, { payload }) => {
    const { products } = payload;
    const length = state.inProducts.length + 1;
    const inProducts = products.map((product) => {
      return {
        uid: `local-${length}`,
        name: product.productName,
        productId: product.productId,
        productUnit: product.productUnit,
        quantity: product.quantity,
        isNew: true,
        productReqLineId: product.id,
        productRequirementLineId: product.id,
      } as ProductRequirementLineModel;
    });
    return {
      ...state,
      inProducts: [...state.inProducts, ...inProducts],
    };
  }),
  on(removeProductInProductRequirementTransferview, (state, { payload }) => {
    if (!payload) {
      return {
        ...state,
      };
    }
    const { products } = payload;

    const newProducts = state.inProducts
      .map((c) => {
        const foundProduct = products.find((product) => product.productId === c.productId);
        if (foundProduct && c.productId === foundProduct.productId) {
          return { ...c, isRemoved: true };
        }
        return c;
      })
      .filter((c) => {
        const foundProduct = products.find((product) => product.productId === c.productId);
        if (foundProduct && c.productId === foundProduct.productId && c.isNew) {
          return false;
        }
        return true;
      });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(getProductRequirementGoodsIssueRequests, (state, { payload }) => {
    return {
      ...state,
      goodsIssueRequestsLoading: true,
      goodsIssueRequestsParams: { ...payload },
    };
  }),
  on(getProductRequirementGoodsIssueRequestsSuccess, (state, { payload }) => {
    return {
      ...state,
      goodsIssueRequests: [...payload.data],
      goodsIssueRequestsPagination: { ...payload.pagination },
      goodsIssueRequestsLoading: false,
    };
  }),
  on(getProductRequirementGoodsIssueRequestsFailure, (state) => {
    return {
      ...state,
      goodsIssueRequestsLoading: false,
    };
  }),
  on(getProductRequirementManufactureRequests, (state, { payload }) => {
    return {
      ...state,
      manufactureRequestsLoading: true,
      manufactureRequestsParams: { ...payload },
    };
  }),
  on(getProductRequirementManufactureRequestsSuccess, (state, { payload }) => {
    return {
      ...state,
      manufactureRequests: [...payload.data],
      manufactureRequestsPagination: { ...payload.pagination },
      manufactureRequestsLoading: false,
    };
  }),
  on(getProductRequirementManufactureRequestsFailure, (state) => {
    return {
      ...state,
      manufactureRequestsLoading: false,
    };
  }),
  on(getProductRequirementPurchaseRequests, (state, { payload }) => {
    return {
      ...state,
      purchaseRequestsLoading: true,
      purchaseRequestsParams: { ...payload },
    };
  }),
  on(getProductRequirementPurchaseRequestsSuccess, (state, { payload }) => {
    return {
      ...state,
      purchaseRequests: [...payload.data],
      purchaseRequestsPagination: { ...payload.pagination },
      purchaseRequestsLoading: false,
    };
  }),
  on(getProductRequirementPurchaseRequestsFailure, (state) => {
    return {
      ...state,
      purchaseRequestsLoading: false,
    };
  }),

  on(downloadProductRequirementFile, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: true,
    };
  }),
  on(downloadProductRequirementFileSuccess, (state, { payload }) => {
    return {
      ...state,
      printData: payload,
      printLoading: false,
    };
  }),
  on(downloadProductRequirementFileFailure, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: false,
    };
  })
);
