import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as formUsers from './user/reducer';
import * as fromSettings from './setting/reducer';
import * as fromAuthority from './authority/reducer';
import * as fromNotification from './notification/reducer';

import * as fromProvinces from './province/reducer';
import * as fromDistricts from './district/reducer';

import * as fromDriver from './driver/reducer';
import * as fromLorry from './lorry/reducer';
import * as fromTrip from './trip/reducer';
import * as fromTransport from './transport-request/reducer';

import * as fromContractor from './contractor/reducer';
import * as fromTeam from './team/reducer';
import * as fromWorker from './worker/reducer';

import * as fromProduct from './product/reducer';
import * as fromWarehouse from './warehouse/reducer';

import * as fromProductRequirement from './product-requirement/reducer';
import * as fromGoodsIssueDocument from './goods-issue-document/reducer';
import * as fromGoodsReceivedNote from './goods-received-note/reducer';
import * as fromGoodsIssueRequest from './goods-issue-request/reducer';
import * as fromManufactureRequest from './manufacture-request/reducer';
import * as fromPurchaseRequest from './purchase-request/reducer';

import * as fromProject from './project/reducer';
import * as fromWorkOutline from './work-outline/reducer';
import * as fromCriterion from './criterion/reducer';
import * as fromCriteriaBundle from './criteria-bundle/reducer';

import * as fromDesignFile from './design-file/reducer';

import * as fromSafetyCriteria from './safety-criteria/reducer';

import * as fromQualityCriteria from './quality-criteria/reducer';

export interface AppState {
  users: formUsers.UserState;
  authorities: fromAuthority.AuthorityState;
  settings: fromSettings.SettingState;
  notifications: fromNotification.NotificationState;

  districts: fromDistricts.DistrictState;
  provinces: fromProvinces.ProvinceState;

  transportRequests: fromTransport.TransportRequestState;
  lorries: fromLorry.LorryState;
  drivers: fromDriver.DriverState;
  trips: fromTrip.TripState;

  workers: fromWorker.WorkerState;
  teams: fromTeam.TeamState;
  contractors: fromContractor.ContractorState;

  warehouses: fromWarehouse.WarehouseState;
  products: fromProduct.ProductState;

  productRequirements: fromProductRequirement.ProductRequirementState;

  goodsIssueDocuments: fromGoodsIssueDocument.GoodsIssueDocumentState;
  goodsReceivedNotes: fromGoodsReceivedNote.GoodsReceivedNoteState;
  goodsIssueRequests: fromGoodsIssueRequest.GoodsIssueRequestState;
  manufactureRequests: fromManufactureRequest.ManufactureRequestState;
  purchaseRequests: fromPurchaseRequest.PurchaseRequestState;

  projects: fromProject.ProjectState;
  workOutlines: fromWorkOutline.WorkOutlineState;
  criterions: fromCriterion.CriterionState;
  criteriaBundles: fromCriteriaBundle.CriteriaBundleState;
  designFiles: fromDesignFile.DesignFileState;

  safetyCriterias: fromSafetyCriteria.SafetyCriteriaState;
  qualityCriterias: fromQualityCriteria.QualityCriteriaState;
}

export const reducers: ActionReducerMap<AppState> = {
  users: formUsers.reducer,
  authorities: fromAuthority.reducer,
  settings: fromSettings.reducer,
  notifications: fromNotification.reducer,

  districts: fromDistricts.reducer,
  provinces: fromProvinces.reducer,

  transportRequests: fromTransport.reducer,
  lorries: fromLorry.reducer,
  drivers: fromDriver.reducer,
  trips: fromTrip.reducer,

  teams: fromTeam.reducer,
  workers: fromWorker.reducer,
  contractors: fromContractor.reducer,

  warehouses: fromWarehouse.reducer,
  products: fromProduct.reducer,
  productRequirements: fromProductRequirement.reducer,

  goodsIssueDocuments: fromGoodsIssueDocument.reducer,
  goodsReceivedNotes: fromGoodsReceivedNote.reducer,
  goodsIssueRequests: fromGoodsIssueRequest.reducer,
  manufactureRequests: fromManufactureRequest.reducer,
  purchaseRequests: fromPurchaseRequest.reducer,

  projects: fromProject.reducer,
  workOutlines: fromWorkOutline.reducer,
  criterions: fromCriterion.reducer,
  criteriaBundles: fromCriteriaBundle.reducer,

  designFiles: fromDesignFile.reducer,

  safetyCriterias: fromSafetyCriteria.reducer,
  qualityCriterias: fromQualityCriteria.reducer,
};

export const selectContainerState = createFeatureSelector<AppState>('appStore');
