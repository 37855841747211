<div class="login-wrapper">
  <form nz-form [formGroup]="loginForm" (ngSubmit)="onSubmit()" role="form">
    <section class="title">
      <h3 class="welcome">Chào mừng đến với</h3>
      Quản lý công trình<br />
      Á Đông
      <h5 class="hint">Sử dụng tài khoản công ty cung cấp</h5>
    </section>
    <div class="login-group">
      <nz-alert *ngIf="error" [nzType]="'error'" [nzMessage]="error" [nzShowIcon]="true" class="mb-lg"></nz-alert>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group nzSize="large" nzPrefixIcon="user">
            <input nz-input formControlName="username" placeholder="Username" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <nz-input-group nzSize="large" nzPrefixIcon="lock">
            <input nz-input type="password" formControlName="password" placeholder="Password" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <button nz-button type="submit" nzType="primary" nzSize="large" [nzLoading]="isAuthenticating$ | async" nzBlock>
          {{ 'Login' | translate }}
        </button>
      </nz-form-item>
    </div>
  </form>
</div>
