<ng-container *ngTemplateOutlet="nestableList; context: { nestable: list, depth: 0 }"> </ng-container>

<ng-template #nestableList let-nestable="nestable" let-depth="depth">
  <ul [style.padding-left.px]="depth ? options.threshold : 0" [class]="options.listClass">
    <li [class]="options.itemClass" [id]="item.$$id" *ngFor="let item of nestable">
      <ng-container *ngTemplateOutlet="nestableItem; context: { nestable: nestable, item: item, depth: depth }"> </ng-container>
      <ng-container *ngIf="item.children && item.$$expanded">
        <ng-container *ngTemplateOutlet="nestableList; context: { nestable: item.children, depth: depth + 1 }"> </ng-container>
      </ng-container>
      <ul [class]="options.listClass" [style.padding-left.px]="options.threshold"></ul>
    </li>
  </ul>
</ng-template>

<ng-template #nestableItem let-parentList="nestable" let-item="item" let-depth="depth">
  <div class="nestable-item-container" (mousedown)="dragStartFromItem($event, item, parentList)">
    <ng-container
      *ngTemplateOutlet="
        template;
        context: {
          $implicit: { item: item, parentList: parentList },
          depth: depth
        }
      "
    >
    </ng-container>
    <div class="nestable-item-mask"></div>
  </div>
</ng-template>
