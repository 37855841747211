import { DesignFileResultsModel, DesignFileResultModel } from './../design-file';
import { createAction, props } from '@ngrx/store';

import { ProjectModel, ProjectResumeModel } from './model';
import { ProjectResultsModel, ProjectResultModel, ProjectLogResultsModel, ProjectPhotoResultsModel } from './results.model';
import { ProductRequirementResultsModel, ProductRequirementModel, ProjectRegistrationResultsModel } from '../product-requirement';
import { WorkerResultsModel, WorkerModel, WorkerAttendanceResultsModel } from '../worker';
import { WorkOutlineResultsModel } from '../work-outline';
import { SafetyCriteriaResultsModel, SafetyCriteriaModel } from '../safety-criteria';
import { QualityCriteriaResultsModel, QualityCriteriaModel } from '../quality-criteria';

import { MarkModel, MarkResultsModel } from '../criteria-bundle';

const getProjects = createAction('[PROJECT] Get Projects', props<{ payload: any }>());

const getProjectsSuccess = createAction('[PROJECT] Get Projects Success', props<{ payload: ProjectResultsModel }>());

export const getProjectsFailure = createAction('[PROJECT] Get Projects Faild');

const getReportProjects = createAction('[PROJECT] Get Report Projects', props<{ payload: any }>());

const getReportProjectsSuccess = createAction('[PROJECT] Get Report Projects Success', props<{ payload: ProjectResultsModel }>());

const getReportProjectsFailure = createAction('[PROJECT] Get Report Projects Faild');

const getProjectsForMap = createAction('[PROJECT] Get Projects Map', props<{ payload: any }>());

export const getProjectsForMapSuccess = createAction('[PROJECT] Get Projects Map Success', props<{ payload: ProjectResultsModel }>());

export const getProjectsForMapFailure = createAction('[PROJECT] Get Projects Map Faild');

const getProjectsDashboard = createAction('[PROJECT] Get ProjectsDashboard', props<{ payload: any }>());

const getProjectsDashboardSuccess = createAction('[PROJECT] Get ProjectsDashboard Success', props<{ payload: ProjectResultsModel }>());

export const getProjectsDashboardFailure = createAction('[PROJECT] Get ProjectsDashboard Faild');

const getProject = createAction('[PROJECT] Get Project', props<{ payload: any }>());

const getProjectSuccess = createAction('[PROJECT] Get Project Success', props<{ payload: ProjectResultModel }>());

export const getProjectFailure = createAction('[PROJECT] Get Project Faild');

const createProject = createAction('[PROJECT] Create Project', props<{ payload: ProjectModel }>());

const createProjectSuccess = createAction('[PROJECT] Create Project Success', props<{ payload: ProjectResultModel }>());

export const createProjectFailure = createAction('[PROJECT] Create Project Faild', props<{ payload: ProjectResultModel }>());

const updateProject = createAction('[PROJECT] Update Project', props<{ payload: ProjectModel }>());

const updateProjectSuccess = createAction('[PROJECT] Update Project Success', props<{ payload: ProjectResultModel }>());

export const updateProjectFailure = createAction('[PROJECT] Update Project Faild', props<{ payload: ProjectResultModel }>());

const deleteProject = createAction('[PROJECT] Delete Project', props<{ payload: number }>());

const deleteProjectSuccess = createAction('[PROJECT] Delete Project Success', props<{ payload: ProjectResultModel }>());

export const deleteProjectFailure = createAction('[PROJECT] Delete Project Faild', props<{ payload: ProjectResultModel }>());

export const openProjectView = createAction('[PROJECT] Open Project View');

const getProjectProductRequirements = createAction('[PROJECT] Get Project ProductRequirements', props<{ payload: any }>());

const getProjectProductRequirementsSuccess = createAction(
  '[PROJECT] Get Project ProductRequirements Success',
  props<{ payload: ProductRequirementResultsModel }>()
);

export const getProjectProductRequirementsFailure = createAction('[PROJECT] Get Project ProductRequirements Faild');

export const createProjectProductRequirement = createAction(
  '[PROJECT] Create Project Product Requirement',
  props<{
    payload: { projectId: number; productRequirement: ProductRequirementModel };
  }>()
);

const createProjectProductRequirementSuccess = createAction(
  '[PROJECT] Create Project Product Requirement Success',
  props<{ payload: ProjectResultModel }>()
);

export const createProjectProductRequirementFailure = createAction('[PROJECT] Create Project Product Requirement Faild');

export const updateProjectProductRequirement = createAction(
  '[PROJECT] Update Project Product Requirement',
  props<{
    payload: { projectId: number; productRequirement: ProductRequirementModel };
  }>()
);

const updateProjectProductRequirementSuccess = createAction(
  '[PROJECT] Update Project Product Requirement Success',
  props<{ payload: ProjectResultModel }>()
);

export const updateProjectProductRequirementFailure = createAction('[PROJECT] Update Project Product Requirement Faild');

const getProjectWorkers = createAction('[PROJECT] Get Project Workers', props<{ payload: any }>());

const getProjectWorkersSuccess = createAction('[PROJECT] Get Project Workers Success', props<{ payload: WorkerResultsModel }>());

export const getProjectWorkersFailure = createAction('[PROJECT] Get Project Workers Faild');

const cleanProjectMembers = createAction('[PROJECT] Clean Project Members');

const removeProjectMember = createAction('[PROJECT] Remove Project Member', props<{ memberId: number }>());

const addProjectMember = createAction('[PROJECT] Add Project Member', props<{ worker: WorkerModel; projectId: number }>());

const addProjectMemberSuccess = createAction('[PROJECT] Add Project Member Success Faild', props<{ payload: ProjectResultModel }>());

const addProjectMemberFailure = createAction('[PROJECT] Add Project Member Faild');

export const getProjectUploadSessions = createAction('[PROJECT] Get Project UploadSessions', props<{ payload: { projectId?: number } }>());

export const getProjectUploadSessionsSuccess = createAction(
  '[PROJECT] Get Project UploadSessions Succsess',
  props<{ payload: DesignFileResultsModel }>()
);

export const getProjectUploadSessionsFailure = createAction('[PROJECT] Get Project UploadSessions Failure');

export const getProjectUploadSessionFile = createAction('[PROJECT] Get Project UploadSession File', props<{ payload: any }>());

export const getProjectUploadSessionFileSuccess = createAction(
  '[PROJECT] Get Project UploadSession File Succsess',
  props<{ payload: DesignFileResultModel }>()
);

export const getProjectUploadSessionFileFailure = createAction('[PROJECT] Get Project UploadSession File Failure');

export const addProjectDesignFile = createAction('[PROJECT] Add Project DesignFile', props<{ payload: any }>());
export const removeProjectDesignFile = createAction('[PROJECT] Remove Project DesignFile', props<{ payload: any }>());

export const uploadProjectDesignFile = createAction('[PROJECT] Upload Project DesignFile', props<{ payload: any }>());

export const uploadProjectDesignFileSuccess = createAction(
  '[PROJECT] Upload Project DesignFile Succsess',
  props<{ payload: DesignFileResultModel }>()
);

export const uploadProjectDesignFileFailure = createAction('[PROJECT] Upload Project DesignFile Failure');

export const createProjectUploadSession = createAction(
  '[PROJECT] Create Project UploadSession',
  props<{
    payload: { projectId: number };
  }>()
);

const createProjectUploadSessionSuccess = createAction(
  '[PROJECT] Create Project UploadSession Success',
  props<{ payload: ProjectResultModel }>()
);

export const createProjectUploadSessionFailure = createAction('[PROJECT] Create Project UploadSession Failure');

export const updateProjectUploadSession = createAction(
  '[PROJECT] Update Project UploadSession',
  props<{ payload: { uploadSessionId: number; note: string } }>()
);

export const updateProjectUploadSessionSuccess = createAction(
  '[PROJECT] Update Project UploadSession Success',
  props<{ payload: ProjectResultModel }>()
);

export const updateProjectUploadSessionFailure = createAction('[PROJECT] Update Project UploadSession Failure');

export const getProjectSafetyCriterias = createAction('[PROJECT] Get Project SafetyCriterias', props<{ payload: any }>());

export const getProjectSafetyCriteriasSuccess = createAction(
  '[PROJECT] Get Project SafetyCriteras Success',
  props<{ payload: SafetyCriteriaResultsModel }>()
);

export const getProjectSafetyCriteriasFailure = createAction('[PROJECT] Get Project SafetyCriterias Failure');

export const createProjectSafetyCriteria = createAction(
  '[PROJECT] Create Project a SafetyCriteria',
  props<{
    payload: {
      projectId: number;
      safetyCriteria: SafetyCriteriaModel;
    };
  }>()
);

export const createProjectSafetyCriteriaSuccess = createAction(
  '[PROJECT] Create a Project SafetyCriteria Success',
  props<{ payload: ProjectResultModel }>()
);

export const createProjectSafetyCriteriaFailure = createAction('[PROJECT] Create a Project SafetyCriteria Failure');

export const getProjectQualityCriterias = createAction('[PROJECT] Get Project QualityCriterias', props<{ payload: any }>());

export const getProjectQualityCriteriasSuccess = createAction(
  '[PROJECT] Get Project QualityCriteras Success',
  props<{ payload: QualityCriteriaResultsModel }>()
);

export const getProjectQualityCriteriasFailure = createAction('[PROJECT] Get Project QualityCriterias Failure');

export const createProjectQualityCriteria = createAction(
  '[PROJECT] Create Project a QualityCriteria',
  props<{
    payload: {
      projectId: number;
      qualityCriteria: QualityCriteriaModel;
    };
  }>()
);

export const createProjectQualityCriteriaSuccess = createAction(
  '[PROJECT] Create a Project QualityCriteria Success',
  props<{ payload: ProjectResultModel }>()
);

export const createProjectQualityCriteriaFailure = createAction('[PROJECT] Create a Project QualityCriteria Failure');

const getProjectWorkOutlines = createAction('[PROJECT] Get Project WorkOutlines', props<{ payload: { projectId: number } }>());

const getProjectWorkOutlinesSuccess = createAction(
  '[PROJECT] Get Project WorkOutlines Success',
  props<{ payload: WorkOutlineResultsModel }>()
);

export const getProjectWorkOutlinesFailure = createAction('[PROJECT] Get Project WorkOutlines Faild');

const getProjectAttendances = createAction('[PROJECT] Get Project Attendances', props<{ payload: { projectId: number } }>());

const getProjectAttendancesSuccess = createAction(
  '[PROJECT] Get Project Attendances Success',
  props<{ payload: WorkerAttendanceResultsModel }>()
);

export const getProjectAttendancesFailure = createAction('[PROJECT] Get Project Attendances Faild');

const getProjectLogs = createAction('[PROJECT] Get Project Logs', props<{ payload: { projectId: number } }>());

const getProjectLogsSuccess = createAction('[PROJECT] Get Project Logs Success', props<{ payload: ProjectLogResultsModel }>());

export const getProjectLogsFailure = createAction('[PROJECT] Get Project Logs Faild');

const getProjectRegistrations = createAction('[PROJECT] Get Project Registrations', props<{ payload: any }>());

const getProjectRegistrationsSuccess = createAction(
  '[PROJECT] Get Project Registrations Success',
  props<{ payload: ProjectRegistrationResultsModel }>()
);

export const getProjectRegistrationsFailure = createAction('[PROJECT] Get Project Registrations Faild');

const approveProjectRegistration = createAction('[PROJECT] Approve Project Registrations', props<{ payload: any }>());

const approveProjectRegistrationSuccess = createAction(
  '[PROJECT] Approve Project Registrations Success',
  props<{ payload: ProjectRegistrationResultsModel }>()
);

export const approveProjectRegistrationFailure = createAction('[PROJECT] Approve Project Registrations Faild');
const getProjectPhotosCheckin = createAction('[PROJECT] Get Project PhotosCheckin', props<{ payload: any }>());

const getProjectPhotosCheckinSuccess = createAction(
  '[PROJECT] Get Project PhotosCheckin Success',
  props<{ payload: ProjectPhotoResultsModel }>()
);

export const getProjectPhotosCheckinFailure = createAction('[PROJECT] Get Project PhotosCheckin Faild');

const getProjectPhotosComplete = createAction('[PROJECT] Get Project PhotosComplete', props<{ payload: any }>());

const getProjectPhotosCompleteSuccess = createAction(
  '[PROJECT] Get Project PhotosComplete Success',
  props<{ payload: ProjectPhotoResultsModel }>()
);

export const getProjectPhotosCompleteFailure = createAction('[PROJECT] Get Project PhotosComplete Failure');

const markProjectDone = createAction('[PROJECT] Mark Project Done', props<{ payload: { projectId: number } }>());

const markProjectDoneSuccess = createAction('[PROJECT] Mark Project Done Success', props<{ payload: ProjectResultModel }>());

export const markProjectDoneFailure = createAction('[PROJECT] Mark Project Done Faild', props<{ payload: ProjectResultModel }>());

const markProjectAccountingDone = createAction(
  '[PROJECT] Mark Project Accounting Done',
  props<{ payload: { projectId: number; note?: string; status: boolean } }>()
);

const markProjectAccountingDoneSuccess = createAction(
  '[PROJECT] Mark Project Accounting Done Success',
  props<{ payload: ProjectResultModel }>()
);

export const markProjectAccountingDoneFailure = createAction(
  '[PROJECT] Mark Project Accounting Done Faild',
  props<{ payload: ProjectResultModel }>()
);

export const markCriteriaBundle = createAction(
  '[PROJECT] Mark CriteriaBundle',
  props<{ payload: { projectId: number; markCriteriaBundle: MarkModel } }>()
);

export const markCriteriaBundleSuccess = createAction('[PROJECT] Mark CriteriaBundle Success', props<{ payload: ProjectResultModel }>());

export const markCriteriaBundleFailure = createAction('[PROJECT] Mark CriteriaBundle Failure');

export const getMarkSessions = createAction('[PROJECT] get Mark Sessions', props<{ payload: number }>());

export const getMarkSessionsSuccess = createAction('[PROJECT] Get Mark Sessions Success', props<{ payload: MarkResultsModel }>());

export const getMarkSessionsFailure = createAction('[PROJECT] Get Mark Sessions Failure');

export const pauseProject = createAction('[PROJECT] Pause Project', props<{ payload: { projectId: number; note?: string } }>());

export const pauseProjectSuccess = createAction('[PROJECT] Pause Project Success', props<{ payload: ProjectResultsModel }>());

export const pauseProjectFailure = createAction('[PROJECT] Pause Project Failure');

const finishProjectWorkoutLine = createAction('[PROJECT] Finish WorkOutline', props<{ payload: { workoutLineId: number } }>());

const finishProjectWorkoutLineSuccess = createAction('[PROJECT] Finish WorkOutline Success', props<{ payload: ProjectResultModel }>());

export const finishProjectWorkoutLineFailure = createAction('[PROJECT] Finish WorkOutline Faild', props<{ payload: ProjectResultModel }>());

export const openResumeProjectView = createAction('[PROJECT] Open Resume Project View');

export const resumeProject = createAction(
  '[PROJECT] Resume Project',
  props<{ payload: { projectId: number; data: ProjectResumeModel } }>()
);

export const resumeProjectSuccess = createAction('[PROJECT] Resume Project Success', props<{ payload: ProjectResultsModel }>());

export const resumeProjectFailure = createAction('[Project] Resume Project Failure');

export const changeTeamLeaderProject = createAction(
  '[PROJECT] Change Team Leader Project',
  props<{ payload: { projectId: number; teamLeaderId: number } }>()
);

export const changeTeamLeaderProjectSuccess = createAction(
  '[PROJECT] Change Team Leader Project Success',
  props<{ payload: ProjectResultsModel }>()
);

export const changeTeamLeaderProjectFailure = createAction('[Project] Change Team Leader Project Failure');

export const ProjectsActions = {
  getReportProjects,
  getReportProjectsSuccess,
  getReportProjectsFailure,
  uploadProjectDesignFile,
  uploadProjectDesignFileSuccess,
  uploadProjectDesignFileFailure,
  finishProjectWorkoutLine,
  finishProjectWorkoutLineSuccess,
  finishProjectWorkoutLineFailure,
  getProjectsForMap,
  getProjectsForMapSuccess,
  getProjectsForMapFailure,
  markProjectDone,
  markProjectDoneSuccess,
  markProjectDoneFailure,
  markProjectAccountingDone,
  markProjectAccountingDoneSuccess,
  markProjectAccountingDoneFailure,
  getProjectsDashboard,
  getProjectsDashboardSuccess,
  getProjectsDashboardFailure,
  getProjectPhotosComplete,
  getProjectPhotosCompleteSuccess,
  getProjectPhotosCompleteFailure,
  getProjectPhotosCheckin,
  getProjectPhotosCheckinSuccess,
  getProjectPhotosCheckinFailure,
  getProjectRegistrations,
  getProjectRegistrationsSuccess,
  getProjectRegistrationsFailure,
  approveProjectRegistration,
  approveProjectRegistrationSuccess,
  approveProjectRegistrationFailure,
  getProjectLogs,
  getProjectLogsSuccess,
  getProjectLogsFailure,
  getProjectAttendances,
  getProjectAttendancesSuccess,
  getProjectAttendancesFailure,
  getProjectWorkOutlines,
  getProjectWorkOutlinesSuccess,
  getProjectWorkOutlinesFailure,
  getProjectQualityCriterias,
  getProjectQualityCriteriasSuccess,
  getProjectQualityCriteriasFailure,
  createProjectQualityCriteria,
  createProjectQualityCriteriaSuccess,
  createProjectQualityCriteriaFailure,
  getProjectSafetyCriterias,
  getProjectSafetyCriteriasSuccess,
  getProjectSafetyCriteriasFailure,
  createProjectSafetyCriteria,
  createProjectSafetyCriteriaSuccess,
  createProjectSafetyCriteriaFailure,
  getProjectUploadSessions,
  getProjectUploadSessionsSuccess,
  getProjectUploadSessionsFailure,
  getProjectUploadSessionFile,
  getProjectUploadSessionFileSuccess,
  getProjectUploadSessionFileFailure,
  addProjectDesignFile,
  removeProjectDesignFile,
  createProjectUploadSession,
  createProjectUploadSessionSuccess,
  createProjectUploadSessionFailure,
  updateProjectUploadSession,
  updateProjectUploadSessionSuccess,
  updateProjectUploadSessionFailure,
  cleanProjectMembers,
  removeProjectMember,
  addProjectMember,
  addProjectMemberSuccess,
  addProjectMemberFailure,
  getProjectWorkers,
  getProjectWorkersSuccess,
  getProjectWorkersFailure,
  createProjectProductRequirement,
  createProjectProductRequirementSuccess,
  createProjectProductRequirementFailure,
  getProjectProductRequirements,
  getProjectProductRequirementsSuccess,
  getProjectProductRequirementsFailure,
  openProjectView,
  getProjects,
  getProjectsSuccess,
  getProjectsFailure,
  getProject,
  getProjectSuccess,
  getProjectFailure,
  createProject,
  createProjectSuccess,
  createProjectFailure,
  updateProject,
  updateProjectSuccess,
  updateProjectFailure,
  deleteProject,
  deleteProjectSuccess,
  deleteProjectFailure,
  markCriteriaBundle,
  markCriteriaBundleSuccess,
  markCriteriaBundleFailure,
  getMarkSessions,
  getMarkSessionsSuccess,
  getMarkSessionsFailure,
  pauseProject,
  pauseProjectSuccess,
  pauseProjectFailure,
  resumeProject,
  resumeProjectSuccess,
  resumeProjectFailure,
  openResumeProjectView,
  changeTeamLeaderProject,
  changeTeamLeaderProjectSuccess,
  changeTeamLeaderProjectFailure,
};
