import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { pipes } from './pipes';

import { CanViewDirective, ImageAccessDirective } from '@app/@core/directives';

import { UploadFileComponent } from './components/upload/upload.component';
import { CustomRateComponent } from './components/custom-rate/custom-rate.component';
import { ProPageGridComponent } from './components/page-grid/page-grid.component';
import { RoundTripComponent } from './components/round-trip/round-trip.component';
import { AppUIModule } from '../app-ui.module';

@NgModule({
  declarations: [
    ...pipes,
    CanViewDirective,
    ImageAccessDirective,
    UploadFileComponent,
    CustomRateComponent,
    ProPageGridComponent,
    RoundTripComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule, HttpClientModule, ReactiveFormsModule, TranslateModule, AppUIModule],
  exports: [
    ...pipes,
    FormsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    CanViewDirective,
    ImageAccessDirective,
    TranslateModule,
    UploadFileComponent,

    ProPageGridComponent,
    CustomRateComponent,
    RoundTripComponent,
  ],
})
export class SharedModule {}
