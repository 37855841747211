import { createAction, props } from '@ngrx/store';

import {
  GoodsIssueDocumentModel,
  GoodsIssueDocumentLineModel,
} from './model';
import {
  GoodsIssueDocumentResultsModel,
  GoodsIssueDocumentResultModel,
} from './results.model';
import { ProductModel } from '../product/model';

const getGoodsIssueDocuments = createAction(
  '[GOODISSUEDOCUMENT] Get GoodsIssueDocuments',
  props<{ payload: any }>()
);

const getGoodsIssueDocumentsSuccess = createAction(
  '[GOODISSUEDOCUMENT] Get GoodsIssueDocuments Success',
  props<{ payload: GoodsIssueDocumentResultsModel }>()
);

export const getGoodsIssueDocumentsFailure = createAction(
  '[GOODISSUEDOCUMENT] Get GoodsIssueDocuments Faild'
);

const getGoodsIssueDocument = createAction(
  '[GOODISSUEDOCUMENT] Get GoodsIssueDocument',
  props<{ payload: { goodsIssueDocumentId: number } }>()
);

const getGoodsIssueDocumentSuccess = createAction(
  '[GOODISSUEDOCUMENT] Get GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const getGoodsIssueDocumentFailure = createAction(
  '[GOODISSUEDOCUMENT] Get GoodsIssueDocument Faild'
);

const createGoodsIssueDocument = createAction(
  '[GOODISSUEDOCUMENT] Create GoodsIssueDocument',
  props<{
    payload: { projectId: number; goodsIssueDocument: GoodsIssueDocumentModel };
  }>()
);

const createGoodsIssueDocumentSuccess = createAction(
  '[GOODISSUEDOCUMENT] Create GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const createGoodsIssueDocumentFailure = createAction(
  '[GOODISSUEDOCUMENT] Create GoodsIssueDocument Faild',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

const updateGoodsIssueDocument = createAction(
  '[GOODISSUEDOCUMENT] Update GoodsIssueDocument',
  props<{
    payload: {
      goodsIssueDocumentId: number;
      goodsIssueDocument: GoodsIssueDocumentModel;
    };
  }>()
);

const updateGoodsIssueDocumentSuccess = createAction(
  '[GOODISSUEDOCUMENT] Update GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const updateGoodsIssueDocumentFailure = createAction(
  '[GOODISSUEDOCUMENT] Update GoodsIssueDocument Faild',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

const deleteGoodsIssueDocument = createAction(
  '[GOODISSUEDOCUMENT] Delete GoodsIssueDocument',
  props<{ payload: number }>()
);

const deleteGoodsIssueDocumentSuccess = createAction(
  '[GOODISSUEDOCUMENT] Delete GoodsIssueDocument Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const deleteGoodsIssueDocumentFailure = createAction(
  '[GOODISSUEDOCUMENT] Delete GoodsIssueDocument Faild',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const openGoodsIssueDocumentView = createAction(
  '[GOODISSUEDOCUMENT] Open GoodsIssueDocument View',
  props<{
    payload: {
      inProducts: Array<GoodsIssueDocumentLineModel>;
    };
  }>()
);

export const cleanProductInGoodsIssueDocument = createAction(
  '[GOODISSUEDOCUMENT] Clean Products in  GoodsIssueDocument'
);

export const addProductInGoodsIssueDocument = createAction(
  '[GOODISSUEDOCUMENT] Add Product in  GoodsIssueDocument',
  props<{ payload: { product: ProductModel; quantity: number } }>()
);

export const editProductInGoodsIssueDocument = createAction(
  '[GOODISSUEDOCUMENT] Edit Product in  GoodsIssueDocument',
  props<{
    payload: { product: GoodsIssueDocumentLineModel; quantity: number };
  }>()
);

export const removeProductInGoodsIssueDocument = createAction(
  '[GOODISSUEDOCUMENT] Remove Product in  GoodsIssueDocument',
  props<{ payload: { product: GoodsIssueDocumentLineModel } }>()
);

export const GoodsIssueDocumentsActions = {
  cleanProductInGoodsIssueDocument,
  addProductInGoodsIssueDocument,
  editProductInGoodsIssueDocument,
  removeProductInGoodsIssueDocument,
  openGoodsIssueDocumentView,
  getGoodsIssueDocuments,
  getGoodsIssueDocumentsSuccess,
  getGoodsIssueDocumentsFailure,
  getGoodsIssueDocument,
  getGoodsIssueDocumentSuccess,
  getGoodsIssueDocumentFailure,
  createGoodsIssueDocument,
  createGoodsIssueDocumentSuccess,
  createGoodsIssueDocumentFailure,
  updateGoodsIssueDocument,
  updateGoodsIssueDocumentSuccess,
  updateGoodsIssueDocumentFailure,
  deleteGoodsIssueDocument,
  deleteGoodsIssueDocumentSuccess,
  deleteGoodsIssueDocumentFailure,
};
