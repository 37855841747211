import { RestService } from './rest.service';
import { StartupService } from './startup.service';
import { SettingService } from './setting/setting.service';
import { PermissionsService } from './permissions.service';
import { RoutingState } from './routing-state.service';

export * from './startup.service';
export * from './setting/setting.service';
export * from './permissions.service';

export const services = [RestService, StartupService, SettingService, PermissionsService, RoutingState];
