import { DesignFilesActions } from './actions';
import { UploadSessionModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum DesignFileModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum DesignFileModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const { downloadSessionFiles, downloadSessionFilesSuccess, downloadSessionFilesFailure } = DesignFilesActions;
export interface DesignFileState {
  list: Array<UploadSessionModel>;
  item: UploadSessionModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    fullName?: string;
  };
  loading: boolean;
  modal: {
    type: DesignFileModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: DesignFileModalStatus;
  };

  printLoading: boolean;
  printData: Blob;
}

export const initialState: DesignFileState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    fullName: null,
    name: null,
  },
  loading: false,
  modal: {
    type: DesignFileModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: DesignFileModalStatus.Init,
  },
  printLoading: false,
  printData: null,
};

export const reducer = createReducer(
  initialState,
  on(downloadSessionFiles, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: true,
    };
  }),
  on(downloadSessionFilesSuccess, (state, { payload }) => {
    return {
      ...state,
      printData: payload,
      printLoading: false,
    };
  }),
  on(downloadSessionFilesFailure, (state) => {
    return {
      ...state,
      printData: null,
      printLoading: false,
    };
  })
);
