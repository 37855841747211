import { createAction, props } from '@ngrx/store';

import { WorkOutlineModel } from './model';
import {
  WorkOutlineResultsModel,
  WorkOutlineResultModel,
} from './results.model';

const getWorkOutlines = createAction(
  '[WORKOUTLINE] Get WorkOutlines',
  props<{ payload: any }>()
);

const getWorkOutlinesSuccess = createAction(
  '[WORKOUTLINE] Get WorkOutlines Success',
  props<{ payload: WorkOutlineResultsModel }>()
);

export const getWorkOutlinesFailure = createAction(
  '[WORKOUTLINE] Get WorkOutlines Faild'
);

const getWorkOutline = createAction(
  '[WORKOUTLINE] Get WorkOutline',
  props<{ payload: any }>()
);

const getWorkOutlineSuccess = createAction(
  '[WORKOUTLINE] Get WorkOutline Success',
  props<{ payload: WorkOutlineResultModel }>()
);

export const getWorkOutlineFailure = createAction(
  '[WORKOUTLINE] Get WorkOutline Faild'
);

const createWorkOutline = createAction(
  '[WORKOUTLINE] Create WorkOutline',
  props<{ payload: WorkOutlineModel }>()
);

const createWorkOutlineSuccess = createAction(
  '[WORKOUTLINE] Create WorkOutline Success',
  props<{ payload: WorkOutlineResultModel }>()
);

export const createWorkOutlineFailure = createAction(
  '[WORKOUTLINE] Create WorkOutline Faild',
  props<{ payload: WorkOutlineResultModel }>()
);

const updateWorkOutline = createAction(
  '[WORKOUTLINE] Update WorkOutline',
  props<{ payload: WorkOutlineModel }>()
);

const updateWorkOutlineSuccess = createAction(
  '[WORKOUTLINE] Update WorkOutline Success',
  props<{ payload: WorkOutlineResultModel }>()
);

export const updateWorkOutlineFailure = createAction(
  '[WORKOUTLINE] Update WorkOutline Faild',
  props<{ payload: WorkOutlineResultModel }>()
);

const deleteWorkOutline = createAction(
  '[WORKOUTLINE] Delete WorkOutline',
  props<{ payload: number }>()
);

const deleteWorkOutlineSuccess = createAction(
  '[WORKOUTLINE] Delete WorkOutline Success',
  props<{ payload: WorkOutlineResultModel }>()
);

export const deleteWorkOutlineFailure = createAction(
  '[WORKOUTLINE] Delete WorkOutline Faild',
  props<{ payload: WorkOutlineResultModel }>()
);

const finishWorkoutLine = createAction(
  '[WORKOUTLINE] Finish WorkOutline',
  props<{ payload: { workoutLineId: number } }>()
);

const finishWorkoutLineSuccess = createAction(
  '[WORKOUTLINE] Finish WorkOutline Success',
  props<{ payload: WorkOutlineResultModel }>()
);

export const finishWorkoutLineFailure = createAction(
  '[WORKOUTLINE] Finish WorkOutline Faild',
  props<{ payload: WorkOutlineResultModel }>()
);

export const openWorkOutlineView = createAction(
  '[WORKOUTLINE] Open WorkOutline View'
);

export const WorkOutlinesActions = {
  finishWorkoutLine,
  finishWorkoutLineSuccess,
  finishWorkoutLineFailure,
  openWorkOutlineView,
  getWorkOutlines,
  getWorkOutlinesSuccess,
  getWorkOutlinesFailure,
  getWorkOutline,
  getWorkOutlineSuccess,
  getWorkOutlineFailure,
  createWorkOutline,
  createWorkOutlineSuccess,
  createWorkOutlineFailure,
  updateWorkOutline,
  updateWorkOutlineSuccess,
  updateWorkOutlineFailure,
  deleteWorkOutline,
  deleteWorkOutlineSuccess,
  deleteWorkOutlineFailure,
};
