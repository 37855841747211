<ng-template #sideTpl>
  <nz-sider
    [nzTrigger]="null"
    [nzCollapsible]="true"
    [nzCollapsed]="isMobile ? false : pro.collapsed"
    [nzWidth]="pro.width"
    [nzCollapsedWidth]="pro.widthInCollapsed"
    class="alain-pro__sider"
    [ngClass]="{ 'alain-pro__sider-fixed': pro.fixSiderbar }"
  >
    <layout-pro-logo class="alain-pro__sider-logo"></layout-pro-logo>
    <div class="alain-pro__side-nav" style="padding: 16px 0; width: 100%">
      <div class="alain-pro__side-nav-wrap" layout-pro-menu></div>
    </div>
  </nz-sider>
</ng-template>
<div class="ant-layout ant-layout-has-sider">
  <ng-container *ngIf="pro.menu === 'side' || isMobile">
    <nz-drawer
      *ngIf="isMobile"
      [nzWidth]="pro.width"
      nzWrapClassName="alain-pro__drawer"
      [nzVisible]="!pro.collapsed"
      [nzClosable]="false"
      nzPlacement="left"
      (nzOnClose)="pro.setCollapsed(true)"
    >
      <ng-template [ngTemplateOutlet]="sideTpl"></ng-template>
    </nz-drawer>
    <ng-container *ngIf="!isMobile">
      <ng-template [ngTemplateOutlet]="sideTpl"></ng-template>
    </ng-container>
  </ng-container>
  <div class="ant-layout" style="min-height: 100vh" [ngStyle]="getLayoutStyle">
    <layout-pro-header></layout-pro-header>

    <div class="ant-layout-content alain-pro__body" [ngStyle]="getContentStyle">
      <div *ngIf="isFetching" style="padding-top: 100px; text-align: center">
        <nz-spin nzSpinning></nz-spin>
      </div>
      <ng-container *ngIf="!isFetching">
        <router-outlet></router-outlet>
      </ng-container>
    </div>
    <layout-pro-footer
      class="ant-layout-footer alain-pro__footer"
    ></layout-pro-footer>
  </div>
</div>
<!-- <quick-chat></quick-chat> -->
