import { createAction, props } from '@ngrx/store';

import { ManufactureRequestModel, ManufactureRequestLineModel } from './model';
import {
  ManufactureRequestResultsModel,
  ManufactureRequestResultModel,
} from './results.model';
import { ProductModel } from '../product/model';
import { TransportRequestResultModel } from '../transport-request';
import { GoodsIssueDocumentResultModel } from '../goods-issue-document';

const getManufactureRequests = createAction(
  '[MANUFACTUREREQUEST] Get ManufactureRequests',
  props<{ payload: any }>()
);

const getManufactureRequestsSuccess = createAction(
  '[MANUFACTUREREQUEST] Get ManufactureRequests Success',
  props<{ payload: ManufactureRequestResultsModel }>()
);

export const getManufactureRequestsFailure = createAction(
  '[MANUFACTUREREQUEST] Get ManufactureRequests Faild'
);

const getManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Get ManufactureRequest',
  props<{ payload: { manufactureRequestId: number } }>()
);

const getManufactureRequestSuccess = createAction(
  '[MANUFACTUREREQUEST] Get ManufactureRequest Success',
  props<{ payload: ManufactureRequestResultModel }>()
);

export const getManufactureRequestFailure = createAction(
  '[MANUFACTUREREQUEST] Get ManufactureRequest Faild'
);

const createManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Create ManufactureRequest',
  props<{
    payload: { projectId: number; manufactureRequest: ManufactureRequestModel };
  }>()
);

export const createManufactureRequestSuccess = createAction(
  '[MANUFACTUREREQUEST] Create ManufactureRequest Success',
  props<{ payload: ManufactureRequestResultModel }>()
);

export const createManufactureRequestFailure = createAction(
  '[MANUFACTUREREQUEST] Create ManufactureRequest Faild',
  props<{ payload: ManufactureRequestResultModel }>()
);

const updateManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Update ManufactureRequest',
  props<{
    payload: {
      manufactureRequestId: number;
      manufactureRequest: ManufactureRequestModel;
    };
  }>()
);

const updateManufactureRequestSuccess = createAction(
  '[MANUFACTUREREQUEST] Update ManufactureRequest Success',
  props<{ payload: ManufactureRequestResultModel }>()
);

export const updateManufactureRequestFailure = createAction(
  '[MANUFACTUREREQUEST] Update ManufactureRequest Faild',
  props<{ payload: ManufactureRequestResultModel }>()
);

const deleteManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Delete ManufactureRequest',
  props<{ payload: number }>()
);

const deleteManufactureRequestSuccess = createAction(
  '[MANUFACTUREREQUEST] Delete ManufactureRequest Success',
  props<{ payload: ManufactureRequestResultModel }>()
);

export const deleteManufactureRequestFailure = createAction(
  '[MANUFACTUREREQUEST] Delete ManufactureRequest Faild',
  props<{ payload: ManufactureRequestResultModel }>()
);

export const openManufactureRequestView = createAction(
  '[MANUFACTUREREQUEST] Open ManufactureRequest View',
  props<{
    payload: {
      inProducts: Array<ManufactureRequestLineModel>;
    };
  }>()
);

export const cleanProductInManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Clean Products in  ManufactureRequest'
);

export const addProductInManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Add Product in  ManufactureRequest',
  props<{ payload: { product: ProductModel; quantity: number } }>()
);

export const editProductInManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Edit Product in  ManufactureRequest',
  props<{
    payload: { product: ManufactureRequestLineModel; quantity: number };
  }>()
);

export const removeProductInManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Remove Product in  ManufactureRequest',
  props<{ payload: { product: ManufactureRequestLineModel } }>()
);

const confirmManufactureRequest = createAction(
  '[MANUFACTUREREQUEST] Confirm ManufactureRequest',
  props<{ payload: { manufactureRequestId: number } }>()
);

const confirmManufactureRequestSuccess = createAction(
  '[MANUFACTUREREQUEST] Confirm ManufactureRequest Success',
  props<{ payload: ManufactureRequestResultModel }>()
);

export const confirmManufactureRequestFailure = createAction(
  '[MANUFACTUREREQUEST] Confirm ManufactureRequest Faild',
  props<{ payload: ManufactureRequestResultModel }>()
);

const createManufactureRequestTransportRequest = createAction(
  '[MANUFACTUREREQUEST] Create TransportRequest',
  props<{
    payload: { manufactureRequestId: number };
  }>()
);

export const createManufactureRequestTransportRequestSuccess = createAction(
  '[MANUFACTUREREQUEST] Create TransportRequest Success',
  props<{ payload: TransportRequestResultModel }>()
);

export const createManufactureRequestTransportRequestFailure = createAction(
  '[MANUFACTUREREQUEST] Create TransportRequest Faild',
  props<{ payload: TransportRequestResultModel }>()
);

const createManufactureRequestGoodsIssueDocument = createAction(
  '[MANUFACTUREREQUEST] Create GoodsIssue',
  props<{
    payload: { manufactureRequestId: number };
  }>()
);

export const createManufactureRequestGoodsIssueDocumentSuccess = createAction(
  '[MANUFACTUREREQUEST] Create GoodsIssue Success',
  props<{ payload: GoodsIssueDocumentResultModel }>()
);

export const createManufactureRequestGoodsIssueDocumentFailure = createAction(
  '[MANUFACTUREREQUEST] Create GoodsIssue Faild'
);

export const ManufactureRequestsActions = {
  confirmManufactureRequest,
  confirmManufactureRequestSuccess,
  confirmManufactureRequestFailure,
  createManufactureRequestGoodsIssueDocument,
  createManufactureRequestGoodsIssueDocumentSuccess,
  createManufactureRequestGoodsIssueDocumentFailure,
  createManufactureRequestTransportRequest,
  createManufactureRequestTransportRequestSuccess,
  createManufactureRequestTransportRequestFailure,
  cleanProductInManufactureRequest,
  addProductInManufactureRequest,
  editProductInManufactureRequest,
  removeProductInManufactureRequest,
  openManufactureRequestView,
  getManufactureRequests,
  getManufactureRequestsSuccess,
  getManufactureRequestsFailure,
  getManufactureRequest,
  getManufactureRequestSuccess,
  getManufactureRequestFailure,
  createManufactureRequest,
  createManufactureRequestSuccess,
  createManufactureRequestFailure,
  updateManufactureRequest,
  updateManufactureRequestSuccess,
  updateManufactureRequestFailure,
  deleteManufactureRequest,
  deleteManufactureRequestSuccess,
  deleteManufactureRequestFailure,
};
