import { PurchaseRequestsActions } from './actions';
import { PurchaseRequestModel, PurchaseRequestLineModel } from './model';
import { createReducer, on } from '@ngrx/store';

export enum PurchaseRequestModalStatus {
  Init = 0,
  Called = 1,
  Success = 2,
  Failure = 3,
}

export enum PurchaseRequestModalType {
  Create = 0,
  Edit = 1,
  View = 2,
}

const {
  getPurchaseRequests,
  getPurchaseRequestsFailure,
  getPurchaseRequestsSuccess,
  getPurchaseRequest,
  getPurchaseRequestFailure,
  getPurchaseRequestSuccess,
  createPurchaseRequest,
  createPurchaseRequestFailure,
  createPurchaseRequestSuccess,
  updatePurchaseRequest,
  updatePurchaseRequestFailure,
  updatePurchaseRequestSuccess,
  deletePurchaseRequest,
  deletePurchaseRequestFailure,
  deletePurchaseRequestSuccess,
  openPurchaseRequestView,
  cleanProductInPurchaseRequest,
  addProductInPurchaseRequest,
  editProductInPurchaseRequest,
  removeProductInPurchaseRequest,
} = PurchaseRequestsActions;
export interface PurchaseRequestState {
  list: Array<PurchaseRequestModel>;
  item: PurchaseRequestModel;
  pagination: {
    page: number;
    size: number;
    totalRecords: number;
  };
  params: {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    name?: string;
    projectId?: number;
  };
  loading: boolean;
  modal: {
    type: PurchaseRequestModalType;
    data: any;
    isLoading: boolean;
    errors: Array<any>;
    status: PurchaseRequestModalStatus;
  };
  inProducts: Array<PurchaseRequestLineModel>;
}

export const initialState: PurchaseRequestState = {
  list: [],
  item: null,
  pagination: {
    page: 0,
    size: 10,
    totalRecords: 0,
  },
  params: {
    page: 0,
    size: 10,
    sort: null,
    search: null,
    name: null,
    projectId: null,
  },
  loading: false,
  modal: {
    type: PurchaseRequestModalType.View,
    data: null,
    isLoading: false,
    errors: [],
    status: PurchaseRequestModalStatus.Init,
  },
  inProducts: [],
};

export const reducer = createReducer(
  initialState,
  on(getPurchaseRequests, (state, { payload }) => {
    return { ...state, loading: true, params: { ...payload } };
  }),
  on(getPurchaseRequestsSuccess, (state, { payload }) => {
    return {
      ...state,
      list: [...payload.data],
      pagination: { ...payload.pagination },
      loading: false,
    };
  }),
  on(getPurchaseRequestsFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(getPurchaseRequest, (state, { payload }) => {
    return { ...state, loading: true };
  }),
  on(getPurchaseRequestSuccess, (state, { payload }) => {
    return {
      ...state,
      item: { ...payload.data },
      loading: false,
    };
  }),
  on(getPurchaseRequestFailure, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(updatePurchaseRequest, (state) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: PurchaseRequestModalStatus.Called,
      type: PurchaseRequestModalType.Edit,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updatePurchaseRequestSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: PurchaseRequestModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(updatePurchaseRequestFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: PurchaseRequestModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createPurchaseRequest, (state, { payload }) => {
    const modal = {
      ...state.modal,
      isLoading: true,
      status: PurchaseRequestModalStatus.Init,
      type: PurchaseRequestModalType.Create,
      errors: [],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createPurchaseRequestSuccess, (state) => {
    const modal = {
      ...state.modal,
      isLoading: false,
      status: PurchaseRequestModalStatus.Success,
    };
    return {
      ...state,
      modal,
    };
  }),
  on(createPurchaseRequestFailure, (state, { payload }) => {
    const { validationErrors = [] } = payload;
    const modal = {
      ...state.modal,
      isLoading: false,
      status: PurchaseRequestModalStatus.Failure,
      errors: [...validationErrors],
    };
    return {
      ...state,
      modal,
    };
  }),
  on(deletePurchaseRequest, (state) => {
    return { ...state, loading: true };
  }),
  on(deletePurchaseRequestSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(deletePurchaseRequestFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(openPurchaseRequestView, (state, { payload }) => {
    const modal = {
      ...state.modal,
      status: PurchaseRequestModalStatus.Init,
      errors: [],
    };

    if (payload && Array.isArray(payload.inProducts)) {
      const lines = payload.inProducts;
      const inProducts = lines.map((c) => {
        return {
          ...c,
          uid: `server-${c.id}`,
          name: c.productName,
          note: c.note,
          unit: c.productUnit,
        };
      });
      return {
        ...state,
        modal,
        inProducts,
      };
    }

    return {
      ...state,
      modal,
      inProducts: [],
    };
  }),
  on(addProductInPurchaseRequest, (state, { payload }) => {
    const { product, quantity } = payload;
    const length = state.inProducts.length + 1;
    const productExist = state.inProducts.find(
      (p) => p.productId === product.id
    );
    if (productExist) {
      const inProducts = state.inProducts.map((p) => {
        let qty = quantity;
        if (p.productId === product.id) {
          qty += p.quantity;
        }
        return { ...p, quantity: qty, isEdited: true };
      });

      return {
        ...state,
        inProducts,
      };
    }
    const inProduct = {
      uid: `local-${length}`,
      name: product.name,
      productId: product.id,
      quantity,
      isNew: true,
    } as PurchaseRequestLineModel;

    return {
      ...state,
      inProducts: [...state.inProducts, inProduct],
    };
  }),
  on(editProductInPurchaseRequest, (state, { payload }) => {
    const { product, quantity } = payload;
    const newProducts = state.inProducts.map((c) => {
      if (c.productId === product.productId && !c.isNew) {
        return {
          ...c,
          ...product,
          productId: product.productId,
          quantity,
          isEdited: true,
        };
      }
      if (c.productId === product.productId && c.isNew) {
        return { ...c, ...product, productId: product.productId };
      }
      return c;
    });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(removeProductInPurchaseRequest, (state, { payload }) => {
    if (!payload) {
      return {
        ...state,
      };
    }
    const { product } = payload;
    const newProducts = state.inProducts
      .map((c) => {
        if (c.productId === product.productId) {
          return { ...c, isRemoved: true };
        }
        return c;
      })
      .filter((c) => {
        if (c.productId === product.productId && c.isNew) {
          return false;
        }
        return true;
      });
    return {
      ...state,
      inProducts: newProducts,
    };
  }),
  on(cleanProductInPurchaseRequest, (state) => {
    return {
      ...state,
      inProducts: [],
    };
  })
);
