import { Component } from '@angular/core';
import { SettingService } from './@core/services';
import { RoutingState } from './@core/services/routing-state.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Á Đông - Admin';
  constructor(settingService: SettingService, routingState: RoutingState) {
    routingState.loadRouting();
  }
}
