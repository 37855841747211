import { createAction, props } from '@ngrx/store';

import { TripModel } from './model';
import { TripResultsModel, TripResultModel } from './results.model';

const getTrips = createAction('[TRIP] Get Trips', props<{ payload: any }>());

const getTripsSuccess = createAction(
  '[TRIP] Get Trips Success',
  props<{ payload: TripResultsModel }>()
);

export const getTripsFailure = createAction('[TRIP] Get Trips Faild');

const getTrip = createAction(
  '[TRIP] Get Trip',
  props<{ payload: { tripId: number } }>()
);

const getTripSuccess = createAction(
  '[TRIP] Get Trip Success',
  props<{ payload: TripResultModel }>()
);

export const getTripFailure = createAction('[TRIP] Get Trip Faild');

export const getTripWithLorry = createAction(
  '[TRIP] Get Trip With Lorry',
  props<{ payload: { tripId: number } }>()
);

const getTripWithLorrySuccess = createAction(
  '[TRIP] Get Trip With Lorry Success',
  props<{ payload: TripResultModel }>()
);

export const getTripWithLorryFailure = createAction(
  '[TRIP] Get Trip With Lorry Faild'
);

const createTrip = createAction(
  '[TRIP] Create Trip',
  props<{ payload: TripModel }>()
);

const createTripSuccess = createAction(
  '[TRIP] Create Trip Success',
  props<{ payload: TripResultModel }>()
);

export const createTripFailure = createAction(
  '[TRIP] Create Trip Faild',
  props<{ payload: TripResultModel }>()
);

const updateTrip = createAction(
  '[TRIP] Update Trip',
  props<{ payload: TripModel }>()
);

const updateTripSuccess = createAction(
  '[TRIP] Update Trip Success',
  props<{ payload: TripResultModel }>()
);

export const updateTripFailure = createAction(
  '[TRIP] Update Trip Faild',
  props<{ payload: TripResultModel }>()
);

const deleteTrip = createAction(
  '[TRIP] Delete Trip',
  props<{ payload: number }>()
);

const deleteTripSuccess = createAction(
  '[TRIP] Delete Trip Success',
  props<{ payload: TripResultModel }>()
);

export const deleteTripFailure = createAction(
  '[TRIP] Delete Trip Faild',
  props<{ payload: TripResultModel }>()
);

export const openTripView = createAction('[TRIP] Open Trip View');

const addTransportRequest = createAction(
  '[TRIP] Add Transport Request',
  props<{
    payload: {
      tripId: number;
      transportRequestId?: number;
    };
  }>()
);

export const addTransportRequestSuccess = createAction(
  '[TRIP] Add Transport Request Success',
  props<{ payload: TripResultModel }>()
);

export const addTransportRequestFailure = createAction(
  '[TRIP] Add Transport Request Faild',
  props<{ payload: TripResultModel }>()
);

const cancelTrip = createAction(
  '[TRIP] Cancel Trip',
  props<{
    payload: {
      tripId: number;
    };
  }>()
);

export const cancelTripSuccess = createAction(
  '[TRIP] Cancel Trip Success',
  props<{ payload: TripResultModel }>()
);

export const cancelTripFailure = createAction(
  '[TRIP] Cancel Trip Faild',
  props<{ payload: TripResultModel }>()
);

export const TripsActions = {
  getTripWithLorry,
  getTripWithLorrySuccess,
  getTripWithLorryFailure,
  cancelTrip,
  cancelTripSuccess,
  cancelTripFailure,
  addTransportRequest,
  addTransportRequestSuccess,
  addTransportRequestFailure,
  openTripView,
  getTrips,
  getTripsSuccess,
  getTripsFailure,
  getTrip,
  getTripSuccess,
  getTripFailure,
  createTrip,
  createTripSuccess,
  createTripFailure,
  updateTrip,
  updateTripSuccess,
  updateTripFailure,
  deleteTrip,
  deleteTripSuccess,
  deleteTripFailure,
};
