import { createAction, props } from '@ngrx/store';

import { GoodsReceivedNoteModel, GoodsReceivedNoteLineModel } from './model';
import {
  GoodsReceivedNoteResultsModel,
  GoodsReceivedNoteResultModel,
} from './results.model';
import { ProductModel } from '../product/model';

const getGoodsReceivedNotes = createAction(
  '[GOODSRECEIVEDNOTE] Get GoodsReceivedNotes',
  props<{ payload: any }>()
);

const getGoodsReceivedNotesSuccess = createAction(
  '[GOODSRECEIVEDNOTE] Get GoodsReceivedNotes Success',
  props<{ payload: GoodsReceivedNoteResultsModel }>()
);

export const getGoodsReceivedNotesFailure = createAction(
  '[GOODSRECEIVEDNOTE] Get GoodsReceivedNotes Faild'
);

const getGoodsReceivedNote = createAction(
  '[GOODSRECEIVEDNOTE] Get GoodsReceivedNote',
  props<{ payload: { goodsReceivedNoteId: number } }>()
);

const getGoodsReceivedNoteSuccess = createAction(
  '[GOODSRECEIVEDNOTE] Get GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const getGoodsReceivedNoteFailure = createAction(
  '[GOODSRECEIVEDNOTE] Get GoodsReceivedNote Faild'
);

const createGoodsReceivedNote = createAction(
  '[GOODSRECEIVEDNOTE] Create GoodsReceivedNote',
  props<{
    payload: { projectId: number; goodsReceivedNote: GoodsReceivedNoteModel };
  }>()
);

const createGoodsReceivedNoteSuccess = createAction(
  '[GOODSRECEIVEDNOTE] Create GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const createGoodsReceivedNoteFailure = createAction(
  '[GOODSRECEIVEDNOTE] Create GoodsReceivedNote Faild',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

const updateGoodsReceivedNote = createAction(
  '[GOODSRECEIVEDNOTE] Update GoodsReceivedNote',
  props<{
    payload: {
      goodsReceivedNoteId: number;
      goodsReceivedNote: GoodsReceivedNoteModel;
    };
  }>()
);

const updateGoodsReceivedNoteSuccess = createAction(
  '[GOODSRECEIVEDNOTE] Update GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const updateGoodsReceivedNoteFailure = createAction(
  '[GOODSRECEIVEDNOTE] Update GoodsReceivedNote Faild',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

const deleteGoodsReceivedNote = createAction(
  '[GOODSRECEIVEDNOTE] Delete GoodsReceivedNote',
  props<{ payload: number }>()
);

const deleteGoodsReceivedNoteSuccess = createAction(
  '[GOODSRECEIVEDNOTE] Delete GoodsReceivedNote Success',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const deleteGoodsReceivedNoteFailure = createAction(
  '[GOODSRECEIVEDNOTE] Delete GoodsReceivedNote Faild',
  props<{ payload: GoodsReceivedNoteResultModel }>()
);

export const openGoodsReceivedNoteView = createAction(
  '[GOODSRECEIVEDNOTE] Open GoodsReceivedNote View',
  props<{
    payload: {
      inProducts: Array<GoodsReceivedNoteLineModel>;
    };
  }>()
);

export const cleanProductInGoodsReceivedNote = createAction(
  '[GOODSRECEIVEDNOTE] Clean Products in  GoodsReceivedNote'
);

export const addProductInGoodsReceivedNote = createAction(
  '[GOODSRECEIVEDNOTE] Add Product in  GoodsReceivedNote',
  props<{ payload: { product: ProductModel; quantity: number } }>()
);

export const editProductInGoodsReceivedNote = createAction(
  '[GOODSRECEIVEDNOTE] Edit Product in  GoodsReceivedNote',
  props<{
    payload: { product: GoodsReceivedNoteLineModel; quantity: number };
  }>()
);

export const removeProductInGoodsReceivedNote = createAction(
  '[GOODSRECEIVEDNOTE] Remove Product in  GoodsReceivedNote',
  props<{ payload: { product: GoodsReceivedNoteLineModel } }>()
);

export const GoodsReceivedNotesActions = {
  cleanProductInGoodsReceivedNote,
  addProductInGoodsReceivedNote,
  editProductInGoodsReceivedNote,
  removeProductInGoodsReceivedNote,
  openGoodsReceivedNoteView,
  getGoodsReceivedNotes,
  getGoodsReceivedNotesSuccess,
  getGoodsReceivedNotesFailure,
  getGoodsReceivedNote,
  getGoodsReceivedNoteSuccess,
  getGoodsReceivedNoteFailure,
  createGoodsReceivedNote,
  createGoodsReceivedNoteSuccess,
  createGoodsReceivedNoteFailure,
  updateGoodsReceivedNote,
  updateGoodsReceivedNoteSuccess,
  updateGoodsReceivedNoteFailure,
  deleteGoodsReceivedNote,
  deleteGoodsReceivedNoteSuccess,
  deleteGoodsReceivedNoteFailure,
};
